import enums from '@/config/enums';
import Vue from "vue";

export const requiredFields = () => [
  {
    key: 'status',
    label: Vue.prototype.$vDict('contacts.field_status.text'),
    class: 'column-min',
    required: true,
    listTypeIds: [
      enums.LIST_TYPES.ALL_CONTACTS,
      enums.LIST_TYPES.COMPANY_CONTACTS,
    ],
    components: ['user_contacts'],
    dataCy: 'status',
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('contacts.field_status.text'),
    class: 'column-min',
    required: true,
    fieldset: ['viewed', 'users'],
    listTypeIds: [enums.LIST_TYPES.USER_CONTACTS],
    components: ['user_contacts'],
    dataCy: 'status',
  },
  {
    key: 'notes',
    label: Vue.prototype.$vDict('contacts.field_notes.text'),
    class: 'column-min',
    required: true,
    components: ['contact_notes', 'contact_activities'],
    dataCy: 'notes',
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('contacts.field_name.text'),
    fieldset: ['id', 'first_name', 'last_name'],
    class: 'column-name',
    sortable: true,
    required: true,
    sortKeys: ['first_name', 'last_name'],
    dataCy: 'name',
  },
];

export const fieldsSections = () => [
  {
    key: 'contact',
    label: Vue.prototype.$vDict('contacts.section_contact.text'),
    icon: 'phone-mobile',
    dataCy: 'contact',
    fields: [
      {
        key: 'tags',
        label: Vue.prototype.$vDict('contacts.field_tags.text'),
        fieldset: ['tags'],
        class: 'column-tags',
        listTypeIds: [enums.LIST_TYPES.USER_CONTACTS],
        fixedPriority: true,
        beforeField: 'name',
      },
      {
        key: 'phones',
        label: Vue.prototype.$vDict('contacts.field_phones.text'),
        fieldset: ['communications'],
        dataCy: 'phones',
      },
      {
        key: 'email',
        label: Vue.prototype.$vDict('contacts.field_email.text'),
        fieldset: ['communications'],
        dataCy: 'email',
      },
      {
        key: 'companies',
        label: Vue.prototype.$vDict('contacts.field_companies.text'),
        fieldset: ['original_companies'],
        dataCy: 'companies',
      },
      {
        key: 'roles',
        label: Vue.prototype.$vDict('contacts.field_roles.text'),
        fieldset: ['project_company_roles', 'all_roles'],
        dataCy: 'roles',
      },
      {
        key: 'client_statuses',
        label: Vue.prototype.$vDict('contacts.field_client_statuses.text'),
        fieldset: ['users'],
        dataCy: 'client-statuses',
        listTypeIds: [enums.LIST_TYPES.USER_CONTACTS],
      },
    ],
  },
  {
    key: 'additional_fields',
    label: Vue.prototype.$vDict('contacts.section_additional_fields.text'),
    icon: 'baseline-format_list_view',
    dataCy: 'additional-fields',
    fields: [],
  },
  {
    key: 'custom_fields',
    label: Vue.prototype.$vDict('contacts.section_custom_fields.text'),
    icon: 'internal',
    dataCy: 'custom-fields',
    fields: [],
  },
];
