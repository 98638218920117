/*
  User class
 */

import Vue from 'vue';
import {
  ENTITY_TYPES,
  INVITE_STATUSES,
  SMART_USER_STATUSES,
} from "@/config/enums";
import BaseEntity from "@/shared/classes/entity/BaseEntity";
import {
  getUsersAggList,
  getUser,
  patchUser,
  postUser,
} from "@/api/repositories/userRepository";
import { Client, UserProfile, ClientUser } from "@/entities";
import store from "@/store";
import router from "@/router";
import {
  BACKEND_ENDPOINTS
} from "@/api/repositories/userRepository";

export class User extends BaseEntity {
  static entityKey = 'users';
  static userSectionAccessComponent = 'all_users';
  static datasetKey = 'users';
  static routeName = 'SupportUser';
  static routes = {
    view: 'SupportUser',
    edit: 'SupportEditorUser',
    list: 'SupportUsers',
    create: 'SupportEditorUserNew',
  };
  static entityTypeId = ENTITY_TYPES.USER;

  static loadAllItemsEndpoint = BACKEND_ENDPOINTS.USERS_AGG_QUERY;
  static loadAllItemsFunc = getUsersAggList;
  static loadItemFunc = getUser;
  static patchItemFunc = patchUser;
  static postItemFunc = postUser;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('users.plural_users_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    // this.loadListFunc = getClients;
    this.selfClass = User;
  }

  prepareItemData(data, defaultData) {
    const {
      clients = [],
      user_profiles = [],
      client_users = [],
      ...rest
    } = data;

    this.apiClients = [];
    this.setClients(clients);
    this.setClientUsers(client_users);
    this.setUserProfiles(user_profiles);

    _.merge(this.data, defaultData(), rest);
  }

  static getAllActions() {
    return [
      {
        key: 'view',
        name: Vue.prototype.$vDict('users.view_button.text'),
        icon: 'pwd-show',
      },
      {
        key: 'edit',
        dataCy: 'edit',
        name: Vue.prototype.$vDict('users.edit_button.text'),
        icon: 'edit',
      },
      {
        key: 'add_subscription',
        dataCy: 'add-subscription',
        name: Vue.prototype.$vDict('users.add_user_to_organization_button.text'),
        icon: 'company',
      },
      {
        key: 'transfer_data',
        dataCy: 'transfer-data',
        name: Vue.prototype.$vDict('users.transfer_data_button.text'),
        icon: 'coworker-note-added',
      },
      {
        key: 'login_as',
        dataCy: 'login-as-user',
        name: Vue.prototype.$vDict('users.login_as_user_button.text'),
        icon: 'logout',
      },
      {
        type: 'divider',
      },
      {
        key: 'suspend',
        icon: 'deactivated',
        name: Vue.prototype.$vDict('users.suspend_button.text'),
        linkClass: 'action-link--warning',
      },
      {
        key: 'unsuspend',
        icon: 'power',
        name: Vue.prototype.$vDict('users.unsuspend_button.text'),
        linkClass: 'action-link--secondary',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'source_type_id':
        value = this.getSourceType();
        res = value?.name || '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;
    let value;

    switch (fieldKey) {
      case 'account_status':
        res = this.getAccountStatus();
        break;
      default:
        res = undefined;
    }

    return res;
  }

  getAccountStatus(clientId) {
    let res;

    if (this.getValue('is_suspended')) {
      res = {
        variant: 'suspended',
        name: Vue.prototype.$vDict('users.account_status_deactivated.text'),
      };
    } else if (this.getValue('is_dummy')) {
      res = {
        variant: 'not-registered',
        name: Vue.prototype.$vDict('users.account_status_not_registered.text'),
      };
    } else {
      res = {
        variant: 'registered',
        name: Vue.prototype.$vDict('users.account_status_registered.text'),
      };
    }

    return res;
  }

  setUserProfiles(userProfiles) {
    this.userProfiles = userProfiles.map(userProfileData => new UserProfile(userProfileData));
  }

  setClients(clients) {
    this.clients = clients.map(clientData => new Client(clientData));
  }

  getUserProfiles() {
    if (this.userProfiles.length > 0) {
      return this.userProfiles;
    }

    const clients = this.clients.length > 0 ? this.clients : this.getClientUsers();
    const userProfiles = [];

    clients.forEach(client => {
      const subscriptions = client.subscriptions || [];

      subscriptions.forEach(subscription => {
        const { userProfile } = subscription;

        if (userProfile && userProfiles.every(el => el.id !== userProfile.id)) {
          userProfiles.push(userProfile);
        }
      });
    });

    return userProfiles;
  }

  setClientUsers(clientUsers) {
    this.clientUsers = clientUsers.map(clientUserData => new ClientUser(clientUserData));
  }

  getClientUsers() {
    if (this.clientUsers.length > 0) {
      return this.clientUsers;
    }

    if (!this.clients.length) {
      return [];
    }

    const clientUsers = [];

    this.clients.forEach(client => {
      const { clientUser } = client;

      if (clientUser) {
        clientUsers.push(clientUser);
      }
    });

    return clientUsers;
  }

  getSubscriptions(clientId) {
    let subscriptions;

    if (this.clients.length > 0) {
      subscriptions = this.clients.reduce((acum, client) => {
        const clientSubscriptions = client.subscriptions || [];
        return [
          ...acum,
          ...clientSubscriptions,
        ];
      }, []);
    } else {
      const clientUsers = this.getClientUsers();
      subscriptions = clientUsers.reduce((acum, clientUser) => {
        const subscriptions = clientUser.subscriptions || [];
        return [
          ...acum,
          ...subscriptions,
        ];
      }, []);
    }

    if (clientId) {
      subscriptions = subscriptions.filter(subscription => subscription.getValue('client_id') === clientId);
    }

    return subscriptions;
  }

  // hasActiveUserProfile() {
  //   const userProfiles = this.getUserProfiles();
  //   return userProfiles.some(userProfile => !userProfile.getValue('is_suspended'));
  // }

  hasActiveSubscription(clientId) {
    const subscriptions = this.getSubscriptions(clientId);
    const allUserProfiles = this.getUserProfiles();

    return subscriptions.some(subscription => {
      const userProfiles = allUserProfiles.filter(el => el.getValue('subscription_id') === subscription.id);
      const hasActiveUserProfile = userProfiles.some(userProfile => userProfile.isActive());
      return hasActiveUserProfile && subscription.isActive();
    });
  }

  isActive() {
    return !this.getValue('is_suspended') && this.hasActiveSubscription();
  }

  isShowLoginAs() {
    //   this.getValue('smart_settings.license_id') !== LICENSES.DEVELOPER;
    return !window.logged_as_user && this.isActive();
  }

  getActions() {
    const actions = this.selfClass.getAllActions();

    return actions.filter(action => {
      let res = true;

      switch (action.key) {
        case 'suspend':
          res = !this.getValue('is_suspended') && store.getters['Account/hasComponent']('user_deactivate_button');
          break;
        case 'unsuspend':
          res = this.getValue('is_suspended') && store.getters['Account/hasComponent']('user_deactivate_button');
          break;
        case 'transfer_data':
          res = store.getters['Account/hasComponent']('user_transfer_task_button');
          break;
        case 'login_as':
          res = this.isShowLoginAs();
          break;
        default:
      }

      return res;
    });
  }

  async doAction(action) {
    switch (action.key) {
      case 'view':
        this.goToView();
        break;
      case 'edit':
        this.goToEdit();
        break;
      case 'suspend':
        await this.changeSuspendedValue(true);
        break;
      case 'unsuspend':
        await this.changeSuspendedValue(false);
        break;
      case 'transfer_data':
        router.push({
          name: 'SupportEditorUserTransferTaskNew',
          query: {
            to_user_id: this.id,
            from: router.currentRoute.fullPath,
          },
        });
        break;
      default:
        await super.doAction(action);
    }
  }
}
