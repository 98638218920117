import Vue from 'vue';

export const requiredFields = () => [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'source_id',
    label: Vue.prototype.$vDict('subscriptions.field_source_id.text'),
    sortable: true,
  },
  {
    key: 'source_type_id',
    label: Vue.prototype.$vDict('subscriptions.field_source_type_id.text'),
    sortable: true,
  },
  {
    key: 'client_id',
    label: Vue.prototype.$vDict('subscriptions.field_client_id.text'),
    sortable: true,
  },
  {
    key: 'end_date_info',
    label: Vue.prototype.$vDict('subscriptions.field_end_date_info.text'),
    sortable: true,
  },
  {
    key: 'end_date',
    label: Vue.prototype.$vDict('subscriptions.field_end_date.text'),
    sortable: true,
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('subscriptions.field_modified_at.text'),
    sortable: true,
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('subscriptions.field_status.text'),
    sortable: true,
  },
];
