/*
  UserContactsList class
 */

import { BaseContactsList } from "@/entities/contact/BaseContactsList";
import { Contact } from "@/entities/contact/Contact";

export class ContactsList extends BaseContactsList {
  static entityClass = Contact;

  constructor(listTypeId) {
    super(listTypeId);

    this.selfClass = ContactsList;
  }
}
