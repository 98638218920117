<template>
  <base-table-view
    class="pm-custom-field-values-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(pm_client_version_id)="data">
      <client-version-field
        :version-id="data.value"
      />
    </template>

    <template v-slot:cell(pm_custom_field_id)="data">
      <id-link-field
        :value="data.value"
        :route-to="{ name: 'PMCustomFields', query: { searchFilter: `id:[${data.value}]` }  }"
      />
    </template>

    <template v-slot:cell(hint)="data">
      <id-link-field
        :value="data.value"
        :route-to="{ name: 'PMCustomFields', query: { searchFilter: `hint:${data.value}` }  }"
      />
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import ClientVersionField from "@/entities/parabyg-migration/ClientVersionField";
import IdLinkField from "@/components/entity-fields/IdLinkField";

export default {
  name: 'pm-custom-field-values-table-view',
  components: {
    BaseTableView,
    ClientVersionField,
    IdLinkField,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
