import enums from '@/config/enums';
import Vue from "vue";

export const requiredFields = () => [
  {
    key: 'user_tender_status_id',
    label: Vue.prototype.$vDict('tenders.field_user_tender_status_id.text'),
    class: 'column-min',
    required: true,
    listTypeIds: [
      enums.LIST_TYPES.ALL_TENDERS,
      enums.LIST_TYPES.PROJECT_TENDERS,
      enums.LIST_TYPES.COMPANY_TENDERS,
      enums.LIST_TYPES.CONTACT_TENDERS,
      enums.LIST_TYPES.TENDER_TENDERS,
    ],
    components: ['user_tenders'],
    dataCy: 'status',
  },
  {
    key: 'user_tender_status_id',
    label: Vue.prototype.$vDict('tenders.field_user_tender_status_id.text'),
    class: 'column-min',
    required: true,
    fieldset: ['viewed', 'user_tender_status_id'],
    listTypeIds: [
      enums.LIST_TYPES.USER_TENDERS,
    ],
    components: ['user_tenders'],
    dataCy: 'status',
  },
  {
    key: 'notes',
    label: Vue.prototype.$vDict('tenders.field_notes.text'),
    class: 'column-min',
    required: true,
    components: ['tender_notes', 'tender_activities'],
    dataCy: 'notes',
  },
  // {
  //   'key': 'coworkers',
  //   'label': '',
  //   'class': 'column-min'
  // },
  {
    key: 'tags',
    label: Vue.prototype.$vDict('tenders.field_tags.text'),
    fieldset: ['tags'],
    class: 'column-tags',
    required: true,
    listTypeIds: [
      enums.LIST_TYPES.USER_TENDERS,
    ],
    dataCy: 'tags',
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('tenders.field_name.text'),
    fieldset: [
      'id',
      'name',
      'project_id',
      'archived',
      'tender_subject_id',
    ],
    sortable: true,
    class: 'column-name',
    required: true,
    sortKeys: ['name'],
    dataCy: 'name',
    // hideOnCard: true,
  },
];

export const fieldsSections = () => [
  {
    key: 'geography',
    label: Vue.prototype.$vDict('tenders.section_geography.text'),
    icon: 'card-geography',
    dataCy: 'geography',
    fields: [
      {
        key: 'region_id',
        label: Vue.prototype.$vDict('tenders.field_region_id.text'),
        fieldset: ['region_id'],
        section_id: 3,
        dataCy: 'region',
      },
      {
        key: 'district_id',
        label: Vue.prototype.$vDict('tenders.field_district_id.text'),
        fieldset: ['district_id'],
        section_id: 3,
        dataCy: 'district',
      },
      {
        key: 'city',
        label: Vue.prototype.$vDict('tenders.field_city.text'),
        fieldset: ['city'],
        sortable: true,
        section_id: 3,
        sortKeys: ['city'],
        dataCy: 'city',
      },
      {
        key: 'postcode',
        label: Vue.prototype.$vDict('tenders.field_postcode.text'),
        fieldset: ['postcode', 'postcode_text'],
        sortable: true,
        section_id: 3,
        sortKeys: ['postcode'],
        dataCy: 'postcode',
      },
      {
        key: 'street',
        label: Vue.prototype.$vDict('tenders.field_street.text'),
        fieldset: ['street', 'street_number'],
        sortable: true,
        section_id: 3,
        sortKeys: ['street', 'street_number'],
        dataCy: 'street',
      },
    ],
  },
  {
    key: 'contact',
    label: Vue.prototype.$vDict('tenders.section_contact.text'),
    icon: 'contacts',
    dataCy: 'contact',
    fields: [
      {
        key: 'company_id',
        label: Vue.prototype.$vDict('tenders.field_company_id.text'),
        fieldset: ['company_id'],
        class: 'column-entity',
        section_id: 2,
        dataCy: 'company',
      },
      {
        key: 'contact_id',
        label: Vue.prototype.$vDict('tenders.field_contact_id.text'),
        fieldset: ['contact_id'],
        class: 'column-contact',
        section_id: 2,
        dataCy: 'contact',
      },
      {
        key: 'phones',
        label: Vue.prototype.$vDict('tenders.field_phones.text'),
        fieldset: ['contact_id'],
        section_id: 2,
        dataCy: 'phones',
      },
      {
        key: 'email',
        label: Vue.prototype.$vDict('tenders.field_email.text'),
        fieldset: ['contact_id'],
        section_id: 2,
        dataCy: 'email',
      },
    ],
  },
  {
    key: 'info',
    label: Vue.prototype.$vDict('tenders.section_info.text'),
    icon: 'card-info',
    dataCy: 'info',
    fields: [
      {
        key: 'project_id',
        label: Vue.prototype.$vDict('tenders.field_project_id.text'),
        fieldset: ['project_id'],
        class: 'column-entity',
        section_id: 1,
        dataCy: 'project-name',
      },
      // {
      //   'label': 'Agreement type',
      //   'key': 'framework_agreement',
      //   'fieldset': ['framework_agreement'],
      //   'sortable': true,
      //   'section_id': 1,
      //   'sort': {
      //     'keys': ['framework_agreement']
      //   },
      // },
      // {
      //   'label': 'Role',
      //   'key': 'company_role_id',
      //   'fieldset': ['company_role_id'],
      //   'sortable': true,
      //   'section_id': 1,
      //   'sortKeys': ['company_role_id'],
      // },
      {
        key: 'company_role_id',
        label: Vue.prototype.$vDict('tenders.field_company_role_id.text'),
        fieldset: ['company_role_id', 'materials', 'tender_subject_id'],
        // 'sortable': true,
        section_id: 1,
        // 'sortKeys': ['company_role_id', 'materials'],
        dataCy: 'role-materials',
      },
      // {
      //   'label': 'Materials',
      //   'key': 'material_data', // структура
      //   'fieldset': ['material_data'],
      //   'sortable': true,
      //   'section_id': 1
      // },
      {
        key: 'tender_type_id',
        label: Vue.prototype.$vDict('tenders.field_tender_type_id.text'),
        fieldset: ['tender_type_id'],
        sortable: true,
        section_id: 1,
        sortKeys: ['tender_type_id'],
        dataCy: 'tender-type',
      },
      {
        key: 'url',
        label: Vue.prototype.$vDict('tenders.field_url.text'),
        fieldset: ['links'],
        section_id: 1,
        dataCy: 'url',
      },
      {
        key: 'value',
        label: Vue.prototype.$vDict('tenders.field_value.text'),
        fieldset: ['value', 'original_currency_id', 'value_unoff'],
        sortable: true,
        section_id: 1,
        sortKeys: ['value'],
        dataCy: 'value',
        valueStyle: enums.FIELD_VALUE_STYLES.CURRENCY,
      },
      {
        key: 'tender_status_id',
        label: Vue.prototype.$vDict('tenders.field_tender_status_id.text'),
        fieldset: ['tender_status_id'],
        sortable: true,
        section_id: 1,
        sortKeys: ['tender_status_id'],
        dataCy: 'tender-status',
        countries: [
          enums.COUNTRIES.SLOVAKIA,
          enums.COUNTRIES.CZECH,
        ],
      },
    ],
  },
  {
    key: 'dates',
    label: Vue.prototype.$vDict('tenders.section_dates.text'),
    icon: 'calendar',
    dataCy: 'dates',
    fields: [
      {
        key: 'first_day_materials_range', // 'startDate',
        label: Vue.prototype.$vDict('tenders.field_first_day_materials_range.text'),
        fieldset: ['first_day_materials_range', 'first_day_materials_text', 'start_unoff'],
        sortable: true,
        class: 'column-date',
        section_id: 4,
        sortKeys: ['first_day_materials_text'],
        dataCy: 'first-day-materials',
        sourceKey: 'first_day_materials_range',
      },
      {
        key: 'last_day_materials_range', // 'finishDate',
        label: Vue.prototype.$vDict('tenders.field_last_day_materials_range.text'),
        fieldset: ['last_day_materials_range', 'last_day_materials_text'],
        class: 'column-date',
        section_id: 4,
        sortable: true,
        sortKeys: ['last_day_materials_text'],
        dataCy: 'last-day-materials',
        sourceKey: 'last_day_materials_range',
      },
      {
        key: 'last_day_questions_range', // 'questionDate',
        label: Vue.prototype.$vDict('tenders.field_last_day_questions_range.text'),
        fieldset: ['last_day_questions_range', 'last_day_questions_text'],
        sortable: true,
        class: 'column-date',
        section_id: 4,
        sortKeys: ['last_day_questions_text'],
        dataCy: 'last-day-questions',
        sourceKey: 'last_day_questions_range',
      },
      {
        key: 'prequalification_date_range', // 'deadlineDate',
        label: Vue.prototype.$vDict('tenders.field_prequalification_date_range.text'),
        fieldset: ['prequalification_date_range', 'prequalification_date_text'],
        sortable: true,
        class: 'column-date',
        section_id: 4,
        sortKeys: ['prequalification_date_text'],
        dataCy: 'prequalification-date',
        sourceKey: 'prequalification_date_range',
      },
      {
        key: 'last_day_application_range', // 'appDate',
        label: Vue.prototype.$vDict('tenders.field_last_day_application_range.text'),
        fieldset: ['last_day_application_range', 'last_day_application_text'],
        sortable: true,
        class: 'column-date',
        section_id: 4,
        sortKeys: ['last_day_application_text'],
        dataCy: 'last-day-application',
        sourceKey: 'last_day_application_range',
      },
    ],
  },
  {
    key: 'tender_updates',
    label: Vue.prototype.$vDict('tenders.section_tender_updates.text'),
    icon: 'arrow-sync',
    dataCy: 'tender-updates',
    fields: [
      {
        key: 'latest_update_at',
        label: Vue.prototype.$vDict('tenders.field_latest_update_at.text'),
        fieldset: ['latest_update_at'],
        sortable: true,
        section_id: 5,
        sortKeys: ['latest_update_at'],
        // listTypeIds: [enums.LIST_TYPES.USER_TENDERS],
        dataCy: 'latest-update-at',
        sourceKey: 'latest_update_at',
      },
      {
        key: 'delivered_at',
        label: Vue.prototype.$vDict('tenders.field_delivered_at.text'),
        fieldset: ['delivered_at'],
        sortable: (statusId) => !!statusId && statusId !== 'all',
        section_id: 5,
        sortKeys: ['delivered_at'],
        listTypeIds: [enums.LIST_TYPES.USER_TENDERS],
        dataCy: 'delivered-at',
        sourceKey: 'delivered_at',
      },
      {
        key: 'responsible',
        label: Vue.prototype.$vDict('tenders.field_responsible.text'),
        fieldset: ['responsible_ids'],
        section_id: 5,
        listTypeIds: [enums.LIST_TYPES.USER_TENDERS],
        dataCy: 'responsible',
        // hideOnCard: true,
      },
      {
        key: 'responsible',
        label: Vue.prototype.$vDict('tenders.field_responsible.text'),
        fieldset: [],
        section_id: 5,
        listTypeIds: [enums.LIST_TYPES.ALL_TENDERS],
        dataCy: 'responsible',
        // hideOnCard: true,
      },
      {
        key: 'client_statuses',
        label: Vue.prototype.$vDict('tenders.field_client_statuses.text'),
        fieldset: ['users'],
        section_id: 5,
        listTypeIds: [enums.LIST_TYPES.USER_TENDERS],
        dataCy: 'client-statuses',
        // hideOnCard: true,
      },
    ],
  },
  {
    key: 'additional_fields',
    label: Vue.prototype.$vDict('tenders.section_additional_fields.text'),
    icon: 'baseline-format_list_view',
    dataCy: 'additional-fields',
    fields: [
      {
        key: 'contact_info',
        label: Vue.prototype.$vDict('tenders.field_contact_info.text'),
        fieldset: ['contact_id'],
        class: 'column-entity',
        listTypeIds: [
          enums.LIST_TYPES.PROJECT_TENDERS,
          enums.LIST_TYPES.COMPANY_TENDERS,
        ],
        section_id: 6,
        dataCy: 'contact-info',
      },
    ],
  },
  {
    key: 'custom_fields',
    label: Vue.prototype.$vDict('tenders.section_custom_fields.text'),
    icon: 'internal',
    dataCy: 'custom-fields',
    fields: [

    ],
  },
];

export const fieldsBlackList = () => ({
  prequalification_date_range: {
    countries: [
      enums.COUNTRIES.SWEDEN,
      enums.COUNTRIES.CZECH,
      enums.COUNTRIES.SLOVAKIA,
    ],
  },
  last_day_materials_range: {
    countries: [
      enums.COUNTRIES.SWEDEN,
      enums.COUNTRIES.DENMARK,
      enums.COUNTRIES.CZECH,
      enums.COUNTRIES.SLOVAKIA,
    ],
  },
  last_day_questions_range: {
    countries: [
      enums.COUNTRIES.DENMARK,
      enums.COUNTRIES.CZECH,
      enums.COUNTRIES.SLOVAKIA,
    ],
  },
});

export const printSettingsFields = () => {
  return [
    {
      key: 'description',
      fieldset: ['description', 'content_standard', 'content_ted', 'project_id'],
      label: Vue.prototype.$vDict('tenders.field_description.text'),
    },
  ];
};
