<template>
  <div class="clients-table-view">
    <base-table-view
      selectable
      :items="items"
      :fields="fields"
      :sort="sort"
      :entity-list="entityList"
      :fixed-required-columns="fixedRequiredColumns"
      @change-sort="changeSort"
    >
      <template v-slot:cell(name)="data">
        <div class="field-title">
          <b-link
            class="field-title__text"
            :to="data.item.getLinkTo()"
            @click.native="data.item.linkToHandler($event)"
          >{{ data.value }}</b-link>
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <div
          v-if="data.value"
          class="status-tag"
          :class="data.value.class"
        >{{ data.value.name }}</div>
      </template>
    </base-table-view>
    
    <client-actions
      ref="entityActions"
      :dataset="entityList.dataset"
    />
  </div>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import ClientActions from "@/entities/client/views/ClientActions";

export default {
  name: 'clients-table-view',
  components: {
    BaseTableView,
    ClientActions
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
