import Vue from 'vue';
import store from '@/store';
import { getSubscriptionVersions } from "@/api/repositories/myranRepository";

export const requiredFields = () => [
  {
    key: 'id',
    label: 'id',
    class: 'column-id',
    sortable: true,
    fieldset: ['id'],
  },
  {
    key: 'subscription_customer_id',
    label: 'Subscription customer id',
    details: true,
    fieldset: ['subscription_customer_id'],
  },
  {
    key: 'source_type_id',
    label: 'Source type',
    details: true,
    fieldset: ['source_type_id'],
  },
  {
    key: 'source_id',
    label: 'Source id',
    fieldset: ['source_id'],
  },
  {
    key: 'source_customer_id',
    label: 'Source customer id',
    fieldset: ['source_customer_id'],
  },
  {
    key: 'customer_name',
    label: 'Customer name',
    main: true,
    fieldset: ['customer_name'],
  },
  {
    key: 'source_created_at',
    label: 'Source created at',
    fieldset: ['source_created_at'],
  },
  {
    key: 'source_modified_at',
    label: 'Source modified at',
    fieldset: ['source_modified_at'],
  },
  {
    key: 'support_name',
    label: 'Support name',
    details: true,
    fieldset: ['support_name'],
  },
  {
    key: 'support_email',
    label: 'Support email',
    details: true,
    fieldset: ['support_email'],
  },
  {
    key: 'support_phone',
    label: 'Support phone',
    details: true,
    fieldset: ['support_phone'],
  },
  {
    key: 'start_date',
    label: 'Start date',
    details: true,
    fieldset: ['start_date'],
  },
  {
    key: 'end_date',
    label: 'End date',
    details: true,
    fieldset: ['end_date'],
  },
  {
    key: 'expiration_date',
    label: 'Expiration date',
    details: true,
    fieldset: ['expiration_date'],
  },
  {
    key: 'project_from_date',
    label: 'Project from date',
    details: true,
    fieldset: ['project_from_date'],
  },
  {
    key: 'support_country',
    label: 'Support country',
    details: true,
    fieldset: ['support_country'],
  },
  {
    key: 'selection',
    label: 'Selection',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
    fieldset: [],
    asyncData: (item) => ({
      requestMethod: getSubscriptionVersions,
      requestQuery: {
        fields: ['selection'],
        filter: {
          id: { in: [item.id] },
        },
      },
      responseMethod: (response) => _.get(response, 'data[0].selection') || {},
    }),
  },
  {
    key: 'addons',
    label: 'Addons',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
    fieldset: ['addons'],
  },
  {
    key: 'created_at',
    label: 'Created at',
    fieldset: ['created_at'],
  },
  {
    key: 'subscription_data',
    label: 'Subscription data',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
    fieldset: [],
    asyncData: (item) => ({
      requestMethod: getSubscriptionVersions,
      requestQuery: {
        fields: ['subscription_data'],
        filter: {
          id: { in: [item.id] },
        },
      },
      responseMethod: (response) => _.get(response, 'data[0].subscription_data') || {},
    }),
  },
  {
    key: 'subscription_type_id',
    label: 'Subscription type',
    fieldset: ['subscription_type_id'],
  },
  {
    key: 'date_migrate_user_data',
    label: 'Date migrate user data',
    details: true,
    fieldset: ['date_migrate_user_data'],
  },
  {
    key: 'source_id_migrate_user_data',
    label: 'Source id migrate user data',
    details: true,
    fieldset: ['source_id_migrate_user_data'],
  },
  {
    key: 'source_system_migrate_user_data',
    label: 'Source system migrate user data',
    details: true,
    fieldset: ['source_system_migrate_user_data'],
  },
  {
    key: 'link_user_profiles',
    label: 'External User Updates',
    details: true,
    fieldset: [],
    isHidden: !store.getters['Account/hasComponent']('myran_logs'),
  },
].filter(field => !field.isHidden);
