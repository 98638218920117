/*
  ReleaseNote class
 */

import Vue from 'vue';
import store from '@/store';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import {
  ENTITY_TYPES,
  LANGUAGES,
  COUNTRIES,
} from '@/config/enums';
import {
  deleteReleaseNote,
  getReleaseNotes,
  patchReleaseNote,
  getReleaseNote,
  putReleaseNote,
  postReleaseNote,
} from "@/api/repositories/releaseNotesRepository";
import { formatDateTimeNoWrap } from "@/shared/utils";

export class ReleaseNote extends BaseEntity {
  static entityKey = 'release_note';
  static datasetKey = 'releaseNotes';
  static entityTypeId = ENTITY_TYPES.RELEASE_NOTE;
  static routes = {
    edit: 'SupportEditorReleaseNote',
    create: 'SupportEditorReleaseNoteNew',
    list: 'SupportReleaseNotes',
  };

  // api
  static loadAllItemsFunc = getReleaseNotes;
  static loadItemFunc = getReleaseNote;
  static putItemFunc = putReleaseNote;
  static postItemFunc = postReleaseNote;
  static deleteItemFunc = deleteReleaseNote;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('release_notes.plural_release_notes_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = ReleaseNote;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: Vue.prototype.$vDict('release_notes.edit_release_button.text'),
        icon: 'edit',
      },
      {
        key: 'publish',
        icon: 'power',
        name: Vue.prototype.$vDict('release_notes.publish_release_button.text'),
        linkClass: 'action-link--secondary',
      },
      {
        linkClass: 'action-link--warning',
        key: 'unpublish',
        icon: 'deactivated',
        name: Vue.prototype.$vDict('release_notes.unpublish_release_button.text')
      },
      {
        type: 'divider',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'release_date':
        res = fieldValue ? formatDateTimeNoWrap(fieldValue) : '';
        break;
      case 'enable_languages':
        res = this.getEnableLanguages();
        break;
      case 'countries':
        res = this.getCountries();
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'status':
        res = this.getStatus();
        break;
      default:
    }

    return res;
  }

  getStatus() {
    return this.getValue('publish_status') ?
      { class: 'status-tag--active', name: Vue.prototype.$vDict('release_notes.release_status_published.text') } :
      { class: 'status-tag--disabled', name: Vue.prototype.$vDict('release_notes.release_status_unpublished.text') };
  }

  getEnableLanguages() {
    const enableLanguages = this.getValue('enable_languages', []);

    return enableLanguages.reduce((acum, langId) => {
      const langProps = LANGUAGES.properties[langId];
      return langProps ? [...acum, langProps] : acum;
    }, []);
  }

  getCountries() {
    const countries = this.getValue('countries', []);

    return countries.reduce((acum, countryId) => {
      const langProps = COUNTRIES.properties[countryId];
      return langProps ? [...acum, langProps] : acum;
    }, []);
  }

  getActions() {
    const actions = this.selfClass.getAllActions();
    return actions.filter(action => {
      let res = true;

      switch (action.key) {
        case 'publish':
          res = !this.getValue('publish_status');
          break;
        case 'unpublish':
          res = this.getValue('publish_status');
          break;
        case 'delete':
          res = store.getters['Account/hasComponent']('release_note_delete_button');
          break;
        default:
      }

      return res;
    });
  }

  async changePublishValue(publish = false) {
    const patchData = { publish_status: publish };
    let response;

    try {
      response = await patchReleaseNote(this.id, patchData);

      if (response) {
        this.setValue('publish_status', publish);
      }
    } catch (error) {}
  }

  async doAction(action) {
    switch (action.key) {
      case 'publish':
        await this.changePublishValue(true);
        break;
      case 'unpublish':
        await this.changePublishValue(false);
        break;
      default:
        await super.doAction(action);
    }
  }
}
