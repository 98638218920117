import enums from "@/config/enums";

export const getEmailLogsListsConfig = () => ({
  [enums.LIST_TYPES.USER_EMAIL_LOGS]: {
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
  [enums.LIST_TYPES.ALL_EMAIL_LOGS]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
});
