/*
  VortalProjectsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import VortalProjectsTableView from "@/entities/vortal-project/views/VortalProjectsTableView";
import Vue from "vue";
import { getVortalProjectsListsConfig } from "@/entities/vortal-project/config";
import { getProjects } from "@/api/repositories/vortalConnectRepository";
import { requiredFields } from "@/entities/vortal-project/fields";
import { VortalProject } from "@/entities";

export class VortalProjectsList extends EntityList {
  static entityClass = VortalProject;

  constructor(listTypeId) {
    super(listTypeId, getVortalProjectsListsConfig);

    this.tableView = VortalProjectsTableView;

    this.selfClass = VortalProjectsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'id',
        dataCy: 'project-id',
        name: Vue.prototype.$vDict('vortal_connect_projects.filter_project_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_projects.filter_project_id_input_placeholder.text'),
        },
      },
      {
        key: 'vortal_operation_uid',
        dataCy: 'vortal-operation-uid',
        name: Vue.prototype.$vDict('vortal_connect_projects.filter_vortal_operation_uid_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_projects.filter_vortal_operation_uid_input_placeholder.text'),
        },
      },
      {
        key: 'country_id',
        dataCy: 'country',
        name: Vue.prototype.$vDict('vortal_connect_projects.filter_country_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
      {
        key: 'vortal_company_identifier_type',
        dataCy: 'identifier-type',
        name: Vue.prototype.$vDict('vortal_connect_projects.filter_vortal_company_identifier_type_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('VORTAL_COMPANY_IDENTIFIER_TYPES'),
        },
      },
      {
        key: 'company_identifier',
        dataCy: 'identifier',
        name: Vue.prototype.$vDict('vortal_connect_projects.filter_company_identifier_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_projects.filter_company_identifier_input_placeholder.text'),
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('vortal_connect_projects.filter_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'modified_at',
        dataCy: 'modified-at',
        name: Vue.prototype.$vDict('vortal_connect_projects.filter_modified_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
    ];
  }
}
