/*
  CompaniesList class
 */

import { BaseCompaniesList } from "@/entities/company/BaseCompaniesList";
import { Company } from "@/entities/company/Company";

export class CompaniesList extends BaseCompaniesList {
  static entityClass = Company;

  constructor(listTypeId) {
    super(listTypeId);

    this.selfClass = CompaniesList;
  }
}
