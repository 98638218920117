/*
  VortalContactsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import VortalContactsTableView from "@/entities/vortal-contact/views/VortalContactsTableView";
import Vue from "vue";
import { getVortalContactsListsConfig } from "@/entities/vortal-contact/config";
import { getContacts, getCompanies } from "@/api/repositories/vortalConnectRepository";
import { requiredFields } from "@/entities/vortal-contact/fields";
import { VortalContact } from "@/entities";

export class VortalContactsList extends EntityList {
  static entityClass = VortalContact;

  constructor(listTypeId) {
    super(listTypeId, getVortalContactsListsConfig);

    this.tableView = VortalContactsTableView;

    this.selfClass = VortalContactsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'id',
        dataCy: 'company-id',
        name: Vue.prototype.$vDict('vortal_connect_contacts.filter_vortal_contact_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_contacts.filter_vortal_contact_id_input_placeholder.text'),
        },
      },
      {
        key: 'vortal_user_code',
        dataCy: 'vortal-user-code',
        name: Vue.prototype.$vDict('vortal_connect_contacts.filter_vortal_user_code_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_contacts.filter_vortal_user_code_input_placeholder.text'),
        },
      },
      {
        key: 'email',
        dataCy: 'email',
        name: Vue.prototype.$vDict('vortal_connect_contacts.filter_email_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_contacts.filter_email_input_placeholder.text'),
        },
      },
      {
        key: 'country_id',
        dataCy: 'country',
        name: Vue.prototype.$vDict('vortal_connect_contacts.filter_country_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
      {
        key: 'banned',
        dataCy: 'banned',
        name: Vue.prototype.$vDict('vortal_connect_contacts.filter_banned_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [{id: 0, name: Vue.prototype.$vDict('global.text_no.text')}, {id: 1, name: Vue.prototype.$vDict('global.text_yes.text')}],
        },
      },
      {
        key: 'unsubscribed',
        dataCy: 'unsubscribed',
        name: Vue.prototype.$vDict('vortal_connect_contacts.filter_unsubscribed_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [{id: 0, name: Vue.prototype.$vDict('global.text_no.text')}, {id: 1, name: Vue.prototype.$vDict('global.text_yes.text')}],
        },
      },
      {
        key: 'vortal_company_id',
        dataCy: 'vortal-company',
        name: Vue.prototype.$vDict('vortal_connect_contacts.filter_vortal_company_label.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_contacts.filter_vortal_company_input_placeholder.text'),
          searchFunc: getCompanies,
          isMultiple: true,
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('vortal_connect_contacts.filter_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'modified_at',
        dataCy: 'modified-at',
        name: Vue.prototype.$vDict('vortal_connect_contacts.filter_modified_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
    ];
  }
}
