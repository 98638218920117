import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('custom_fieldset.field_template_name.text'),
    class: 'column-name',
    sortable: true,
  },
  {
    key: 'count_custom_field',
    label: Vue.prototype.$vDict('custom_fieldset.field_count_custom_field.text'),
  },
  {
    key: 'entity_type_id',
    label: Vue.prototype.$vDict('custom_fieldset.field_section.text'),
    class: 'column-section',
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('custom_fieldset.field_created_at.text'),
    class: 'column-created-at',
    sortable: true,
  },
];
