/*
  VortalProject class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getProjects } from "@/api/repositories/vortalConnectRepository";
import { formatDateTimeNoWrap } from "@/shared/utils";

export class VortalProject extends BaseEntity {
  static entityKey = 'vortal_project';
  static datasetKey = 'vortalProjects';
  static entityTypeId = ENTITY_TYPES.VORTAL_PROJECT;
  static routes = {
    edit: 'VortalConnectEditorLog',
  };

  // api
  static loadAllItemsFunc = getProjects;
  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('vortal_connect_projects.projects_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = VortalProject;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
      case 'modified_at':
        res = fieldValue ? formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', { id: fieldValue, prop: 'name' });
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'smart_id':
        res = this.getDatasetItem(dataset, 'smartProjects', fieldValue);
        break;
      case 'vortal_contact_id':
        value = this.getDatasetItem(dataset, 'vortalContacts', fieldValue);
        res = value?.name || '';
        break;
      case 'vortal_company_id':
        value = this.getDatasetItem(dataset, 'vortalCompanies', fieldValue);
        res = value?.name || '';
        break;
      default:
    }

    return res;
  }
}
