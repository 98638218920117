import store from "@/store";
import { LIST_TYPES } from "@/config/enums";

export const getListUsersListsConfig = () => ({
  [LIST_TYPES.CLIENT_USERS]: {
    defaultSearchFilter: {
      default: {
        'user_profile.is_suspended': 0,
        'subscription_status': 'at_least_one_active',
      },
    },
    defaultSort: {
      default: {
        user_id: { order: 'a' },
      },
    },
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('client_users_excel_export'),
    },
    // affectTotalFilterKeys: {
    //   default: ['user.is_suspended'],
    // },
    hasAccess: () => store.getters['Account/hasComponent']('all_clients'),
  },
});
