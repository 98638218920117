import enums from "@/config/enums";

export const getErrorMessagesListsConfig = () => ({
  [enums.LIST_TYPES.ERROR_MESSAGES]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        created_at: { order: 'd' },
      },
    },
  },
});
