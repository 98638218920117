import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'smart_id',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_smart_project.text'),
  },
  {
    key: 'rsm_id',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_rsm_id.text'),
  },
  {
    key: 'vortal_operation_uid',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_vortal_operation_uid.text'),
  },
  {
    key: 'country_id',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_country.text'),
  },
  {
    key: 'vortal_contact_id',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_vortal_contact.text'),
  },
  {
    key: 'vortal_company_id',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_vortal_company.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_modified_at.text'),
    sortable: true,
  },
  {
    key: 'created_by',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_created_by.text'),
    details: true,
  },
  {
    key: 'modified_by',
    label: Vue.prototype.$vDict('vortal_connect_projects.field_modified_by.text'),
    details: true,
  },
];
