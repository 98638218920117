import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'pm_client_version_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
  },
  {
    key: 'source_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_source_id.text'),
  },
  {
    key: 'parabyg_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_parabyg_id.text'),
  },
  {
    key: 'rsm_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_rsm_id.text'),
  },
  {
    key: 'entity_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_entity_id.text'),
  },
  {
    key: 'is_valid',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
  },
  {
    key: 'validation_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_validation_error_message.text'),
    details: true,
  },
  {
    key: 'is_migrated',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_migrated.text'),
  },
  {
    key: 'migration_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_migration_error_message.text'),
    details: true,
  }
];
