/*
  Custom field metric class
 */

import BaseEntity from '@/shared/classes/entity/BaseEntity';
import {
  ENTITY_TYPES,
} from '@/config/enums';
import {
  getCustomFieldMetrics,
  getCustomFieldMetric,
  putCustomFieldMetric,
  postCustomFieldMetric, deleteCustomFieldMetric,
} from "@/api/repositories/customFieldsRepository";
import Vue from "vue";

const defaultData = () => ({
  id: null,
  name: '',
  key: '',
  description: '',
});

export class CustomFieldMetric extends BaseEntity {
  static entityKey = 'custom_field_metric';
  static datasetKey = 'customFieldMetrics';
  static routes = {
    edit: 'SystemCustomFieldMetricEditor',
    create: 'SystemCustomFieldMetricNew',
    list: 'SystemCustomFieldMetrics',
  };
  static entityTypeId = ENTITY_TYPES.CUSTOM_FIELD_METRIC;

  // api
  static loadAllItemsFunc = getCustomFieldMetrics;
  static loadItemFunc = getCustomFieldMetric;
  static putItemFunc = putCustomFieldMetric;
  static postItemFunc = postCustomFieldMetric;
  static deleteItemFunc = deleteCustomFieldMetric;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('custom_field_metrics.plural_custom_field_metrics.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    this.selfClass = CustomFieldMetric;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: Vue.prototype.$vDict('custom_field_metrics.edit_metric_button.text'),
        icon: 'edit',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }
}
