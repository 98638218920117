/*
  SubscriptionsList class
 */

import Vue from 'vue';
import EntityList from "@/shared/classes/list/EntityList";
import SubscriptionsTableView from "@/entities/subscriptions/table-views/SubscriptionsTableView";
import { getSubscriptionsListsConfig } from "@/entities/subscriptions/config";
import { requiredFields } from "@/entities/subscriptions/fields";
import { Subscription } from "@/entities";

export class SubscriptionsList extends EntityList {
  static entityClass = Subscription;

  constructor(listTypeId) {
    super(listTypeId, getSubscriptionsListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = SubscriptionsTableView;

    this.selfClass = SubscriptionsList;
  }

  getEntityPluralTypeText() {
    return Vue.prototype.$vDict('subscriptions.plural_text.text');
  }
}
