/*
  MyranClientVersion class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getClientVersions } from '@/api/repositories/myranRepository';

export class MyranClientVersion extends BaseEntity {
  static entityKey = 'myran_client_version';
  static datasetKey = 'myranClientVersions';
  static entityTypeId = ENTITY_TYPES.MYRAN_CLIENT_VERSION;

  // api
  static loadAllItemsFunc = getClientVersions;
  static getEntityPluralTypeText() {
    // return Vue.prototype.$vDict('user_files.plural_files_text.text');
    return 'Client versions';
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = MyranClientVersion;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'source_type_id':
        value = this.getItemFromEnum('SOURCE_TYPES', fieldValue);
        res = value?.name || '';
        break;
      case 'link_user_versions':
        res = this.getValue('id');
        break;
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', {
          id: fieldValue,
          prop: 'name',
        });
        break;
      case 'created_at':
        res = fieldValue;
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }
}
