/*
  UserContact class
 */

import { BaseContact } from "@/entities/contact/BaseContact";

export class Contact extends BaseContact {
  static routes = BaseContact.allSectionRoutes;

  constructor(...args) {
    super(...args);

    this.selfClass = Contact;
  }
}
