import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'avatar',
    label: '',
    class: 'column-min',
  },
  {
    key: 'user_name',
    label: Vue.prototype.$vDict('usage_stats.field_user_name.text'),
    sortable: true,
  },
  {
    key: 'stat_project_view',
    label: Vue.prototype.$vDict('usage_stats.stat_project_view.text'),
    sortable: true,
  },
  {
    key: 'stat_tender_view',
    label: Vue.prototype.$vDict('usage_stats.stat_tender_view.text'),
    sortable: true,
  },
  {
    key: 'stat_company_view',
    label: Vue.prototype.$vDict('usage_stats.stat_company_view.text'),
    sortable: true,
  },
  {
    key: 'stat_contact_view',
    label: Vue.prototype.$vDict('usage_stats.stat_contact_view.text'),
    sortable: true,
  },
  {
    key: 'stat_auth',
    label: Vue.prototype.$vDict('usage_stats.stat_auth_month.text'),
    sortable: true,
  },
  {
    key: 'last_login',
    label: Vue.prototype.$vDict('usage_stats.last_login.text'),
    sortable: true,
  },
];
