/*
  Smart selection class
 */

import Vue from 'vue';
import {
  ENTITY_TYPES,
} from "@/config/enums";
import BaseEntity from "@/shared/classes/entity/BaseEntity";
import {
  getSmartSelections,
} from "@/api/repositories/smartSelectionsRepository";
import { downLevelIds, upLevelIds } from "@/shared/utils";
import { formatBudgetRange } from "@/shared/formatters";

const initialData = () => ({
  source_type_id: null,
  source_id: null,
  name: '',
  description: '',
  administrative_units: [],
  countries: [],
  categories: [],
  development_types: [],
  stages: [],
  materials: [],
  planning_stages: [],
  project_types: [],
  value_min: 0,
  value_max: 0,
  currency_id: null,
  event_roles: [],
  view_roles: [],
  tender_event_roles: [],
  tender_view_roles: [],
});

export class SmartSelection extends BaseEntity {
  static entityKey = 'user_profile';
  static loadAllItemsFunc = getSmartSelections;
  static datasetKey = 'user_profiles';
  static entityTypeId = ENTITY_TYPES.SMART_SELECTION;

  constructor(...args) {
    super(initialData, ...args);

    // this.loadListFunc = getClients;
    this.formData = {};
    this.initCustomName();
    this.selfClass = SmartSelection;
  }

  prepareItemData(data, defaultData) {
    const {
      subscription = null,
      ...restData
    } = data;

    this.subscription = subscription;

    _.merge(this.data, defaultData(), restData);
  }

  getName() {
    return this.name;
  }

  getFormattedBudget() {
    const valueMin = this.getValue('value_min');
    const valueMax = this.getValue('value_max');
    const budgetValue = [
      valueMin,
      valueMax === 0 ? vars.CONST_MAX_BUDGET_LIMIT : valueMax,
    ].filter(el => el !== null);
    return budgetValue.length > 0 ? formatBudgetRange(budgetValue, {
      currencyId: this.getValue('currency_id'),
    }) : null;
  }

  getFiltersLookups() {
    const countries = this.getValue('countries', []);
    const lookupOptions = {
      all: true,
      countries,
    };

    return {
      categories: Vue.prototype.$lDict('client.project_categories', lookupOptions),
      development_types: Vue.prototype.$lDict('client.development_types', lookupOptions),
      planning_stages: Vue.prototype.$lDict('client.planning_stages', lookupOptions),
      roles: Vue.prototype.$lGetCompanyStageRoles(lookupOptions),
      tender_roles: Vue.prototype.$lGetCompanyStageRoles({
        ...lookupOptions,
        rolesLookupKey: 'client.company_role_tender',
      }),
    };
  }

  getFilters(locations = []) {
    const formattedBudgetValue = this.getFormattedBudget();

    return [
      {
        key: 'administrative_units',
        name: Vue.prototype.$vDict('smart_selections.field_administrative_units.text'),
        value: downLevelIds(locations, this.getValue('administrative_units', [])),
      },
      {
        key: 'categories',
        name: Vue.prototype.$vDict('smart_selections.field_categories.text'),
        value: this.getValue('categories', []),
        lookupKey: 'categories',
      },
      {
        key: 'development_types',
        name: Vue.prototype.$vDict('smart_selections.field_development_types.text'),
        value: this.getValue('development_types', []),
        lookupKey: 'development_types',
      },
      {
        key: 'planning_stages',
        name: Vue.prototype.$vDict('smart_selections.field_planning_stages.text'),
        value: this.getValue('planning_stages', []),
        lookupKey: 'planning_stages',
      },
      {
        key: 'event_roles',
        name: Vue.prototype.$vDict('smart_selections.field_event_roles.text'),
        value: this.getValue('event_roles', []),
        lookupKey: 'roles',
      },
      {
        key: 'view_roles',
        name: Vue.prototype.$vDict('smart_selections.field_view_roles.text'),
        value: this.getValue('view_roles', []),
        lookupKey: 'roles',
      },
      {
        key: 'tender_event_roles',
        name: Vue.prototype.$vDict('smart_selections.field_tender_event_roles.text'),
        value: this.getValue('tender_event_roles', []),
        lookupKey: 'tender_roles',
      },
      {
        key: 'tender_view_roles',
        name: Vue.prototype.$vDict('smart_selections.field_tender_view_roles.text'),
        value: this.getValue('tender_view_roles', []),
        lookupKey: 'tender_roles',
      },
      {
        key: 'value',
        name: Vue.prototype.$vDict('smart_selections.field_value.text'),
        value: formattedBudgetValue,
        type: 'budget',
      },
    ];
  }

  getFormData(locations = []) {
    const data = this.getData();
    return {
      ...data,
      countries: data.countries || [],
      categories: data.categories || [],
      development_types: data.development_types || [],
      stages: data.stages || [],
      materials: data.materials || [],
      planning_stages: data.planning_stages || [],
      project_types: data.project_types || [],
      event_roles: data.event_roles || [],
      view_roles: data.view_roles || [],
      tender_event_roles: data.tender_event_roles || [],
      tender_view_roles: data.tender_view_roles || [],
      administrative_units: downLevelIds(locations, this.getValue('administrative_units', [])),
    };
  }

  static convertFormToData(formData = {}, locations = []) {
    return {
      ...formData,
      administrative_units: upLevelIds(locations, formData.administrative_units || []),
    };
  }
}
