<template>
  <base-modal
    ref="modal"
    :title="modalTitle"
    title-tag="h3"
    size="lg"
    hide-footer
    :is-loading-content="isLoading"
    @show="onShowModal"
    @hidden="onHiddenModal"
  >
    <smart-selection-info
      :selection="selection"
      :locations="locations"
    />
  </base-modal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import MixinModal from "@/mixins/MixinModal";
import SmartSelectionInfo from "@/entities/smart-selection/views/SmartSelectionInfo.vue";
import { loadLocationList } from "@/shared/api-helpers/geoApiHelper";

export default {
  name: "smart-selection-info-modal",
  components: {
    BaseModal,
    SmartSelectionInfo,
  },
  mixins: [
    MixinModal,
  ],
  props: {
    selection: { type: Object, default: Object },
  },
  data() {
    return {
      locations: [],
      isLoading: false,
    };
  },
  computed: {
    modalTitle() {
      return `Selection ${this.selection?.getName()}`;
    },
  },
  methods: {
    async onShowModal() {
      this.isLoading = true;
      this.locations = await loadLocationList({
        showCountries: true,
        accessType: 'all',
      });
      this.isLoading = false;
    },
    onHiddenModal() {
      this.locations = [];
    },
  },
}
</script>
