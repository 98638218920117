import enums from '@/config/enums';
import Vue from "vue";

export const requiredFields = () => [
  // required
  {
    key: 'status',
    label: Vue.prototype.$vDict('companies.field_status.text'),
    class: 'column-min',
    required: true,
    listTypeIds: [
      enums.LIST_TYPES.ALL_COMPANIES,
      enums.LIST_TYPES.PROJECT_COMPANIES,
    ],
    components: ['user_companies'],
    dataCy: 'status',
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('companies.field_status.text'),
    class: 'column-min',
    required: true,
    fieldset: ['viewed', 'client_company_folder_id'],
    listTypeIds: [enums.LIST_TYPES.USER_COMPANIES],
    components: ['user_companies'],
    dataCy: 'status',
  },
  {
    key: 'notes',
    label: Vue.prototype.$vDict('companies.field_notes.text'),
    class: 'column-min',
    required: true,
    components: ['company_notes', 'company_activities'],
    dataCy: 'notes',
  },
  // {
  //   'key': 'coworkers',
  //   'label': '',
  //   'class': 'column-min',
  //   'required': true,
  // },
  {
    key: 'tags',
    label: Vue.prototype.$vDict('companies.field_tags.text'),
    fieldset: ['tags'],
    class: 'column-tags',
    required: true,
    listTypeIds: [enums.LIST_TYPES.USER_COMPANIES],
    dataCy: 'tags',
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('companies.field_name.text'),
    fieldset: [
      'id',
      'name',
      'name2',
      'org_nr',
      'country_id',
      'company_type_id',
    ],
    class: 'column-name',
    sortable: true,
    required: true,
    sortKeys: ['name'],
    dataCy: 'name',
  },
];

export const fieldsSections = () => [
  {
    key: 'visiting_address',
    label: Vue.prototype.$vDict('companies.section_visiting_address.text'),
    dataCy: 'visiting-address',
    icon: 'card-geography',
    fields: [
      {
        key: 'visiting_region_id',
        label: Vue.prototype.$vDict('companies.field_visiting_region_id.text'),
        fieldset: ['addresses'],
        section_id: 3,
        dataCy: 'visiting-region',
      },
      {
        key: 'visiting_district_id',
        label: Vue.prototype.$vDict('companies.field_visiting_district_id.text'),
        fieldset: ['addresses'],
        section_id: 3,
        dataCy: 'visiting-district',
      },
      {
        key: 'visiting_postcode',
        label: Vue.prototype.$vDict('companies.field_visiting_postcode.text'),
        fieldset: ['addresses'],
        section_id: 3,
        dataCy: 'visiting-postcode',
      },
      {
        key: 'visiting_street',
        label: Vue.prototype.$vDict('companies.field_visiting_street.text'),
        fieldset: ['addresses'],
        section_id: 3,
        dataCy: 'visiting-street',
      },
    ],
  },
  {
    key: 'postal_address',
    label: Vue.prototype.$vDict('companies.section_postal_address.text'),
    dataCy: 'postal-address',
    icon: 'post_address',
    fields: [
      {
        key: 'post_region_id',
        label: Vue.prototype.$vDict('companies.field_post_region_id.text'),
        fieldset: ['addresses'],
        section_id: 4,
        dataCy: 'post-region',
      },
      {
        key: 'post_district_id',
        label: Vue.prototype.$vDict('companies.field_post_district_id.text'),
        fieldset: ['addresses'],
        section_id: 4,
        dataCy: 'post-district',
      },
      {
        key: 'post_city',
        label: Vue.prototype.$vDict('companies.field_post_city.text'),
        fieldset: ['addresses'],
        section_id: 4,
        dataCy: 'post-city',
      },
      {
        key: 'post_street',
        label: Vue.prototype.$vDict('companies.field_post_street.text'),
        fieldset: ['addresses'],
        section_id: 4,
        dataCy: 'post-street',
      },
    ],
  },
  {
    key: 'contact',
    label: Vue.prototype.$vDict('companies.section_contact.text'),
    dataCy: 'contact',
    icon: 'contacts',
    fields: [
      {
        key: 'phones',
        label: Vue.prototype.$vDict('companies.field_phones.text'),
        class: 'column-min',
        fieldset: ['communications'],
        section_id: 2,
        dataCy: 'phones',
      },
      {
        key: 'email',
        label: Vue.prototype.$vDict('companies.field_email.text'),
        class: 'column-min',
        fieldset: ['communications'],
        section_id: 2,
        dataCy: 'email',
      },
      {
        key: 'website',
        label: Vue.prototype.$vDict('companies.field_website.text'),
        class: 'column-min',
        fieldset: ['communications'],
        section_id: 2,
        dataCy: 'home-page',
      },
    ],
  },
  {
    key: 'company_info',
    label: Vue.prototype.$vDict('companies.section_company_info.text'),
    dataCy: 'company-info',
    icon: 'card-info',
    fields: [
      // {
      //   label: 'Employees number',
      //   labelIcon: 'users-actions',
      //   key: 'contact_count',
      //   fieldset: ['contact_count'],
      //   sortable: true,
      //   section_id: 5,
      //   sortKeys: ['contact_count'],
      // },
      {
        key: 'project_count',
        label: Vue.prototype.$vDict('companies.field_project_count.text'),
        fieldset: ['projects_count'],
        sortable: true,
        section_id: 5,
        sortKeys: ['projects_count'],
        dataCy: 'projects-count',
      },
      {
        key: 'project_value_range',
        label: Vue.prototype.$vDict('companies.field_project_value_range.text'),
        fieldset: ['projects_value_sum'],
        sortable: true,
        section_id: 5,
        sortKeys: ['projects_value_sum'],
        dataCy: 'project-value-range',
        valueStyle: enums.FIELD_VALUE_STYLES.CURRENCY,
        sourceKey: 'projects_value_sum',
      },
      {
        key: 'company_type_id',
        label: Vue.prototype.$vDict('companies.field_company_type_id.text'),
        fieldset: ['company_type_id'],
        sortable: true,
        section_id: 5,
        dataCy: 'company-type',
      },
    ],
  },
  {
    key: 'company_updates',
    label: Vue.prototype.$vDict('companies.section_company_updates.text'),
    dataCy: 'company-updates',
    icon: 'arrow-sync',
    fields: [
      {
        key: 'latest_update_at',
        label: Vue.prototype.$vDict('companies.field_latest_update_at.text'),
        fieldset: ['latest_update_at'],
        sortable: true,
        section_id: 6,
        sortKeys: ['latest_update_at'],
        // listTypeIds: [enums.LIST_TYPES.USER_COMPANIES],
        dataCy: 'latest-update-at',
        sourceKey: 'latest_update_at',
      },
      {
        key: 'delivered_at',
        label: Vue.prototype.$vDict('companies.field_delivered_at.text'),
        fieldset: ['delivered_at'],
        sortable: (statusId) => !!statusId && statusId !== 'all',
        section_id: 6,
        sortKeys: ['delivered_at'],
        listTypeIds: [enums.LIST_TYPES.USER_COMPANIES],
        dataCy: 'delivered-at',
        sourceKey: 'delivered_at',
      },
      {
        key: 'client_statuses',
        label: Vue.prototype.$vDict('companies.field_client_statuses.text'),
        fieldset: ['users'],
        listTypeIds: [enums.LIST_TYPES.USER_COMPANIES],
        dataCy: 'client-statuses',
        // hideOnCard: true,
      },
      // {
      //   'label': 'Total budget', // ?????
      //   'key': 'value',
      //   'fieldset': ['value'],
      //   'sortable': true,
      //   'section_id': 5
      // },
      // {
      //   'label': 'Construction start',
      //   'key': 'constStart',
      //   'fieldset': ['cfar_nr'],
      //   'sortable': true,
      //   'section_id': 5
      // },
    ],
  },
  {
    key: 'company',
    label: Vue.prototype.$vDict('companies.section_company.text'),
    dataCy: 'company',
    icon: 'company',
    fields: [
      // {
      //   label: 'Company nr',
      //   key: 'company_nr',
      //   fieldset: ['company_nr'],
      //   section_id: 1,
      // },
      {
        key: 'source_id',
        label: Vue.prototype.$vDict('companies.field_source_id.text'),
        fieldset: ['source_id'],
        section_id: 1,
        dataCy: 'company-nr',
      },
      {
        key: 'cfar_nr',
        label: Vue.prototype.$vDict('companies.field_cfar_nr.text'),
        fieldset: ['cfar_nr'],
        section_id: 1,
        dataCy: 'cfar-nr',
      },
      // {
      //   label: 'Par ID',
      //   key: 'par_id',
      //   fieldset: ['par_id'],
      //   section_id: 1,
      // },
    ],
  },
  {
    key: 'additional_fields',
    label: Vue.prototype.$vDict('companies.section_additional_fields.text'),
    icon: 'baseline-format_list_view',
    dataCy: 'additional-fields',
    fields: [
      {
        key: 'addresses',
        label: Vue.prototype.$vDict('companies.field_addresses.text'),
        fieldset: ['city', 'postcode', 'street', 'street_number'],
        listTypeIds: [enums.LIST_TYPES.PROJECT_COMPANIES],
        dataCy: 'addresses',
      },
      {
        key: 'project_company_roles',
        label: Vue.prototype.$vDict('companies.field_project_company_roles.text'),
        fieldset: [],
        listTypeIds: [enums.LIST_TYPES.PROJECT_COMPANIES],
      },
    ],
  },
  {
    key: 'custom_fields',
    label: Vue.prototype.$vDict('companies.section_custom_fields.text'),
    icon: 'internal',
    dataCy: 'custom-fields',
    fields: [

    ],
  },
];

export const fieldsBlackList = () => ({
  source_id: {
    countries: [
      enums.COUNTRIES.SWEDEN,
      enums.COUNTRIES.DENMARK,
      enums.COUNTRIES.FINLAND,
      enums.COUNTRIES.NORWAY,
      enums.COUNTRIES.CZECH,
      enums.COUNTRIES.SLOVAKIA,
    ],
  },
  cfar_nr: {
    countries: [
      enums.COUNTRIES.SWEDEN,
      enums.COUNTRIES.DENMARK,
      enums.COUNTRIES.FINLAND,
      enums.COUNTRIES.NORWAY,
      enums.COUNTRIES.CZECH,
      enums.COUNTRIES.SLOVAKIA,
      enums.COUNTRIES.LIECHTENSTEIN,
      enums.COUNTRIES.SWITZERLAND,
    ],
  },
  par_id: {
    countries: [
      enums.COUNTRIES.SWEDEN,
      enums.COUNTRIES.DENMARK,
      enums.COUNTRIES.FINLAND,
      enums.COUNTRIES.NORWAY,
      enums.COUNTRIES.CZECH,
      enums.COUNTRIES.SLOVAKIA,
    ],
  },
  visiting_region_id: {
    countries: [
      enums.COUNTRIES.LIECHTENSTEIN,
      enums.COUNTRIES.SWITZERLAND,
    ],
  },
  visiting_district_id: {
    countries: [
      enums.COUNTRIES.LIECHTENSTEIN,
      enums.COUNTRIES.SWITZERLAND,
    ],
  },
  visiting_postcode: {
    countries: [
      enums.COUNTRIES.LIECHTENSTEIN,
      enums.COUNTRIES.SWITZERLAND,
    ],
  },
  visiting_street: {
    countries: [
      enums.COUNTRIES.LIECHTENSTEIN,
      enums.COUNTRIES.SWITZERLAND,
    ],
  },
});
