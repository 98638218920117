/*
  UsersList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  SEARCH_FIELD_TYPE,
} from "@/config/enums";
import UsersTableView from "@/entities/user/table-views/AllUsersTableView.vue";
import Vue from "vue";
import { getUsersListsConfig } from "@/entities/user/config";
import { requiredFields } from "@/entities/user/fields";
import { User } from "@/entities";

export class UsersList extends EntityList {
  static entityClass = User;

  constructor(listTypeId) {
    super(listTypeId, getUsersListsConfig);

    this.clientData = {};
    this.tableView = UsersTableView;

    this.selfClass = UsersList;
    this.getRequiredFields = requiredFields;
  }

  getListSearchFields() {
    return [
      {
        fieldKey: 'keywords',
        fieldType: SEARCH_FIELD_TYPE.CUSTOM,
        fieldAttrs: {
          dataCy: 'search-keywords',
        },
        filterType: SEARCH_FILTER_TYPES.KEYWORDS,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('users.filter_keywords_input_placeholder.text'),
          autocomplete: true,
          autocompleteSettings: {
            autocompleteCallback: async (searchText, query) => {
              let response;

              try {
                response = await User.loadAllItemsFunc({
                  ...query,
                  search: searchText,
                });
              } catch (e) {}

              return {
                items: (response?.data || []).map(user => ({
                  id: user.id,
                  name: user.name,
                  email: user.email,
                  // label: user.email,
                })),
                total: response?.meta?.total,
              };
            },
            optionComponent: () => import("@/entities/user/views/UserOption.vue"),
            routeName: User.routes.view,
          },
        },
      },
    ];
  }
}
