/*
  UserTender class
 */

import { BaseTender } from "@/entities/tender/BaseTender";

export class UserTender extends BaseTender {
  static routes = BaseTender.userSectionRoutes;

  constructor(...args) {
    super(...args);

    this.selfClass = UserTender;
  }
}
