/*
  UserTendersList class
 */

import { BaseTendersList } from "@/entities/tender/BaseTendersList";
import { Tender } from "@/entities/tender/Tender";

export class TendersList extends BaseTendersList {
  static entityClass = Tender;

  constructor(listTypeId) {
    super(listTypeId);

    this.selfClass = TendersList;
  }
}
