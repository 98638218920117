<template>
  <base-table-view
    class="pm-clients-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(client_id)="data">
      <b-link
        v-if="data.value"
        :to="{ name: 'SupportClient', params: { id: data.item.getValue('client_id') } }"
        class="text-secondary"
      >{{ data.value.name }}</b-link>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'pm-clients-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
