<template>
  <div class="ssn-table-view">
    <base-table-view
      selectable
      :items="items"
      :fields="fields"
      :sort="sort"
      :entity-list="entityList"
      :fixed-required-columns="fixedRequiredColumns"
      @change-sort="changeSort"
    >
      <template v-slot:cell(client_id)="{ value }">
        <b-link
          v-if="value"
          :to="value.getLinkTo()"
          class="entity-link"
        >{{ value.getName() }}</b-link>
      </template>
      <template v-slot:cell(user_id)="data">
        <field-user
          v-if="data.value"
          :user-data="data.value"
        />
      </template>
      <template v-slot:cell(access_code)="data">
        <copied-text
          :text="data.value"
        />
      </template>
      <template v-slot:cell(actions)="data">
        <actions-field
          :possible-actions="possibleActions"
          :actions="data.item.getActions(entityList.dataset)"
          :is-loading="isLoadingEntityId(data.item.id)"
          @action="entityActionHandler(data.item, $event)"
        />
      </template>
    </base-table-view>

    <saved-search-notification-actions
      ref="entityActions"
    />
  </div>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import FieldUser from "@/components/entity-fields/FieldUser";
import CopiedText from "@/components/CopiedText";
import ActionsField from "@/components/table/fields/ActionsField.vue";
import SavedSearchNotificationActions
  from "@/entities/saved-search-notif/views/SavedSearchNotificationActions.vue";

export default {
  name: 'ssn-table-view',
  components: {
    BaseTableView,
    FieldUser,
    CopiedText,
    ActionsField,
    SavedSearchNotificationActions,
  },
  mixins: [
    tableViewMixin,
  ],
};
</script>

<style lang="scss" scoped>

</style>
