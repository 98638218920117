/*
  DeadMessageSubscriptionsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import { SEARCH_FILTER_TYPES } from "@/config/enums";
import DeadMessageSubscriptionsTableView from "@/entities/dead-message-subscription/views/DeadMessageSubscriptionsTableView";
import Vue from "vue";
import { getDeadMessageSubscriptionsListsConfig } from "@/entities/dead-message-subscription/config";
import { getDeadMessagesSubscriptions, deleteDeadMessageSubscription } from "@/api/repositories/dictionariesRepository";
import { requiredFields } from "@/entities/dead-message-subscription/fields";
import { DeadMessageSubscription } from "@/entities";

export class DeadMessageSubscriptionsList extends EntityList {
  static entityClass = DeadMessageSubscription;

  constructor(listTypeId) {
    super(listTypeId, getDeadMessageSubscriptionsListsConfig);

    this.tableView = DeadMessageSubscriptionsTableView;

    this.selfClass = DeadMessageSubscriptionsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('dead_message_subscriptions.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('search.input_id_placeholder.text'),
        },
      },
      {
        key: 'email',
        dataCy: 'email',
        name: Vue.prototype.$vDict('dead_message_subscriptions.field_email.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
      {
        key: 'service_name',
        dataCy: 'service-name',
        name: Vue.prototype.$vDict('dead_message_subscriptions.field_service_name.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
      {
        key: 'topic',
        dataCy: 'topic',
        name: Vue.prototype.$vDict('dead_message_subscriptions.field_topic.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
    ];
  }
}
