/*
  UserTendersList class
 */

import { TendersList, UserTender } from "@/entities";

export class UserTendersList extends TendersList {
  static entityClass = UserTender;
  static isUserSection = true;

  constructor(listTypeId) {
    super(listTypeId);

    this.statusId = 'all';
    this.selfClass = UserTendersList;
  }
}
