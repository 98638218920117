var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"smart-selection-info"},[_vm._t("fields",_vm._l((_vm.selectionFields),function(field){return _c('b-form-group',{key:field.key,attrs:{"label":field.name}},[_vm._v(_vm._s(field.value))])}),{"fields":_vm.selectionFields}),_c('collapsible-list',{attrs:{"items":_vm.filters},scopedSlots:_vm._u([{key:"item(administrative_units)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{staticClass:"form-group--section"},[_c('div',{staticClass:"form-group__centered-content form-group__section-content"},[_c('checkboxes-related',{attrs:{"view-only":"","value":item.value,"items":_vm.locations,"name-key":"title"}})],1)])]}},{key:"item(categories)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{staticClass:"form-group--section"},[_c('div',{staticClass:"form-group__centered-content form-group__section-content"},[_c('checkboxes-related',{attrs:{"view-only":"","value":item.value,"items":item.items}})],1)])]}},{key:"item(development_types)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{staticClass:"form-group--section"},[_c('div',{staticClass:"form-group__centered-content form-group__section-content"},[_c('checkboxes-column',{attrs:{"view-only":"","value":item.value,"items":item.items}})],1)])]}},{key:"item(planning_stages)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{staticClass:"form-group--section"},[_c('div',{staticClass:"form-group__centered-content form-group__section-content"},[_c('checkboxes-column',{attrs:{"view-only":"","value":item.value,"items":item.items}})],1)])]}},{key:"item(event_roles)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{staticClass:"form-group--section"},[_c('div',{staticClass:"form-group__centered-content form-group__section-content"},[_c('checkboxes-related',{attrs:{"view-only":"","value":item.value,"items":item.items}})],1)])]}},{key:"item(view_roles)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{staticClass:"form-group--section"},[_c('div',{staticClass:"form-group__centered-content form-group__section-content"},[_c('checkboxes-related',{attrs:{"view-only":"","value":item.value,"items":item.items}})],1)])]}},{key:"item(tender_event_roles)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{staticClass:"form-group--section"},[_c('div',{staticClass:"form-group__centered-content form-group__section-content"},[_c('checkboxes-related',{attrs:{"view-only":"","value":item.value,"items":item.items}})],1)])]}},{key:"item(tender_view_roles)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{staticClass:"form-group--section"},[_c('div',{staticClass:"form-group__centered-content form-group__section-content"},[_c('checkboxes-related',{attrs:{"view-only":"","value":item.value,"items":item.items}})],1)])]}},{key:"li(value)",fn:function(ref){
var item = ref.item;
return [_c('li',{staticClass:"selection-not-collapsible-item"},[_c('b',[_vm._v(_vm._s(item.originName)+":")]),_vm._v(" "+_vm._s(item.value)+" ")])]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }