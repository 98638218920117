<template>
  <base-table-view
    class="pm-custom-fields-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(pm_client_version_id)="data">
      <client-version-field
        :version-id="data.value"
      />
    </template>
    <template
      v-for="linkSlot in linkToListSlots"
      v-slot:[`cell(${linkSlot.slotKey})`]="data"
    >
      <b-link
        :to="{ name: linkSlot.routeName, query: { searchFilter: `pm_custom_field_id:${data.item.id}` }  }"
        class="text-secondary"
      >{{ $vDict('entity_list.show_list_link.text') }}</b-link>
    </template>
    <template
      v-slot:cell(hint)="data"
    >
      <id-link-field
        :value="data.value"
        :route-to="{ name: 'PMCustomFieldValues', query: { searchFilter: `hint:${data.value}` }  }"
      />
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import ClientVersionField from "@/entities/parabyg-migration/ClientVersionField";
import IdLinkField from "@/components/entity-fields/IdLinkField";

export default {
  name: 'pm-custom-fields-table-view',
  components: {
    BaseTableView,
    ClientVersionField,
    IdLinkField,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
    linkToListSlots() {
      return [
        {
          slotKey: 'pm_custom_field_selections',
          routeName: 'PMCustomFieldSelections',
        },
        {
          slotKey: 'pm_custom_field_employees',
          routeName: 'PMCustomFieldEmployees',
        },
        {
          slotKey: 'pm_custom_field_values',
          routeName: 'PMCustomFieldValues',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
