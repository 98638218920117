<template>
  <base-table-view
    class="usage-stats-users-table-view"
    selectable
    row-number
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(avatar)="data">
      <avatar :user-data="data.value" />
    </template>
    <template v-slot:cell(user_name)="data">
      <div class="field-title field-title--inline">
        <div
          v-if="data.item.getValue('user_deleted')"
          class="field-title__icon"
        >
          <div class="c-badge c-badge--warning">{{ $vDict('users.status_suspended.text') }}</div>
        </div>

        <b-link
          class="field-title__text"
          :to="getLinkToUser(data.item)"
          @click="entityList.onGoToEntity(data.item.id)"
        >{{ data.value }}</b-link>
      </div>
      <div class="field-title-email">{{ data.item.getValue('user_email') }}</div>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import Avatar from '@/components/Avatar';

export default {
  name: 'usage-stats-users-table-view',
  components: {
    BaseTableView,
    Avatar,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
  methods: {
    getLinkToUser(item) {
      const { clientId } = this.entityList;
      const linkTo = {
        name: 'UsageStatisticsUser',
        params: {
          id: item.getValue('user_id'),
        },
      };

      if (clientId) {
        Object.assign(linkTo, {
          query: {
            client_id: clientId,
          },
        });
      }

      return linkTo;
    },
  },
};
</script>

<style lang="scss" scoped>
.usage-stats-users-table-view {
  &::v-deep .dt-table__td {
    vertical-align: middle;
  }

  .field-title-email {
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
