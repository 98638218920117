import {
  LIST_TYPES,
} from "@/config/enums";

export const getWebhooksQueueListsConfig = () => ({
  [LIST_TYPES.ALL_WEBHOOKS_QUEUE]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
  [LIST_TYPES.USER_WEBHOOKS_QUEUE]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
});
