<template>
  <base-modal
    ref="modal"
    :title="formConfig.modalTitle"
    :ok-title="formConfig.okTitle"
    :is-loading-content="isLoadingExistEntities"
    :is-loading-ok="isSubmitLoading"
    :ok-disabled="$v.formData.$anyError || isCheckingEmail || isLoadingUnique"
    modal-class="create-user-modal"
    title-tag="h2"
    size="lg"
    @shown="onShowModal"
    @hide="onHideModal"
    @hidden="onHiddenModal"
    @ok="submitForm"
  >
    <b-form-group
      v-if="isShowField('user')"
      label="User email"
      class="fl-user-email"
      :state="!$v.formData.email.$anyError"
    >
<!--      <base-input
        v-model="$v.formData.email.$model"
        name="field-email"
        trim
      >
        <template
          v-if="isLoadingUnique"
          v-slot:input-append
        >
          <v-spinner
            size="16"
          />
        </template>
      </base-input>-->

      <b-form-input
        v-model="$v.formData.email.$model"
        :class="{ 'is-invalid': $v.formData.email.$anyError }"
        trim
        name="user-email"
      />

      <b-form-invalid-feedback
        v-if="!isLoadingUnique && !isCheckingEmail"
        v-html="getFormErrorsAsText('formData.email')"
      />

      <transition name="fade">
        <div
          v-if="isUserWillBeCreated && formConfig.key !== 'create_user'"
          class="user-will-created-alert"
        >
          <div class="user-will-created-alert__icon">
            <svg-icon
              name="alert-triangle"
              size="16"
            />
          </div>
          <div class="user-will-created-alert__text">{{ $vDict('users.user_will_be_created.text') }}</div>
        </div>
      </transition>
    </b-form-group>

    <b-form-group
      v-if="isShowField('client')"
      :state="!$v.formData.client_id.$anyError"
      :label="$vDict('users.field_client_id.text')"
    >
      <select-client
        data-cy="select-organization"
        v-model="formData.client_id"
        :input-placeholder="$vDict('users.form_client_input_placeholder.text')"
        :class="{ 'is-invalid': $v.formData.client_id.$anyError }"
        is-show-smart-id
        show-emails
      />

      <b-form-invalid-feedback
        v-html="getFormErrorsAsText('formData.client_id')"
      />
    </b-form-group>

    <template v-if="isShowField('subscription')">
      <loading
        v-if="isLoadingSubscriptions || isLoadingUnique"
        height="100"
      />
      <b-form-group
        v-else
        :label="$vDict('users.field_subscription_name.text')"
        :state="!$v.formData.subscription_id.$anyError"
      >
        <b-form-select
          name="user-subscription"
          :class="{ 'is-invalid': $v.formData.subscription_id.$anyError }"
          v-model="formData.subscription_id"
          :disabled="isLoadingUnique"
        >
          <option :value="null">{{ $vDict('global.form_select_placeholder.text') }}</option>
          <option
            v-for="option in clientSubscriptionsOptions"
            :key="option.id"
            :value="option.id"
          >{{ option.name }}</option>
        </b-form-select>

        <b-form-invalid-feedback
          v-html="getFormErrorsAsText('formData.subscription_id')"
        />
      </b-form-group>
    </template>

    <transition name="fade">
      <b-form-group v-if="isUserWillBeCreated || formConfig.key === 'create_user'">
        <b-form-checkbox
          v-model="formData.send_invitation"
          :value="1"
          :unchecked-value="0"
          class="custom-checkbox--custom-field"
        >
          <div class="custom-control-label__body">{{ $vDict('users.send_invitation_label.text') }}</div>
        </b-form-checkbox>
      </b-form-group>
    </transition>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import MixinModal from "@/mixins/MixinModal";
import SelectClient from "@/components/forms/SelectClient";
import MixinFormValidate from "@/mixins/MixinFormValidate";
import BaseInput from "@/components/forms/BaseInput.vue";
import { email, required } from "vuelidate/lib/validators";
import { Subscription, User, UserProfile } from "@/entities";
import { postUserProfile } from '@/api/repositories/userRepository';

const initialFormData = () => ({
  email: '',
  client_id: null,
  subscription_id: null,
  send_invitation: 0,
});

const initialShowProps = () => ({
  user: {},
  client: {},
  subscription: {},
});

export default {
  name: 'create-user-modal',
  components: {
    BaseModal,
    SelectClient,
    BaseInput,
  },
  mixins: [
    MixinModal,
    MixinFormValidate,
  ],
  data() {
    return {
      formData: initialFormData(),
      showProps: initialShowProps(),
      clientSubscriptions: [],
      isLoadingExistEntities: false,
      isSubmitLoading: false,
      isLoadingSubscriptions: false,
      existUser: null,
      isLoadingUnique: false,
      isCheckingEmail: false,
    };
  },
  computed: {
    formConfig() {
      const config = {
        key: 'add_user',
        okTitle: this.$vDict('users.add_user_form_submit_button.text'),
      };

      if (this.showProps.subscription?.data) {
        Object.assign(config, {
          modalTitle: this.$vDict('users.add_user_to_exist_subscription_title.text', {
            subscriptionName: this.showProps.subscription.getName(),
          }),
          fields: ['user'],
        });
      } else if (this.showProps.client?.data && this.showProps.user?.data) {
        Object.assign(config, {
          modalTitle: this.$vDict('users.add_exist_user_to_subscription_title.text', {
            userName: this.showProps.user.getName(),
          }),
          fields: ['subscription'],
        });
      } else if (this.showProps.client?.data) {
        Object.assign(config, {
          modalTitle: this.$vDict('users.add_user_to_client_title.text', {
            clientName: this.showProps.client?.getName(),
          }),
          fields: ['user', 'subscription'],
        });
      } else if (this.showProps.user?.data) {
        Object.assign(config, {
          modalTitle: this.$vDict('users.add_exist_user_to_subscription_title.text', {
            userName: this.showProps.user.getName(),
          }),
          fields: ['client', 'subscription'],
        });
      } else {
        Object.assign(config, {
          key: 'create_user',
          modalTitle: this.$vDict('users.create_user_form_title.text'),
          okTitle: this.$vDict('users.create_user_form_submit_button.text'),
          fields: ['user', 'client', 'subscription'],
        });
      }

      return config;
    },
    clientSubscriptionsOptions() {
      let subscriptions;

      if (this.existUser) {
        const userClients = this.existUser.clients || [];
        const userSubscriptionsIds = userClients.reduce((acum, userClient) => {
          return [
            ...acum,
            ...(userClient.subscriptions || []),
          ];
        }, []).map(el => el.id);

        subscriptions = this.clientSubscriptions.filter(subscription => !userSubscriptionsIds.includes(subscription.id));
      } else {
        subscriptions = this.clientSubscriptions;
      }

      return subscriptions.map(subscription => ({
        id: subscription.id,
        name: subscription.getName(),
      }));
    },
    clientSubscriptionsOptionsStr() {
      return JSON.stringify(this.clientSubscriptionsOptions);
    },
    formErrors() {
      return {
        formData: {
          email: {
            required: this.$vDict('errors.email_required.text'),
            email: this.$vDict('errors.email_not_valid.text'),
            isUserUnique: this.$vDict('users.user_already_exists.text'),
            isSubscriptionUnique: 'User already exist in this subscription',
          },
          client_id: {
            required: this.$vDict('forms.error_field_is_required.text', {
              fieldName: this.$vDict('users.field_client_id.text'),
            }),
          },
          subscription_id: {
            required: this.$vDict('forms.error_field_is_required.text', {
              fieldName: this.$vDict('users.field_subscription_name.text'),
            }),
          },
        },
      };
    },
    isUserWillBeCreated() {
      return this.isShowField('user') &&
        this.formData.email &&
        !this.existUser &&
        !this.isCheckingEmail &&
        !this.$v.formData.email.$anyError;
    },
  },
  validations() {
    const formData = {};

    if (this.isShowField('user')) {
      Object.assign(formData, {
        email: {
          required,
          email,
        },
      });

      if (this.formConfig.key === 'create_user') {
        Object.assign(formData.email, {
          isUserUnique: () => {
            return !this.existUser;
          },
        });
      } else if (!this.isShowField('subscription') && this.existUser) {
        Object.assign(formData.email, {
          isSubscriptionUnique: async (userEmail) => {
            let isUnique;

            this.isLoadingUnique = true;
            isUnique = await this.isSubscriptionUnique(userEmail);
            this.isLoadingUnique = false;

            return isUnique;
          },
        });
      }
    }

    if (this.isShowField('client')) {
      Object.assign(formData, {
        client_id: {
          required,
        },
      });
    }

    if (this.isShowField('subscription')) {
      Object.assign(formData, {
        subscription_id: {
          required,
        },
      });
    }

    return {
      formData,
    };
  },
  watch: {
    'formData.client_id': {
      async handler(clientId) {
        if (!this.isShowField('subscription') || !clientId) {
          this.clientSubscriptions = [];
          return;
        }

        this.isLoadingSubscriptions = true;
        this.clientSubscriptions = await this.loadClientSubscriptions(clientId);

        setTimeout(() => {
          this.isLoadingSubscriptions = false;
        }, 500);
      },
    },
    'formData.email': {
      async handler(userEmail) {
        if (!this.isShowField('user')) {
          return;
        }

        this.isCheckingEmail = true;
        this.debounceLoadExistUser(userEmail);
      },
    },
    clientSubscriptionsOptionsStr() {
      const option = this.clientSubscriptionsOptions.find(el => el.id === this.formData.subscription_id);

      if (!option) {
        this.formData.subscription_id = null;
      }
    },
  },
  mounted() {
    this.debounceLoadExistUser = _.debounce(async userEmail => {
      if (!this.$v.formData.email.email) {
        this.existUser = null;
      } else {
        this.existUser = await this.loadUserByEmail(userEmail);
      }

      this.isCheckingEmail = false;
    }, 700);
  },
  methods: {
    isShowField(fieldKey) {
      return this.formConfig.fields.includes(fieldKey);
    },
    show(payload = {}) {
      const { client, user, subscription } = payload;

      Object.assign(this.showProps, {
        client,
        user,
        subscription
      });

      Object.assign(this.formData, {
        email: user?.getValue('email') || null,
        client_id: client?.id || subscription?.client_id || null,
        subscription_id: subscription?.id || null,
      });

      this.loadExistEntities();
      this.$refs.modal.show();
    },
    async loadExistEntities() {
      this.isLoadingExistEntities = true;

      if (!this.isShowField('user')) {
        this.existUser = await this.loadUserByEmail(this.formData.email);
      }

      this.isLoadingExistEntities = false;
    },
    onHiddenModal() {
      this.formData = initialFormData();
      this.showProps = initialShowProps();
      this.existUser = null;
      this.$v.$reset();
    },
    async loadClientSubscriptions(clientId) {
      let subscriptions = [];

      try {
        const response = await Subscription.loadAllItemsFunc({
          filter: {
            client_id: {
              in: [clientId],
            },
            // end_date: {
            //   gte: DateTime.now().toISO(),
            // },
          },
          sort: {
            id: { order: 'd' },
          },
          smart_selections: 0,
        });
        subscriptions = (response?.data || []).map(subscriptionData => new Subscription(subscriptionData));
      } catch (e) {}

      return subscriptions.filter(subscription => !subscription.isExpired());
    },
    async loadUserByEmail(userEmail = '') {
      let response;

      try {
        response = await User.loadAllItemsFunc({
          offset: 0,
          filter: {
            email: {
              eq: userEmail,
            },
          },
        });
      } catch (e) {}

      return _.get(response, 'data[0]');
    },
    async isSubscriptionUnique() {
      const query = {
        filter: {
          'user.id': {
            in: [this.existUser.id],
          },
          subscription_id: {
            in: [this.formData.subscription_id],
          },
        },
        limit: 1,
        smart_selections: 0,
      };
      let isUnique = false;

      try {
        const response = await UserProfile.loadAllItemsFunc(query);
        isUnique = !(response?.data || []).length;
      } catch (e) {}

      return isUnique;
    },
    async submitForm(e) {
      e.preventDefault();

      const isValid = await this.validateForm({ toastErrors: false });

      if (!isValid) {
        return;
      }

      this.isSubmitLoading = true;

      try {
        const response = await postUserProfile({
          subscription_id: this.formData.subscription_id,
          source_id: null,
          is_suspended: false,
          flattened_data: {},
          user: {
            email: this.formData.email,
            name: "",
            phone: "",
            pic: "",
            is_suspended: false,
          },
          smart_settings: {
            send_invite_if_new: this.formData.send_invitation,
            modules: [],
            fixed_modules: [],
          },
        });

        if (response) {
          const userData = response?.data?.user || {};

          this.hideModal();
          this.$notifToastr('success', this.$vDict('users.user_added_successfully.text'));
          this.onSubmitSuccess(userData);
        }
      } catch (e) {
        this.$notifUnexpectedError();
      }

      this.isSubmitLoading = false;
    },
    onSubmitSuccess(userData = {}) {
      if (this.formConfig.key !== 'create_user' || !userData?.id) {
        this.$emit('user-added', {
          userId: userData.id,
          clientId: this.formData.client_id,
          subscriptionId: this.formData.subscription_id,
        });
        return;
      }

      const user = new User(userData);
      user.goToView();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-will-created-alert {
  margin-top: 12px;
  padding: 8px;
  border: 1px solid var(--secondary);
  background-color: var(--active-bg);
  display: flex;
  border-radius: var(--border-radius);

  &__icon {
    margin-right: 8px;
    color: var(--secondary);
    height: 20px;
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss">
.create-user-modal .modal-dialog {
  .modal-body {
    padding: 0 90px;

    .form-group {
      &:nth-child(n+2) {
        margin-top: 40px;
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
}
</style>
