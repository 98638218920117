<template>
  <div class="client-user-actions">
    <login-as-user
      ref="loginAsUser"
    />

    <user-actions
      ref="userActions"
    />

    <create-user-modal
      ref="createUserModal"
      @user-added="onActionDone"
    />
  </div>
</template>

<script>
import LoginAsUser from "@/entities/client-user/views/LoginAsUser";
import UserActions from "@/entities/user/views/UserActions.vue";
import CreateUserModal from "@/entities/user/views/CreateUserModal";

export default {
  name: 'client-user-actions',
  components: {
    LoginAsUser,
    UserActions,
    CreateUserModal
  },
  data() {
    return {
      actionInfo: null,
    };
  },
  methods: {
    async actionHandler(clientUser, action) {
      const { user } = clientUser;

      if (action.type === 'user') {
        await this.$refs.userActions.actionHandler(user, action);
        return;
      }

      this.actionInfo = {
        entity: clientUser,
        action,
      };

      switch (action.key) {
        case 'login_as':
          await this.$refs.loginAsUser.login({
            id: user.id,
            name: user.getValue('name'),
            email: user.getValue('email'),
          });
          break;
        case 'add_subscription':
        case 'add_to_organization':
          const { user, client } = clientUser;
          await this.$refs.createUserModal.show({ user, client });
          break;
        default:
          await clientUser.doAction(action);
      }
    },
    onActionDone() {
      this.$emit('done', this.actionInfo);
      this.actionInfo = null;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
