/*
  License class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import {
  getLicenses,
  getLicense,
  patchLicense,
} from "@/api/repositories/licensesRepository";
import { ENTITY_TYPES } from '@/config/enums';
import { Module } from "@/entities";

export class License extends BaseEntity {
  static entityKey = 'license';
  static datasetKey = 'licenses';
  static entityTypeId = ENTITY_TYPES.LICENSE;
  static routes = {
    edit: 'SystemLicenseEditor',
  };

  // api
  static loadAllItemsFunc = getLicenses;
  static loadItemFunc = getLicense;
  static patchItemFunc = patchLicense;
  static getEntityPluralTypeText() {
    return 'Licenses';
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = License;
  }

  getModules(dataset) {
    const res = [];

    _.uniq(this.data.modules).forEach(moduleId => {
      const moduleData = this.getDatasetItem(dataset, 'modules', moduleId);

      if (moduleData) {
        res.push(new Module(moduleData));
      }
    });

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'modules':
        res = this.getModules(dataset);
        break;
      default:
    }

    return res;
  }

  getFieldValue(fieldKey) {
    let res;

    switch (fieldKey) {
      case 'name':
        res = this.getLicenseName();
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
    ];
  }

  getLicenseName() {
    const license = Vue.prototype.$lFind('client.licenses', { id: this.data.id });
    return license ? license.name : '';
  }
}
