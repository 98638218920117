/*
  CompaniesList class
 */

import { BaseCompaniesList } from "@/entities/company/BaseCompaniesList";
import { UserCompany } from "@/entities/company/UserCompany";

export class UserCompaniesList extends BaseCompaniesList {
  static entityClass = UserCompany;
  static isUserSection = true;

  constructor(listTypeId) {
    super(listTypeId);

    this.selfClass = UserCompaniesList;
    this.statusId = 'all';
  }
}
