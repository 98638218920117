<template>
  <base-table-view
    class="myran-subscription-versions-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(link_user_profiles)="data">
      <b-link
        class="text-secondary"
        :to="{ name: 'UserProfiles', query: { searchFilter: `subscription_version_id:[${data.value}]` } }"
      >Show list</b-link>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'myran-subscription-versions-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
