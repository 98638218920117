<template>
  <div class="client-users-table-view">
    <base-table-view
      :striped="false"
      selectable
      :items="tableRows"
      :fields="tableFields"
      :sort="sort"
      :entity-list="entityList"
      :fixed-required-columns="fixedRequiredColumns"
      @change-sort="changeSort"
    >
      <template v-slot:row="{ item }">
        <td
          v-for="field in getRowFields(item)"
          class="dt-table__td"
          :rowspan="!field.subscription ? item._rowspan : 1"
          v-bind="getTdParams(item, field)"
          :key="field.key"
        >
          <template v-if="field.key === 'user_id'">{{ item.clientUserData.id }}</template>
          <template v-else-if="field.key === 'name'">
            <field-user
              :user="item.clientUser.user"
              show-email
            />
          </template>
          <template v-else-if="field.key === 'license_id'">{{ item.clientUserData.licenseName }}</template>
          <template v-else-if="field.key === 'account_status'">
            <user-account-status
              v-if="item.clientUserData.accountStatus"
              :variant="item.clientUserData.accountStatus.variant"
              :name="item.clientUserData.accountStatus.name"
            />
          </template>
          <template v-else-if="field.key === 'client_user_status' && item.clientUserData">
            <client-user-status
              v-if="item.clientUserData.onboardingStatus"
              :variant="item.clientUserData.onboardingStatus.variant"
              :name="item.clientUserData.onboardingStatus.name"
            />
          </template>
          <template v-else-if="field.key === 'subscription_name' && item.subscriptionData">
            <subscription-name
              :subscription="item.subscription"
            />
          </template>
          <template v-else-if="field.key === 'subscription_end_date' && item.subscriptionData">
            {{ item.subscriptionData.endDate }}
          </template>
          <template v-else-if="field.key === 'actions'">
            <actions-field
              :possible-actions="possibleActions"
              :actions="item.clientUser.getActions(true)"
              :is-loading="isLoadingEntityId(item.clientUser.id)"
              @action="entityActionHandler(item.clientUser, $event)"
            />
          </template>
        </td>
      </template>
    </base-table-view>

    <client-user-actions
      ref="entityActions"
      @done="onActionDone"
    />
  </div>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import ActionsField from "@/components/table/fields/ActionsField.vue";
import FieldUser from "@/components/entity-fields/FieldUser.vue";
import ClientUserActions from "@/entities/client-user/views/ClientUserActions.vue";
import UserAccountStatus from "@/entities/user/views/UserAccountStatus.vue";
import ClientUserStatus from "@/entities/client-user/views/ClientUserStatus.vue";
import SubscriptionName from "@/entities/subscriptions/views/SubscriptionName.vue";

export default {
  name: 'client-users-table-view',
  components: {
    BaseTableView,
    ActionsField,
    FieldUser,
    ClientUserActions,
    UserAccountStatus,
    ClientUserStatus,
    SubscriptionName,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
    tableFields() {
      return this.fields.map(field => ({
        ...field,
        class: [field.class, { 'subrow-column': field.subscription }],
      }));
    },
    tableRows() {
      let rows = [];

      this.items.forEach((clientUser, userIndex) => {
        const { subscriptions, user, client } = clientUser;
        const hasActiveSubscription = user.hasActiveSubscription(client.id);
        const userNumber = userIndex + 1;
        const clientUserLicense = clientUser.getLicense();
        const userOnboardingStatus = clientUser.getUserOnboardingStatus();
        const userRows = [{
          id: clientUser.id,
          clientUser,
          clientUserData: {
            id: user.getValue('id'),
            name: user.getName(),
            email: user.getValue('email'),
            sourceId: user.getValue('source_id'),
            linkTo: user.getLinkTo(),
            licenseName: clientUserLicense?.name || '',
            accountStatus: user.getAccountStatus(client.id) || {},
            onboardingStatus: userOnboardingStatus,
          },
          _rowspan: subscriptions.length || 1,
        }];

        subscriptions.forEach((subscription, subscriptionIndex) => {
          const rowData = {
            id: _.compact([
              clientUser.id,
              subscription.id,
            ]).join('_'),
            subscriptionData: {
              id: subscription.getValue('id'),
              name: subscription.getName(),
              linkTo: subscription.getLinkTo(),
              endDate: subscription.getFieldValue('end_date'),
              isActiveOnUser: subscription.isActiveOnUser(),
            },
            subscription,
            _tdClass: (subscriptionIndex + 1) % 2 === 1 ? 'odd-row' : 'even-row',
          };

          if (subscriptionIndex === 0) {
            Object.assign(userRows[0], rowData);
          } else {
            userRows.push(rowData);
          }
        });

        rows = rows.concat(userRows.map(rowData => ({
          ...rowData,
          hasActiveSubscription,
          _rowClass: userNumber % 2 === 1 ? 'odd-row' : 'even-row',
        })));
      });

      return rows;
    },
  },
  methods: {
    getRowFields(item) {
      return item.clientUserData ?
        this.tableFields :
        this.tableFields.filter(field => field.subscription);
    },
    getTdParams(item, field) {
      const tdClasses = [item._tdClass];

      const isUserInactive = !item.hasActiveSubscription && !['user_id', 'name'].includes(field.key);
      const isSubscriptionInactive = field.subscription && !item.subscriptionData?.isActiveOnUser;

      if (isUserInactive || isSubscriptionInactive) {
        tdClasses.push('dt-table__td--muted');
      }

      return {
        class: [
          field.class,
          ...tdClasses,
        ],
      };
    },
    async onActionDone(payload = {}) {
      const { action, entity } = payload;

      switch (action?.key) {
        case 'add_to_organization':
          await this.entityList.reloadEntity(entity, {
            filter: {
              id: {
                in: [entity.user.id]
              }
            }
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.client-users-table-view::v-deep {
  .dt-table__th,
  .dt-table__td {
    border-bottom: 1px solid var(--line-color-main);
  }

  .dt-table__tr.odd-row {
    background-color: var(--entity-list-table-odd-row-bg);
  }

  .subrow-column.even-row {
    background-color: var(--entity-list-table-subrows-even-row-bg);
  }
}
</style>
