import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'pm_client_version_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
  },
  {
    key: 'filename',
    label: Vue.prototype.$vDict('parabyg_migration.field_filename.text'),
  },
  {
    key: 'source_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_source_id.text'),
  },
  {
    key: 'responsible',
    label: Vue.prototype.$vDict('parabyg_migration.field_responsible.text'),
    details: true,
  },
  {
    key: 'responsible_ref',
    label: Vue.prototype.$vDict('parabyg_migration.field_responsible_ref.text'),
    details: true,
  },
  {
    key: 'rsm_project_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_rsm_project_id.text'),
    details: true,
  },
  {
    key: 'rsm_company_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_rsm_company_id.text'),
    details: true,
  },
  {
    key: 'rsm_contact_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_rsm_contact_id.text'),
    details: true,
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_created_at.text'),
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_modified_at.text'),
  },
  {
    key: 'url',
    label: Vue.prototype.$vDict('parabyg_migration.field_url.text'),
    details: true,
  },
  {
    key: 'is_valid',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
  },
  {
    key: 'validation_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_validation_error_message.text'),
    details: true,
  }
];
