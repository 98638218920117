/*
  VortalCompaniesList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import VortalCompaniesTableView from "@/entities/vortal-company/views/VortalCompaniesTableView";
import Vue from "vue";
import { getVortalCompaniesListsConfig } from "@/entities/vortal-company/config";
import { getCompanies } from "@/api/repositories/vortalConnectRepository";
import { requiredFields } from "@/entities/vortal-company/fields";
import { VortalCompany } from "@/entities";

export class VortalCompaniesList extends EntityList {
  static entityClass = VortalCompany;

  constructor(listTypeId) {
    super(listTypeId, getVortalCompaniesListsConfig);

    this.tableView = VortalCompaniesTableView;

    this.selfClass = VortalCompaniesList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'id',
        dataCy: 'company-id',
        name: Vue.prototype.$vDict('vortal_connect_companies.filter_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_companies.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'vortal_company_code',
        dataCy: 'vortal-company-code',
        name: Vue.prototype.$vDict('vortal_connect_companies.filter_vortal_company_code_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_companies.filter_vortal_company_code_input_placeholder.text'),
        },
      },
      {
        key: 'country_id',
        dataCy: 'country',
        name: Vue.prototype.$vDict('vortal_connect_companies.filter_country_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
      {
        key: 'vortal_company_identifier_type',
        dataCy: 'identifier-type',
        name: Vue.prototype.$vDict('vortal_connect_companies.filter_vortal_company_identifier_type_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('VORTAL_COMPANY_IDENTIFIER_TYPES'),
        },
      },
      {
        key: 'company_identifier',
        dataCy: 'identifier',
        name: Vue.prototype.$vDict('vortal_connect_companies.filter_company_identifier_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_companies.filter_company_identifier_input_placeholder.text'),
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('vortal_connect_companies.filter_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'modified_at',
        dataCy: 'modified-at',
        name: Vue.prototype.$vDict('vortal_connect_companies.filter_modified_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
    ];
  }
}
