<template>
  <div class="user-transfer-task-actions">
    <user-transfer-task-stages-modal
      ref="stagesModal"
      :dataset="dataset"
    />
  </div>
</template>

<script>
import UserTransferTaskStagesModal
  from "@/entities/user-transfer-task/modals/UserTransferTaskStagesModal.vue";

export default {
  name: 'user-transfer-task-actions',
  props: {
    dataset: { type: Object, default: Object },
  },
  components: {
    UserTransferTaskStagesModal,
  },
  methods: {
    async actionHandler(entity, action) {
      switch (action.key) {
        case 'view_stages':
          this.$refs.stagesModal.showStagesModal(entity);
          break;
        default:
          await entity.doAction(action.key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
