/*
  UserContactsList class
 */

import { BaseContactsList } from "@/entities/contact/BaseContactsList";
import { UserContact } from "@/entities/contact/UserContact";

export class UserContactsList extends BaseContactsList {
  static entityClass = UserContact;
  static isUserSection = true;

  constructor(listTypeId) {
    super(listTypeId);

    this.selfClass = UserContactsList;
    this.permanentQueryData = {
      filter: {
        'users.user_suspended': {
          eq: 0,
        },
      },
      context: 'client',
    };
  }
}
