import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('saved_search_notifs.field_id.text'),
    sortable: true,
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('saved_search_notifs.field_name.text'),
    sortable: true,
  },
  {
    key: 'access_code',
    label: Vue.prototype.$vDict('saved_search_notifs.field_access_code.text'),
    class: 'column-access-code',
  },
  {
    key: 'client_id',
    label: Vue.prototype.$vDict('saved_search_notifs.field_client_id.text'),
  },
  {
    key: 'user_id',
    label: Vue.prototype.$vDict('saved_search_notifs.field_user_id.text'),
  },
  {
    key: 'list_type_id',
    label: Vue.prototype.$vDict('saved_search_notifs.field_list_type_id.text'),
  },
  {
    key: 'entity_number',
    label: Vue.prototype.$vDict('saved_search_notifs.field_entity_number.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('saved_search_notifs.field_created_at.text'),
    sortable: true,
  },
];
