<template>
  <entity-status-tag
    class="user-account-status"
    :icon="statusParams.icon"
    :name="statusParams.name"
    :variant="statusParams.variant"
    :class="{ 'user-account-status--muted': isMuted }"
  />
</template>

<script>
import EntityStatusTag from "@/components/entity/EntityStatusTag.vue";

export default {
  name: 'user-account-status',
  components: {
    EntityStatusTag,
  },
  props: {
    variant: { type: String, default: '' },
    name: { type: String, default: '' },
    isMuted: { type: Boolean, default: false },
  },
  computed: {
    statusParams() {
      const params = {
        icon: '',
        name: this.name || '',
      };

      switch (this.variant) {
        case 'registered':
          Object.assign(params, {
            icon: 'checked',
            variant: 'active',
          });
          break;
        case 'suspended':
          Object.assign(params, {
            icon: 'deactivated',
            variant: 'deactivated',
          });
          break;
        default:
      }

      return params;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-account-status {
  .dt-table__td--muted &,
  &--muted {
    &.entity-status-tag--active {
      --bg-color: var(--entity-status-tag-muted-bg);
      --text-color: var(--entity-status-tag-muted-color);
    }
  }
}
</style>
