/*
  CustomFieldMetricsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import CustomFieldMetricsTableView from "@/entities/custom-field-metric/views/CustomFieldMetricsTableView";
import Vue from "vue";
import { getCustomFieldMetricsListsConfig } from "@/entities/custom-field-metric/config";
import { getCustomFieldMetrics, deleteCustomFieldMetric } from "@/api/repositories/customFieldsRepository";
import { requiredFields } from "@/entities/custom-field-metric/fields";
import { CustomFieldMetric } from "@/entities";

export class CustomFieldMetricsList extends EntityList {
  static entityClass = CustomFieldMetric;

  constructor(listTypeId) {
    super(listTypeId, getCustomFieldMetricsListsConfig);

    this.tableView = CustomFieldMetricsTableView;

    this.selfClass = CustomFieldMetricsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'key',
        dataCy: 'key',
        name: Vue.prototype.$vDict('custom_field_metrics.filter_key.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('custom_field_metrics.filter_key_placeholder.text'),
        },
      },
    ];
  }
}
