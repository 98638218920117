import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('email_sender_logs.field_id.text'),
    sortable: true,
  },
  {
    key: 'subject',
    label: Vue.prototype.$vDict('email_sender_logs.field_subject.text'),
  },
  {
    key: 'provider_msg_id',
    label: Vue.prototype.$vDict('email_sender_logs.field_provider_msg_id.text'),
  },
  {
    key: 'message_id',
    label: Vue.prototype.$vDict('email_sender_logs.field_message_id.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('email_sender_logs.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'failure_reason',
    label: Vue.prototype.$vDict('email_sender_logs.field_failure_reason.text'),
    details: true,
  },
  {
    key: 'sent',
    label: Vue.prototype.$vDict('email_sender_logs.field_sent.text'),
  },
  {
    key: 'user_id',
    label: Vue.prototype.$vDict('email_sender_logs.field_user_id.text'),
  },
  {
    key: 'email',
    label: Vue.prototype.$vDict('email_sender_logs.field_email.text'),
  },
];
