import enums from "@/config/enums";
import store from "@/store";

export const getTendersListsConfig = () => ({
  [enums.LIST_TYPES.ALL_TENDERS]: {
    isMain: true,
    isSavePosition: true,
    showFieldsSettings: true,
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('excel_export'),
      print: store.getters['Account/hasComponent']('print'),
    },
    defaultSort: {
      default: {
        latest_update_at: { order: 'd' },
      },
    },
    defaultSearchFilter: {
      default: {
        archived: 0,
      },
    },
    customDefaultFieldset: true,
    showSsn: true,
    alternativeListTypeId: enums.LIST_TYPES.USER_TENDERS,
    hasBulkOperations: true,
    bulkOperations() {
      return [
        {
          key: 'select_responsible',
        },
        {
          key: 'print',
        },
        {
          key: 'excel',
        },
      ];
    },
    hasAccess: () => store.getters['Account/hasComponent']('all_tenders'),
  },
  [enums.LIST_TYPES.USER_TENDERS]: {
    isMain: true,
    isSavePosition: true,
    showFieldsSettings: true,
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('excel_export'),
      print: store.getters['Account/hasComponent']('print'),
    },
    defaultSort: {
      byStatus: {
        [enums.USER_TENDER_STATUSES.NEW]: {
          delivered_at: { order: 'd' },
        },
      },
      default: {
        latest_update_at: { order: 'd' },
      },
    },
    // defaultListFilter: {
    //   byStatus: {
    //     [enums.USER_TENDER_STATUSES.NEW]: {
    //       delivered_at: {
    //         gteOffsetWeeks: 'w-4',
    //         lteOffsetWeeks: 'today',
    //       },
    //     },
    //   },
    // },
    defaultSearchFilter: {
      default: {
        archived: 0,
        'users.user_id': [
          store.getters['Account/userId'],
        ],
      },
    },
    // affectTotalFilterKeys: {
    //   default: ['archived'],
    // },
    hasLockedItems: true,
    customDefaultFieldset: true,
    actions: {
      clearFolder: {
        byStatus: {
          [enums.USER_TENDER_STATUSES.NEW]: true,
        },
        default: false,
      },
    },
    alternativeListTypeId: enums.LIST_TYPES.ALL_TENDERS,
    couldHasCustomFields: store.getters['Account/hasComponent']('tender_custom_fields'),
    hasBulkOperations: true,
    hasSavedByFilter: true,
    bulkOperations() {
      return [
        {
          key: 'select_responsible',
        },
        {
          key: 'print',
        },
        {
          key: 'excel',
        },
        {
          key: 'delete',
        },
      ];
    },
    hasAccess: () => store.getters['Account/hasComponent']('user_tenders'),
  },
  [enums.LIST_TYPES.PROJECT_TENDERS]: {
    defaultSort: {
      default: {
        latest_update_at: { order: 'd' },
      },
    },
    customDefaultFieldset: true,
    hasAccess: () => store.getters['Account/hasComponent']('project_tender_tab'),
  },
  [enums.LIST_TYPES.TENDER_TENDERS]: {
    defaultSort: {
      default: {
        latest_update_at: { order: 'd' },
      },
    },
    customDefaultFieldset: true,
    hasAccess: () => store.getters['Account/hasComponent']('project_tender_tab'),
  },
  [enums.LIST_TYPES.COMPANY_TENDERS]: {
    defaultSort: {
      default: {
        name: { order: 'a' },
      },
    },
    customDefaultFieldset: true,
    hasAccess: () => store.getters['Account/hasComponent']('company_tender_tab'),
  },
  [enums.LIST_TYPES.CONTACT_TENDERS]: {
    defaultSort: {
      default: {
        name: { order: 'a' },
      },
    },
    customDefaultFieldset: true,
    hasAccess: () => store.getters['Account/hasComponent']('contact_tender_tab'),
  },
});
