import Vue from 'vue';
import { getUserVersions } from "@/api/repositories/myranRepository";

export const requiredFields = () =>  [
  {
    key: 'id',
    label: 'id',
    class: 'column-id',
    sortable: true,
    fieldset: ['id'],
  },
  {
    key: 'client_version_id',
    label: 'Client version id',
    details: true,
    fieldset: ['client_version_id'],
  },
  {
    key: 'email',
    label: 'Email',
    main: true,
    fieldset: ['email'],
  },
  {
    key: 'name',
    label: 'Name',
    fieldset: ['name'],
  },
  {
    key: 'phone',
    label: 'Phone',
    details: true,
    fieldset: ['phone'],
  },
  {
    key: 'license_id',
    label: 'License',
    details: true,
    fieldset: ['license_id'],
  },
  {
    key: 'language_id',
    label: 'Language',
    details: true,
    fieldset: ['language_id'],
  },
  {
    key: 'currency_id',
    label: 'Currency',
    details: true,
    fieldset: ['currency_id'],
  },
  {
    key: 'status',
    label: 'Status',
    details: true,
    fieldset: ['status'],
  },
  {
    key: 'updates_notif_mode',
    label: 'Update notif mode',
    details: true,
    fieldset: ['updates_notif_mode'],
  },
  {
    key: 'updates_notif_gateways',
    label: 'Updates notif gateways',
    details: true,
    fieldset: ['updates_notif_gateways'],
  },
  {
    key: 'timezone',
    label: 'Timezone',
    details: true,
    fieldset: ['timezone'],
  },
  {
    key: 'source_id',
    label: 'Source id',
    details: true,
    fieldset: ['source_id'],
  },
  {
    key: 'project_triggers',
    label: 'Project triggers',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
    fieldset: [],
    asyncData: (item) => ({
      requestMethod: getUserVersions,
      requestQuery: {
        fields: ['project_triggers'],
        filter: {
          id: { in: [item.id] },
        },
      },
      responseMethod: (response) => _.get(response, 'data[0].project_triggers') || {},
    }),
  },
  {
    key: 'tender_triggers',
    label: 'Tender triggers',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
    fieldset: [],
    asyncData: (item) => ({
      requestMethod: getUserVersions,
      requestQuery: {
        fields: ['tender_triggers'],
        filter: {
          id: { in: [item.id] },
        },
      },
      responseMethod: (response) => _.get(response, 'data[0].tender_triggers') || {},
    }),
  },
  {
    key: 'user_id',
    label: 'User id',
    fieldset: ['user_id'],
  },
  {
    key: 'user_import_status',
    label: 'User import status',
    fieldset: ['user_import_status'],
  },
  {
    key: 'created_at',
    label: 'Created at',
    fieldset: ['created_at'],
  },
];
