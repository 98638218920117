/*
  PMClientsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  SEARCHABLE_LIST_TYPES,
} from "@/config/enums";
import ClientsTableView from "@/entities/parabyg-migration/pm-client/views/ClientsTableView";
import { getPMClientListsConfig} from "@/entities/parabyg-migration/pm-client/config";
import { getPMClients } from "@/api/repositories/parabygMigrationRepository";
import { requiredFields } from "@/entities/parabyg-migration/pm-client/fields";
import { Client } from "@/entities";
import Vue from "vue";
import { PMClient } from "@/entities";

export class PMClientsList extends EntityList {
  static entityClass = PMClient;

  constructor(listTypeId) {
    super(listTypeId, getPMClientListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = ClientsTableView;
    this.settingsForLoadingDatasets = [
      {
        datasetKey: Client.datasetKey,
        loadFunc: Client.loadAllItemsFunc,
        fieldKeys: ['client_id'],
      },
    ];

    this.selfClass = PMClientsList;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('entity_list.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'hint',
        dataCy: 'hint',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_client_hint.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_pm_client_hint_input_placeholder.text'),
        },
      },
      {
        key: 'client_id',
        dataCy: 'client',
        name: Vue.prototype.$vDict('parabyg_migration.field_client_id.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          type: SEARCHABLE_LIST_TYPES.CLIENTS,
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_client_input_placeholder.text'),
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('parabyg_migration.field_created_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
    ];
  }
}
