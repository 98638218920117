/*
  MyranUserProfilesList class
 */

import Vue from "vue";
import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  LIST_TYPES,
} from "@/config/enums";
import MyranUserProfilesTableView from "@/entities/myran-user-profile/views/MyranUserProfilesTableView";
import { getMyranUserProfilesListsConfig } from "@/entities/myran-user-profile/config";
import { requiredFields } from "@/entities/myran-user-profile/fields";
import { getUserProfiles } from '@/api/repositories/myranRepository';
import { MyranUserProfile } from "@/entities";

export class MyranUserProfilesList extends EntityList {
  static entityClass = MyranUserProfile;

  constructor(listTypeId) {
    super(listTypeId, getMyranUserProfilesListsConfig);

    this.tableView = MyranUserProfilesTableView;

    this.selfClass = MyranUserProfilesList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'keywords',
        dataCy: 'keywords',
        name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_keywords_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('myran_logs_user_profiles.filter_keywords_input_placeholder.text'),
        },
      },
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('myran_logs_user_profiles.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'subscription_version_id',
        dataCy: 'subscription-version',
        name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_subscription_version_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('myran_logs_user_profiles.filter_subscription_version_id_input_placeholder.text'),
        },
      },
      {
        key: 'source_id',
        dataCy: 'source',
        name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_source_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('myran_logs_user_profiles.filter_source_id_input_placeholder.text'),
        },
        listTypeIds: [LIST_TYPES.MYRAN_LOGS_USER_PROFILES],
      },
      {
        key: 'source_created_at',
        dataCy: 'source-created-at',
        name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_source_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'source_modified_at',
        dataCy: 'source-modified-at',
        name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_source_modified_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'valid',
        dataCy: 'valid',
        name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_valid_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [
            { id: 1, name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_valid_option_valid.text'), },
            { id: 0, name: Vue.prototype.$vDict('myran_logs_user_profiles.filter_valid_option_not_valid.text'), },
          ],
        },
      },
    ];
  }
}
