/*
  MyranUserVersionsList class
 */

import Vue from "vue";
import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  SEARCHABLE_LIST_TYPES,
} from "@/config/enums";
import MyranUserVersionsTableView from "@/entities/myran-user-version/views/MyranUserVersionsTableView";
import { getMyranUserVersionsListsConfig } from "@/entities/myran-user-version/config";
import { requiredFields } from "@/entities/myran-user-version/fields";
import { getUsers } from "@/api/repositories/userRepository";
import { MyranUserVersion } from "@/entities";

export class MyranUserVersionsList extends EntityList {
  static entityClass = MyranUserVersion;

  constructor(listTypeId) {
    super(listTypeId, getMyranUserVersionsListsConfig);

    this.tableView = MyranUserVersionsTableView;
    this.settingsForLoadingDatasets = [
      {
        datasetKey: 'users',
        fieldKeys: ['user_id'],
        loadFunc: getUsers,
      },
    ];

    this.selfClass = MyranUserVersionsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'keywords',
        dataCy: 'keywords',
        name: 'Keywords',
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: 'Type keywords',
          // searchFunc: getUserVersions,
        },
      },
      {
        key: 'id',
        dataCy: 'id',
        name: 'Id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type id',
        },
      },
      {
        key: 'client_version_id',
        dataCy: 'client-version',
        name: 'Client version id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type client version id',
        },
      },
      {
        key: 'user_id',
        dataCy: 'user',
        name: 'User',
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          placeholder: 'Type user name',
          type: SEARCHABLE_LIST_TYPES.USERS,
        },
      },
      {
        key: 'license_id',
        dataCy: 'license',
        name: 'License',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('client.licenses'),
        },
      },
      {
        key: 'language_id',
        dataCy: 'language',
        name: 'Language',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.languages'),
        },
      },
      {
        key: 'currency_id',
        dataCy: 'currency',
        name: 'Currency',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.currencies'),
        },
      },
      {
        key: 'source_id',
        dataCy: 'source',
        name: 'Source Id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type source id',
        },
      },
      {
        key: 'user_import_status',
        dataCy: 'user-import-status',
        name: 'User import status',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('USER_IMPORT_STATUSES'),
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: 'Created at',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
    ];
  }
}
