import enums from "@/config/enums";
import store from '@/store';

export const getDeadMessageSubscriptionsListsConfig = () => ({
  [enums.LIST_TYPES.DEAD_MESSAGE_SUBSCRIPTIONS]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
});
