/*
  WebhooksList class
 */

import { UserWebhook } from "@/entities/webhook/UserWebhook";
import { WebhooksList } from "@/entities";

export class UserWebhooksList extends WebhooksList {
  static entityClass = UserWebhook;

  constructor(listTypeId) {
    super(listTypeId);

    this.selfClass = UserWebhooksList;
  }
}
