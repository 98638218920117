import enums from "@/config/enums";

export const getSubscriptionsListsConfig = () => ({
  [enums.LIST_TYPES.SUBSCRIPTIONS]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
    // defaultSearchFilter: {
    //   default: {
    //     end_date: {
    //       type: 'gte_now',
    //     },
    //   },
    // },
  },
  [enums.LIST_TYPES.CLIENT_SUBSCRIPTIONS]: {
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
    // defaultSearchFilter: {
    //   default: {
    //     end_date: {
    //       type: 'gte_now',
    //     },
    //   },
    // },
  },
});
