/*
  ErrorMessage class
 */

import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getErrorMessages } from "@/api/repositories/dictionariesRepository";
import { formatDateTime } from "@/shared/utils";
import Vue from "vue";

export class ErrorMessage extends BaseEntity {
  static entityKey = 'error_message';
  static datasetKey = 'errorMessages';
  static entityTypeId = ENTITY_TYPES.ERROR_MESSAGE;
  static routes = {
    edit: 'SystemErrorMessagesEditor',
  };

  static loadAllItemsFunc = getErrorMessages;
  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('error_messages.error_messages_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = ErrorMessage;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
      case 'datetime':
        res = fieldValue ? formatDateTime(fieldValue, {
          toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME,
          userZone: true
        }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }
}
