/*
  LicensesList class
 */

import Vue from "vue";
import EntityList from "@/shared/classes/list/EntityList";
import CountryModulesTableView from "./views/CountryModulesTableView.vue";
import { getCountryModulesListsConfig } from './config';
import { requiredFields } from "./fields";
import { CountryModules } from "./CountryModules";
import { Module } from "../module/Module";
import { SEARCH_FILTER_TYPES } from "@/config/enums";

export class CountryModulesList extends EntityList {
  static entityClass = CountryModules;

  constructor(listTypeId) {
    super(listTypeId, getCountryModulesListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = CountryModulesTableView;
    this.settingsForLoadingDatasets = [
      {
        datasetKey: Module.datasetKey,
        loadFunc: Module.loadAllItemsFunc,
        fieldKeys: ['modules'],
      },
    ];

    this.selfClass = CountryModulesList;
  }

  getListFiltersSetting(dataset = {}) {
    return [
      {
        key: 'country_id',
        dataCy: 'country',
        name: Vue.prototype.$vDict('country_modules.field_country_id.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
    ];
  }
}
