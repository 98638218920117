<template>
  <div class="email-log-actions">
    <email-log-events-modal
      ref="emailLogEventsModal"
      :dataset="dataset"
    />
  </div>
</template>

<script>
import EmailLogEventsModal from "@/entities/email-log/modals/EmailLogEventsModal.vue";

export default {
  name: 'email-log-actions',
  props: {
    dataset: { type: Object, default: Object },
  },
  components: {
    EmailLogEventsModal,
  },
  methods: {
    async actionHandler(emailLog, action) {
      switch (action.key) {
        case 'view_events':
          await this.$refs.emailLogEventsModal.showLogEvents(emailLog);
          break;
        default:
          await emailLog.doAction(action.key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
