import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('dead_message_subscriptions.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'email',
    label: Vue.prototype.$vDict('dead_message_subscriptions.field_email.text'),
  },
  {
    key: 'service_name',
    label: Vue.prototype.$vDict('dead_message_subscriptions.field_service_name.text'),
    class: 'column-service-name',
    sortable: true,
  },
  {
    key: 'topic',
    label: Vue.prototype.$vDict('dead_message_subscriptions.field_topic.text'),
    sortable: true,
  },
];
