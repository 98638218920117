/*
  VortalLogsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import VortalLogsTableView from "@/entities/vortal-log/views/VortalLogsTableView";
import Vue from "vue";
import { getVortalLogsListsConfig } from "@/entities/vortal-log/config";
import { getCompanies, getContacts, getLogs } from "@/api/repositories/vortalConnectRepository";
import { requiredFields } from "@/entities/vortal-log/fields";
import { VortalLog } from "@/entities";

export class VortalLogsList extends EntityList {
  static entityClass = VortalLog;

  constructor(listTypeId) {
    super(listTypeId, getVortalLogsListsConfig);

    this.tableView = VortalLogsTableView;

    this.selfClass = VortalLogsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'vortal_user_action',
        dataCy: 'vortal-user-action',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_vortal_user_action_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('VORTAL_USER_ACTIONS'),
        },
      },
      {
        key: 'vortal_company_identifier_type',
        dataCy: 'identifier-type',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_vortal_company_identifier_type_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('VORTAL_COMPANY_IDENTIFIER_TYPES'),
        },
      },
      {
        key: 'company_identifier',
        dataCy: 'company-identifier',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_company_identifier_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_company_identifier_input_placeholder.text'),
        },
      },
      {
        key: 'contact_vortal_user_code',
        dataCy: 'contact-vortal-user-code',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_contact_vortal_user_code_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_contact_vortal_user_code_input_placeholder.text'),
        },
      },
      {
        key: 'company_vortal_company_code',
        dataCy: 'company-vortal-company-code',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_company_vortal_company_code_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_company_vortal_company_code_input_placeholder.text'),
        },
      },
      {
        key: 'project_vortal_operation_uid',
        dataCy: 'project-vortal-operation-uid',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_project_vortal_operation_uid_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_project_vortal_operation_uid_input_placeholder.text'),
        },
      },
      {
        key: 'contact_email',
        dataCy: 'contact-email',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_contact_email_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_contact_email_input_placeholder.text'),
        },
      },
      {
        key: 'project_rsm_id',
        dataCy: 'project-rsm',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_project_rsm_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_project_rsm_id_input_placeholder.text'),
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'vortal_contact_id',
        dataCy: 'vortal-contact',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_vortal_contact_label.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_vortal_contact_input_placeholder.text'),
          searchFunc: getContacts,
        },
      },
      {
        key: 'vortal_company_id',
        dataCy: 'vortal-company',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_vortal_company_label.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_vortal_company_placeholder.text'),
          searchFunc: getCompanies,
        },
      },
      {
        key: 'vortal_project_id',
        dataCy: 'vortal-project',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_vortal_project_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_vortal_project_input_placeholder.text'),
        },
      },
      {
        key: 'project_smart_id',
        dataCy: 'smart-project',
        name: Vue.prototype.$vDict('vortal_connect_logs.filter_project_smart_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_logs.filter_project_smart_id_input_placeholder.text'),
        },
      },
    ];
  }
}
