import enums from "@/config/enums";
import store from "@/store";
import Vue from 'vue';

export const getContactsListsConfig = () => ({
  [enums.LIST_TYPES.ALL_CONTACTS]: {
    isMain: true,
    isSavePosition: true,
    showFieldsSettings: true,
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('excel_export'),
      print: store.getters['Account/hasComponent']('print'),
    },
    defaultSort: {
      default: {
        name: { order: 'a' },
      },
    },
    permanentQueryData: {
      default: {
        filter: {
          company_count: {
            gte: 1,
          },
        },
      },
    },
    customDefaultFieldset: true,
    showSsn: true,
    alternativeListTypeId: enums.LIST_TYPES.USER_CONTACTS,
    hasBulkOperations: true,
    bulkOperations() {
      return [
        {
          key: 'print',
        },
        {
          key: 'excel',
        },
        {
          key: 'add',
          name: Vue.prototype.$vDict('contacts.add_to_my_contacts_button.text'),
          icon: 'add-round',
          isNeedReload: true,
        },
      ];
    },
    hasAccess: () => store.getters['Account/hasComponent']('all_contacts'),
  },
  [enums.LIST_TYPES.USER_CONTACTS]: {
    isMain: true,
    isSavePosition: true,
    showFieldsSettings: true,
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('excel_export'),
      print: store.getters['Account/hasComponent']('print'),
    },
    defaultSort: {
      default: {
        name: { order: 'a' },
      },
    },
    defaultSearchFilter: {
      default: {
        'users.user_id': [
          store.getters['Account/userId'],
        ],
      },
    },
    hasLockedItems: true,
    customDefaultFieldset: true,
    couldHasCustomFields: store.getters['Account/hasComponent']('contact_custom_fields'),
    alternativeListTypeId: enums.LIST_TYPES.ALL_CONTACTS,
    hasBulkOperations: true,
    hasSavedByFilter: true,
    bulkOperations() {
      return [
        {
          key: 'print',
        },
        {
          key: 'excel',
        },
        {
          key: 'add',
          name: Vue.prototype.$vDict('contacts.add_to_my_contacts_button.text'),
          icon: 'add-round',
          isNeedReload: true,
        },
        {
          key: 'delete',
          name: Vue.prototype.$vDict('contacts.delete_from_my_contacts_button.text'),
          isNeedReload: true,
        },
      ];
    },
    hasAccess: () => store.getters['Account/hasComponent']('user_contacts'),
  },
  [enums.LIST_TYPES.COMPANY_CONTACTS]: {
    // fieldset: [
    //   'name',
    //   'phones',
    //   'email',
    // ],
    defaultSort: {
      default: {
        name: { order: 'a' },
      },
    },
    customDefaultFieldset: true,
    hasAccess: () => store.getters['Account/hasComponent']('contact'),
  },
});
