import enums from "@/config/enums";
import store from '@/store';

export const getMyranUserProfilesListsConfig = () => ({
  [enums.LIST_TYPES.MYRAN_LOGS_USER_PROFILES]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
  [enums.LIST_TYPES.USER_MYRAN_UPDATES]: {
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
});
