import {
  LIST_TYPES,
} from '@/config/enums';

export const getDefaultDashboardWidgetsListConfig = () => ({
  [LIST_TYPES.DASHBOARD_WIDGETS]: {
    isMain: true,
    isSavePosition: true,
  },
});
