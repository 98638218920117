/*
  UserProjectsList class
 */

import { BaseProjectsList } from "@/entities/project/BaseProjectsList";
import { UserProject } from "@/entities";

export class UserProjectsList extends BaseProjectsList {
  static entityClass = UserProject;
  static isUserSection = true;

  constructor(listTypeId) {
    super(listTypeId);

    this.selfClass = UserProjectsList;
    this.statusId = 'all';
  }
}
