<template>
  <div class="dead-message-actions">
    <dead-message-subscribe-modal
      ref="subscribeModal"
    />
  </div>
</template>

<script>
import DeadMessageSubscribeModal from "@/views/system/dead-message-subscriptions/DeadMessageSubscribeModal.vue";

export default {
  name: 'dead-message-actions',
  components: {
    DeadMessageSubscribeModal,
  },
  methods: {
    async actionHandler(entity, action) {
      switch (action.key) {
        case 'subscribe':
          this.$refs.subscribeModal.show({
            topic: entity.getValue('topic'),
            service_name: entity.getValue('service_name'),
          });
          break;
        default:
          await entity.doAction(action);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
