/*
  NotesList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import { getNotes } from "@/api/repositories/salesRepository";
import { getNotesListsConfig } from "@/entities/note/config";
import {
  Project,
  Company,
  Contact,
  Tender,
  Note,
} from "@/entities";

export class NotesList extends EntityList {
  static entityClass = Note;

  constructor(listTypeId) {
    super(listTypeId, getNotesListsConfig);

    //
    this.selfClass = NotesList;
  }

  async prepareItems(items = []) {
    return Promise.resolve(items);
  }

  loadDatasets(items = []) {
    const loadEntitiesData = [
      {
        entityClass: Project,
        queryParams: {
          fields: ['id', 'title', 'subtitle', 'source_id', 'deleted'],
          show_locked: 1,
        },
      },
      {
        entityClass: Company,
        queryParams: {
          fields: ['id', 'name'],
          show_locked: 1,
        },
      },
      {
        entityClass: Contact,
        queryParams: {
          fields: ['id', 'first_name', 'last_name'],
          show_locked: 1,
        },
      },
      {
        entityClass: Tender,
        queryParams: {
          fields: ['id', 'name'],
          show_locked: 1,
        },
      },
    ];

    this.loadEntitiesDatasets(loadEntitiesData, items);
  }
}
