import Vue from "vue";

export const requiredFields = () => [
  {
    key: 'status',
    label: Vue.prototype.$vDict('activities.field_status.text'),
    class: 'column-min',
    dataCy: 'status',
  },
  {
    key: 'activity_type_id',
    label: Vue.prototype.$vDict('activities.field_activity_type_id.text'),
    class: 'column-name',
    dataCy: 'activity-type',
  },
  {
    key: 'start_date_time',
    label: Vue.prototype.$vDict('activities.field_start_date_time.text'),
    class: 'text-nowrap',
    sortable: true,
    dataCy: 'start-date-time',
  },
  {
    key: 'linked_to',
    label: Vue.prototype.$vDict('activities.field_linked_to.text'),
    fieldset: [
      'activity_type_id',
      'entity_id',
    ],
    dataCy: 'linked-to',
  },
  {
    key: 'assigned_to',
    label: Vue.prototype.$vDict('activities.field_assigned_to.text'),
    dataCy: 'assigned-to',
  },
  {
    key: 'user_id',
    label: Vue.prototype.$vDict('activities.field_user_id.text'),
    dataCy: 'created-by',
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('activities.field_modified_at.text'),
    sortable: true,
    dataCy: 'modified-at',
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('activities.field_created_at.text'),
    sortable: true,
    dataCy: 'created-at',
  },
];
