<template>
  <base-table-view
    class="custom-fieldset-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(name)="data">
      <div class="field-title">
        <b-link
          class="field-title__text"
          :to="data.item.getLinkTo()"
          @click.native="data.item.linkToHandler($event)"
        >{{ data.value }}</b-link>
      </div>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'custom-fieldset-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
