/*
  MyranUserVersion class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getUserVersions } from '@/api/repositories/myranRepository';
import { formatDateTime } from "@/shared/utils";

export class MyranUserVersion extends BaseEntity {
  static entityKey = 'myran_user_version';
  static datasetKey = 'myranUserVersions';
  static entityTypeId = ENTITY_TYPES.MYRAN_USER_VERSION;

  // api
  static loadAllItemsFunc = getUserVersions;
  static getEntityPluralTypeText() {
    return 'User versions';
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = MyranUserVersion;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'license_id':
        res = Vue.prototype.$lFind('client.licenses', { id: fieldValue, prop: 'name' });
        break;
      case 'language_id':
        res = Vue.prototype.$lFind('global.languages', { id: fieldValue, prop: 'name' });
        break;
      case 'currency_id':
        res = Vue.prototype.$lFind('global.currencies', { id: fieldValue, prop: 'name' });
        break;
      case 'status':
        value = this.getItemFromEnum('USER_STATUSES', fieldValue);
        res = value?.name || '';
        break;
      case 'user_import_status':
        value = this.getItemFromEnum('USER_IMPORT_STATUSES', fieldValue);
        res = value?.name || '';
        break;
      case 'created_at':
        res = fieldValue;
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'name':
        res = this.getUser(dataset);
        break;
      default:
    }

    return res;
  }
}
