/*
  Client class
 */

import Vue from 'vue';
import BaseEntity from "@/shared/classes/entity/BaseEntity";
import store from "@/store";
import router from "@/router";
import { ENTITY_TYPES, SSO_PROVIDERS } from "@/config/enums";
import {
  getClients,
  updateClients,
  deleteClient,
  getClient,
  getClientDaughters,
  postClient,
  patchClient,
} from "@/api/repositories/clientsRepository";
import { formatDateTime } from "@/shared/utils";
import { User, Subscription, ApiClient } from "@/entities";
import { ClientUser } from "@/entities/client-user/ClientUser";
import { getDomainsOptions } from "@/config/lookups";

const defaultData = () => ({
  name: '',
  source_id: null,
  is_suspended: false,
  flattened_data: '',
  smart_settings: {
    sso_provider_id: SSO_PROVIDERS.SHARP,
  },
});

export class Client extends BaseEntity {
  static entityKey = 'client';
  static userSectionAccessComponent = 'all_clients';
  static loadAllItemsFunc = getClients;
  static loadItemFunc = getClient;
  static deleteItemFunc = deleteClient;
  static postItemFunc = postClient;
  static patchItemFunc = patchClient;
  static datasetKey = 'clients';
  static routes = {
    view: 'SupportClient',
    edit: 'SupportEditorClient',
    list: 'SupportClients',
    create: 'SupportEditorClientNew',
  };
  static entityTypeId = ENTITY_TYPES.CLIENT;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('clients.plural_clients_text.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    // this.loadListFunc = getClients;
    this.selfClass = Client;
  }

  prepareItemData(data) {
    const {
      client_user = null,
      api_clients = [],
      subscriptions = [],
      ...clientData
    } = data;

    const clientUserData = client_user ? {
      ...client_user,
      ...(!client_user.client ? {
        client: {
          id: clientData.id,
          name: clientData.name,
          smart_settings: clientData.smart_settings,
        },
      } : {}),
    } : null;

    this.apiClients = (api_clients || []).map(apiClientData => new ApiClient(apiClientData));
    this.parents = [];
    this.subscriptions = (subscriptions || []).map(subscriptionData => new Subscription(subscriptionData));
    this.clientUser = clientUserData ? new ClientUser(clientUserData) : null;

    _.merge(this.data, defaultData(), clientData);
  }

  static getAllActions() {
    return [
      {
        key: 'view',
        name: Vue.prototype.$vDict('clients.view_button.text'),
        icon: 'pwd-show',
      },
      {
        key: 'edit',
        dataCy: 'edit',
        name: Vue.prototype.$vDict('clients.edit_button.text'),
        icon: 'edit',
      },
      {
        key: 'create_subscription',
        dataCy: 'create-subscription',
        name: Vue.prototype.$vDict('clients.create_subscription_button.text'),
        icon: 'lead-info',
      },
      {
        key: 'create_user',
        dataCy: 'create-user',
        name: Vue.prototype.$vDict('clients.create_user_button.text'),
        icon: 'add-user',
      },
      {
        key: 'view_stats',
        dataCy: 'view-stats',
        icon: 'stats',
        name: Vue.prototype.$vDict('clients.usage_stats_button.text'),
      },
      {
        key: 'create_api_client',
        dataCy: 'create-api-client',
        icon: 'api',
        name: Vue.prototype.$vDict('clients.create_api_client_button.text'),
      },
      {
        key: 'view_api_client',
        dataCy: 'view-api-client',
        icon: 'api',
        name: Vue.prototype.$vDict('clients.api_client_button.text'),
      },
      // {
      //   key: 'update_subscription',
      //   dataCy: 'update-subscription',
      //   name: Vue.prototype.$vDict('clients.update_subscription_button.text'),
      //   icon: 'updates-list',
      // },
      {
        type: 'divider',
      },
      {
        key: 'delete',
        icon: 'trash',
        name: Vue.prototype.$vDict('clients.delete_client_button.text'),
        linkClass: 'action-link--warning',
      },
      // {
      //   key: 'activate',
      //   icon: 'power',
      //   name: 'Activate',
      // },
      // {
      //   class: 'text-muted',
      //   key: 'deactivate',
      //   icon: 'deactivated',
      //   name: 'Deactivate',
      // },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'created_at':
      case 'modified_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'source_type_id':
        value = this.getSourceType();
        res = value?.name || '';
        break;
      case 'official_id':
        res = this.getValue('flattened_data.official_id', '');
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getActions() {
    const actions = this.selfClass.getAllActions();
    return actions.filter(action => {
      let res = true;

      switch (action.key) {
        case 'activate':
          res = !this.getValue('activated');
          break;
        case 'deactivate':
          res = this.getValue('activated');
          break;
        // case 'update_subscription':
        //   res = !!this.getValue('source_id');
        //   break;
        case 'view_stats':
          res = store.getters['Account/hasComponent']('usage_stats_client');
          break;
        case 'view_api_client':
          res = store.getters['Account/hasComponent']('api_clients') &&
            this.apiClients.length > 0;
          break;
        case 'create_api_client':
          res = store.getters['Account/hasComponent']('api_clients') &&
            this.apiClients.length === 0;
          break;
        case 'delete':
          res = store.getters['Account/hasComponent']('client_delete_button');
          break;
        case 'create_user':
          res = !!this.listInstance;
          break;
        case 'create_subscription':
          res = !!this.listInstance &&
            (!this.subscriptions.length || store.getters['Account/hasComponent']('ability_add_more_than_one_subscription'));
          break;
        default:
      }

      return res;
    });
  }

  async doAction(action) {
    switch (action.key) {
      case 'create_user':
        this.goToCreateClientUser();
        break;
      case 'create_subscription':
        this.goToCreateClientSubscription();
        break;
      case 'deactivate':
        await this.changeActivated(0);
        break;
      case 'activate':
        await this.changeActivated(1);
        break;
      // case 'update_subscription':
      //   await this.sendUpdateSubscription();
      //   break;
      case 'view_stats':
        router.push({
          name: 'UsageStatisticsClient',
          params: {
            id: this.id,
          },
        });
        break;
      case 'view_api_client':
        this.goToApiClients();
        break;
      case 'create_api_client':
        router.push({
          name: ApiClient.routes.create,
          query: {
            client_id: this.id,
          },
        });
        break;
      default:
        await super.doAction(action);
    }
  }

  goToApiClients() {
    if (!this.apiClients.length) {
      return;
    }

    if (this.apiClients.length > 1) {
      router.push({
        name: ApiClient.routes.list,
        query: {
          searchFilter: `client_id:${this.id}`,
        },
      });
      return;
    }

    router.push({
      name: ApiClient.routes.view,
      params: {
        id: this.apiClients[0].id,
      },
    });
  }

  async changeActivated(activated) {
    try {
      const response = await updateClients(this.id, {
        activated: (activated ? 1 : 0),
      });

      if (response) {
        await this.patchData({
          activated
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  // async sendUpdateSubscription() {
  //   try {
  //     const sourceId = this.getValue('source_id');
  //     const response = await updateCustomerSubscription(sourceId);
  //
  //     if (response) {
  //       Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('clients.update_subscription_success.text'));
  //     }
  //   } catch (error) {
  //     Vue.prototype.$notifToastr('error', error);
  //   }
  // }

  goToCreateClientUser() {
    const query = {
      client_id: this.id,
      from: router.currentRoute.fullPath,
    };

    router.push({
      name: User.routes.create,
      query,
    });
  }

  goToCreateClientSubscription() {
    const query = {
      client_id: this.id,
      from: router.currentRoute.fullPath,
    };

    router.push({
      name: Subscription.routes.create,
      query,
    });
  }

  async fetchApiClients() {
    const query = {
      limit: 10,
      filter: {
        client_id: {
          in: [this.id],
        },
      },
    };
    let response;

    try {
      response = await ApiClient.loadAllItemsFunc(query);
      this.apiClients = (response?.data || []).map(apiClientData => new ApiClient(apiClientData));
    } catch (e) {}
  }

  async fetchParents() {
    const query = {
      limit: 50,
      filter: {
        daughter_id: {
          in: [this.id],
        },
      },
    };
    let response;

    try {
      response = await getClientDaughters(query);
      this.parents = response?.data || [];
    } catch (e) {}
  }

  getDomain(domains = []) {
    const domain = domains.find(el => el.id === this.getValue('smart_settings.domain_id'));

    if (!domain) {
      return;
    }

    const domainsOptions = getDomainsOptions(domains);
    const domainOption = domainsOptions.find(el => el.id === domain.id);

    return {
      id: domain.id,
      domain: domainOption.name || domain.domain,
      send_from: {
        name: domain.send_from?.name,
        email: domain.send_from?.email,
      },
    };
  }

  hasActiveSubscription() {
    return this.subscriptions.some(subscription => subscription.isActive());
  }

  hasActiveSubscriptionOnUser() {
    return this.subscriptions.some(subscription => subscription.isActiveOnUser());
  }

  getFormData() {
    const data = super.getFormData();
    const smart_settings = data.smart_settings || {};

    return {
      ...data,
      smart_settings: {
        ...smart_settings,
        sso_provider_id: smart_settings.sso_provider_id || null,
      },
    };
  }

  isSSO() {
    return this.getValue('smart_settings.sso_provider_id') > 1;
  }
}
