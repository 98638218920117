import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('clients.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('clients.field_name.text'),
    sortable: true,
  },
  {
    key: 'source_id',
    label: Vue.prototype.$vDict('clients.field_source_id.text'),
  },
  {
    key: 'source_type_id',
    label: Vue.prototype.$vDict('clients.field_source_type_id.text'),
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('clients.field_modified_at.text'),
    sortable: true,
  },
  {
    key: 'package_expire_at',
    label: Vue.prototype.$vDict('clients.field_package_expire_at.text'),
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('clients.field_status.text'),
  },
];
