<template>
  <base-table-view
    class="licenses-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(modules)="data">
      <ul
        v-if="data.value.length > 0"
        class="sm-tags-list"
      >
        <li
          v-for="(module, index) in data.value"
          :key="index"
          class="sm-tags-list__item"
        >{{ module.data.name }}</li>
      </ul>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'licenses-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
};
</script>

<style lang="scss" scoped>

</style>
