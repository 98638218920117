/*
  ComponentsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import ComponentsTableView from "@/entities/component/views/ComponentsTableView";
import Vue from "vue";
import { getComponentsListsConfig } from "@/entities/component/config";
import { getComponents, deleteComponent } from "@/api/repositories/systemRepository";
import { requiredFields } from "@/entities/component/fields";
import { Component } from "@/entities";

export class ComponentsList extends EntityList {
  static entityClass = Component;

  constructor(listTypeId) {
    super(listTypeId, getComponentsListsConfig);

    this.tableView = ComponentsTableView;

    this.selfClass = ComponentsList;
    this.getRequiredFields = requiredFields;
  }
}
