<template>
  <base-table-view
    class="pm-client-versions-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(pm_client_id)="data">
      <b-link
        v-if="data.value"
        :to="{ name: 'PMClients', query: { searchFilter: `id:[${data.value.id}]` }  }"
        class="text-secondary"
      >{{ data.value.hint }}</b-link>
    </template>
    <template
      v-for="linkSlot in linkToListSlots"
      v-slot:[`cell(${linkSlot.slotKey})`]="data"
    >
      <b-link
        :to="{ name: linkSlot.routeName, query: { searchFilter: `pm_client_version_id:${data.item.id}` }  }"
        class="text-secondary"
      >{{ $vDict('entity_list.show_list_link.text') }}</b-link>
    </template>
    <template v-slot:cell(is_fetched)="data">
      <div
        v-if="data.value"
        class="status-tag"
        :class="data.value.class"
      >{{ data.value.name }}</div>
    </template>
    <template v-slot:cell(is_valid)="data">
      <div
        v-if="data.value"
        class="status-tag"
        :class="data.value.class"
      >{{ data.value.name }}</div>
    </template>
    <template v-slot:cell(is_migrated)="data">
      <div
        v-if="data.value"
        class="status-tag"
        :class="data.value.class"
      >{{ data.value.name }}</div>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'pm-client-versions-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
    linkToListSlots() {
      return [
        {
          slotKey: 'pm_projects',
          routeName: 'PMProjects',
        },
        {
          slotKey: 'pm_companies',
          routeName: 'PMCompanies',
        },
        {
          slotKey: 'pm_contacts',
          routeName: 'PMContacts',
        },
        {
          slotKey: 'pm_employees',
          routeName: 'PMEmployees',
        },
        {
          slotKey: 'pm_documents',
          routeName: 'PMDocuments',
        },
        {
          slotKey: 'pm_activities',
          routeName: 'PMActivities',
        },
        {
          slotKey: 'pm_custom_fields',
          routeName: 'PMCustomFields',
        },
        {
          slotKey: 'pm_custom_field_values',
          routeName: 'PMCustomFieldValues',
        },
        {
          slotKey: 'pm_custom_field_selections',
          routeName: 'PMCustomFieldSelections',
        },
        {
          slotKey: 'pm_custom_field_employees',
          routeName: 'PMCustomFieldEmployees',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
