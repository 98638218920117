import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'hint',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_client_hint.text'),
  },
  {
    key: 'client_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_client_id.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_created_at.text'),
  },
];
