import Vue from 'vue';
import store from '@/store';
import {
  SEARCH_FIELD_TYPE,
  SEARCH_FILTER_TYPES,
  LIST_TYPES,
  FIELD_VALUE_STYLES,
  COUNTRIES,
} from "@/config/enums";

export const requiredFields = () =>  [
  {
    key: 'status',
    label: Vue.prototype.$vDict('projects.field_status.text'),
    class: 'column-status',
    required: true,
    listTypeIds: [
      LIST_TYPES.ALL_PROJECTS,
      LIST_TYPES.COMPANY_PROJECTS,
      LIST_TYPES.CONTACT_PROJECTS,
    ],
    components: ['user_projects'],
    dataCy: 'status',
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('projects.field_status.text'),
    class: 'column-status',
    required: true,
    fieldset: ['viewed', 'user_project_status_id'],
    listTypeIds: [LIST_TYPES.USER_PROJECTS],
    components: ['user_projects'],
    dataCy: 'status',
  },
  {
    key: 'notes',
    label: Vue.prototype.$vDict('projects.field_notes.text'),
    class: 'column-min column-notes',
    required: true,
    components: ['project_notes', 'project_activities'],
    dataCy: 'notes',
  },
  // {
  //   'key': 'coworkers',
  //   'label': '',
  //   'class': 'column-min'
  // },
  {
    key: 'tags',
    label: Vue.prototype.$vDict('projects.field_tags.text'),
    fieldset: ['tags'],
    class: 'column-tags',
    required: true,
    listTypeIds: [LIST_TYPES.USER_PROJECTS],
    dataCy: 'tags',
  },
  {
    key: 'title',
    label: Vue.prototype.$vDict('projects.field_title.text'),
    fieldset: [
      'id',
      'title',
      'subtitle',
      'category_id',
      'source_id',
      'version',
      'framework_agreement',
      'deleted',
    ],
    class: 'column-name',
    sortable: true,
    required: true,
    sortKeys: ['title'],
    dataCy: 'title',
  },
];

const getCompanyRoles = (listTypeId) => {
  let roleIds = [];

  switch (listTypeId) {
    case LIST_TYPES.ALL_TENDERS:
    case LIST_TYPES.USER_TENDERS:
      roleIds = store.getters['Account/getSelectionsValue']('tender_event_roles');
      break;
    default:
  }

  const res = Vue.prototype.$lGetCompanyStageRoles({
    listTypeId,
    roleIds,
  });

  return res.map(item => ({
    ...item,
    isTitle: true,
  }))
};

export const fieldsSections = (listTypeId) =>  [
  {
    key: 'geography',
    label: Vue.prototype.$vDict('projects.section_geography.text'),
    icon: 'card-geography',
    dataCy: 'geography',
    fields: [
      {
        key: 'country_id',
        label: Vue.prototype.$vDict('projects.field_country_id.text'),
        fieldset: ['country_id'],
        dataCy: 'country',
      },
      {
        key: 'region_id',
        label: Vue.prototype.$vDict('projects.field_region_id.text'),
        fieldset: ['region_id'],
        dataCy: 'region',
      },
      {
        key: 'district_id',
        label: Vue.prototype.$vDict('projects.field_district_id.text'),
        fieldset: ['district_id'],
        dataCy: 'district',
      },
      {
        key: 'city',
        label: Vue.prototype.$vDict('projects.field_city.text'),
        fieldset: ['city'],
        sortable: true,
        sortKeys: ['city'],
        dataCy: 'city',
      },
      {
        key: 'postcode',
        label: Vue.prototype.$vDict('projects.field_postcode.text'),
        fieldset: ['postcode', 'postcode_text'],
        sortable: true,
        sortKeys: ['postcode'],
        dataCy: 'postcode',
      },
      {
        key: 'street',
        label: Vue.prototype.$vDict('projects.field_street.text'),
        fieldset: ['street', 'street_number'],
        sortable: true,
        sortKeys: ['street', 'street_number'],
        dataCy: 'street',
      },
      {
        key: 'property_designation',
        label: Vue.prototype.$vDict('projects.field_property_designation.text'),
        fieldset: ['property_designation'],
        dataCy: 'property-designation',
      },
    ],
  },
  {
    key: 'info',
    label: Vue.prototype.$vDict('projects.section_info.text'),
    icon: 'card-info',
    dataCy: 'info',
    fields: [
      {
        key: 'value',
        label: Vue.prototype.$vDict('projects.field_value.text'),
        fieldset: ['value', 'value_unoff', 'original_currency_id'],
        sortable: true,
        sortKeys: ['value'],
        dataCy: 'value',
        valueStyle: FIELD_VALUE_STYLES.CURRENCY,
        sourceKey: 'value',
      },
      {
        key: 'contract_type_id',
        label: Vue.prototype.$vDict('projects.field_contract_type_id.text'),
        fieldset: ['contract_type_id'],
        sortable: true,
        sortKeys: ['contract_type_id'],
        dataCy: 'contract-type',
      },
      {
        key: 'residence_forms',
        label: Vue.prototype.$vDict('projects.field_residence_forms.text'),
        fieldset: ['residence_forms'],
        dataCy: 'residence-forms',
      },
      {
        key: 'ecocertified_type_id',
        label: Vue.prototype.$vDict('projects.field_ecocertified_type_id.text'),
        fieldset: ['ecocertified_type_id', 'certification_types'],
        class: 'column-icon',
        sortable: true,
        sortKeys: ['ecocertified_type_id'],
        dataCy: 'project-attribute',
        // hideOnCard: true,
      },
      {
        key: 'uncertainty_id',
        label: Vue.prototype.$vDict('projects.field_uncertainty_id.text'),
        fieldset: ['uncertainty_id'],
        dataCy: 'uncertainty',
      },
      {
        key: 'building_purpose',
        label: Vue.prototype.$vDict('projects.field_building_purpose.text'),
        fieldset: ['building_purpose'],
        dataCy: 'building-purpose',
      },
      {
        key: 'url',
        label: Vue.prototype.$vDict('projects.field_url.text'),
        fieldset: ['url'],
        dataCy: 'url',
      },
      {
        key: 'project_area',
        label: Vue.prototype.$vDict('projects.field_project_area.text'),
        fieldset: ['project_area'],
        dataCy: 'project-area',
      },
    ],
  },
  {
    key: 'construction_details',
    label: Vue.prototype.$vDict('projects.section_construction_details.text'),
    icon: 'stage-construction',
    dataCy: 'construction-details',
    fields: [
      {
        key: 'start_date',
        label: Vue.prototype.$vDict('projects.field_start_date.text'),
        fieldset: ['start_text', 'start_date', 'start_unoff'],
        class: 'column-date',
        sortable: true,
        sortKeys: ['start_date'],
        dataCy: 'start-date',
        sourceKey: 'start_date',
      },
      {
        key: 'finish_date',
        label: Vue.prototype.$vDict('projects.field_finish_date.text'),
        fieldset: ['finish_text', 'finish_date'],
        class: 'column-date',
        sortable: true,
        sortKeys: ['finish_date'],
        dataCy: 'finish-date',
        sourceKey: 'finish_date',
      },
      {
        key: 'planning_approval_date',
        label: Vue.prototype.$vDict('projects.field_planning_approval_date.text'),
        fieldset: ['planning_approval_date'],
        class: 'column-date',
        sortable: true,
        sortKeys: ['planning_approval_date'],
        dataCy: 'planning-approval-date',
        sourceKey: 'planning_approval_date',
      },
      {
        key: 'stage_id',
        label: Vue.prototype.$vDict('projects.field_stage_id.text'),
        fieldset: ['stage_id', 'start_date', 'finish_date', 'companies'],
        class: 'column-icon',
        sortable: true,
        sortKeys: ['stage_id'],
        dataCy: 'stage',
        // hideOnCard: true,
      },
      {
        key: 'planning_stage_id',
        label: Vue.prototype.$vDict('projects.field_planning_stage_id.text'),
        fieldset: ['planning_stage_id'],
        sortable: true,
        sortKeys: ['planning_stage_id'],
        dataCy: 'planning-stage',
      },
    ],
  },
  {
    key: 'properties',
    label: Vue.prototype.$vDict('projects.section_properties.text'),
    icon: 'properties',
    dataCy: 'properties',
    fields: [
      {
        key: 'buildings',
        label: Vue.prototype.$vDict('projects.field_buildings.text'),
        fieldset: ['buildings'],
        sortable: true,
        sortKeys: ['buildings'],
        dataCy: 'buildings',
      },
      {
        key: 'apartments',
        label: Vue.prototype.$vDict('projects.field_apartments.text'),
        fieldset: ['apartments'],
        sortable: true,
        sortKeys: ['apartments'],
        dataCy: 'apartments',
      },
      {
        key: 'floors',
        label: Vue.prototype.$vDict('projects.field_floors.text'),
        fieldset: ['floors'],
        sortable: true,
        sortKeys: ['floors'],
        dataCy: 'floors',
      },
      {
        key: 'underground_floors',
        label: Vue.prototype.$vDict('projects.field_underground_floors.text'),
        fieldset: ['underground_floors'],
        sortable: true,
        sortKeys: ['underground_floors'],
        dataCy: 'underground-floors',
      },
      {
        key: 'parking_lots',
        label: Vue.prototype.$vDict('projects.field_parking_lots.text'),
        fieldset: ['parking_lots'],
        sortKeys: ['parking_lots'],
        dataCy: 'parking-lots',
      },
      {
        key: 'materials',
        label: Vue.prototype.$vDict('projects.field_materials.text'),
        fieldset: ['materials', 'material_data'],
        section_id: 3,
        dataCy: 'materials',
      },
      // {
      //   'label': 'Construction area',
      //   'key': 'construction_area',
      //   'fieldset': ['construction_area'],
      //   'sortable': true,
      //   'sortKeys': ['construction_area'],
      // },
      // {
      //   'label': 'Residence form',
      //   'key': 'residence_forms',
      //   'fieldset': ['residence_forms'],
      //   'sortable': true,
      // },
      // {
      //   'label': 'Construction Type & Category',
      //   'key': 'constructionTypeCategory',
      //   'sortable': true
      // },
      // {
      //   key: 'updated_at',
      //   fieldset: ['updated_at'],
      //   sortable: true,
      //   sortKeys: ['updated_at'],
      //   listTypeIds: [LIST_TYPES.USER_PROJECTS],
      // },
      // {
      //   key: 'modified_at',
      //   fieldset: ['modified_at'],
      //   sortable: true,
      //   sortKeys: ['modified_at'],
      //   listTypeIds: [LIST_TYPES.USER_PROJECTS],
      // },
    ],
  },
  {
    key: 'measurement',
    label: Vue.prototype.$vDict('projects.section_measurement.text'),
    icon: 'area',
    dataCy: 'measurement',
    fields: [
      {
        key: 'gross_area',
        label: Vue.prototype.$vDict('projects.field_gross_area.text'),
        fieldset: ['gross_area'],
        sortable: true,
        sortKeys: ['gross_area'],
        dataCy: 'gross-area',
      },
      {
        key: 'gross_volume',
        label: Vue.prototype.$vDict('projects.field_gross_volume.text'),
        fieldset: ['gross_volume'],
        sortable: true,
        sortKeys: ['gross_volume'],
        dataCy: 'gross-volume',
      },
      {
        key: 'usage_area',
        label: Vue.prototype.$vDict('projects.field_usage_area.text'),
        fieldset: ['usage_area'],
        sortable: true,
        sortKeys: ['usage_area'],
        dataCy: 'usage-area',
      },
      {
        key: 'construction_area',
        label: Vue.prototype.$vDict('projects.field_construction_area.text'),
        fieldset: ['construction_area'],
        sortable: true,
        sortKeys: ['construction_area'],
        dataCy: 'construction-area',
      },
      {
        key: 'renovation_area',
        label: Vue.prototype.$vDict('projects.field_renovation_area.text'),
        fieldset: ['renovation_area'],
        sortable: true,
        sortKeys: ['renovation_area'],
        dataCy: 'renovation-area',
      },
      {
        key: 'demolition_area',
        label: Vue.prototype.$vDict('projects.field_demolition_area.text'),
        fieldset: ['demolition_area'],
        sortable: true,
        sortKeys: ['demolition_area'],
        dataCy: 'demolition-area',
      },
      {
        key: 'decontamination_area',
        label: Vue.prototype.$vDict('projects.field_decontamination_area.text'),
        fieldset: ['decontamination_area'],
        sortable: true,
        sortKeys: ['decontamination_area'],
        dataCy: 'decontamination-area',
      },
    ],
  },
  {
    key: 'companies_on_project',
    label: Vue.prototype.$vDict('projects.section_companies_on_project.text'),
    icon: 'company16',
    checked: true,
    filters: [
      {
        fieldKey: 'company_roles',
        fieldType: SEARCH_FIELD_TYPE.SELECT,
        fieldAttrs: {
          formId: 'company-role',
          dropRight: true,
          buttonIcon: 'role-contractor',
          buttonText: Vue.prototype.$vDict('search.company_roles_placeholder.text'),
        },
        formAttrs: {
          class: 'selector-form--height-limited',
        },
        filterType: SEARCH_FILTER_TYPES.CHECKBOXES_TREE,
        filterAttrs: {
          isShowSearch: true,
          searchInputOptions: {
            placeholder: Vue.prototype.$vDict('search.input_autocomplete_roles_placeholder.text'),
          },
          items: getCompanyRoles(listTypeId),
        },
        queryKey: 'visible_roles', // for add value to request
      },
    ],
    fields: [
      {
        key: 'companies_on_project',
        label: Vue.prototype.$vDict('projects.section_companies_on_project.text'),
        class: 'companies-on-project-wrap',
        fieldset: ['roles'],
        dataCy: 'companies-on-project',
        fields: [
          {
            key: 'company_roles_org_number',
            label: Vue.prototype.$vDict('projects.field_company_roles_org_number.text'),
          },
          {
            key: 'company_roles_date',
            label: Vue.prototype.$vDict('projects.field_company_roles_date.text'),
            dataCy: 'company-roles-date',
          },
          {
            key: 'company_roles_contact',
            label: Vue.prototype.$vDict('projects.field_company_roles_contact.text'),
            dataCy: 'company-roles-contact',
          },
          {
            key: 'company_roles_contact_role',
            label: Vue.prototype.$vDict('projects.field_company_roles_contact_role.text'),
            dataCy: 'company-roles-contact-role',
            dependentOn: 'company_roles_contact',
          },
          {
            key: 'company_roles_contact_phone',
            label: Vue.prototype.$vDict('projects.field_company_roles_contact_phone.text'),
            dataCy: 'company-roles-contact-phone',
            dependentOn: 'company_roles_contact',
          },
          {
            key: 'company_roles_contact_email',
            label: Vue.prototype.$vDict('projects.field_company_roles_contact_email.text'),
            dataCy: 'company-roles-contact-email',
            dependentOn: 'company_roles_contact',
          },
        ],
      },
    ],
  },
  {
    key: 'project_updates',
    label: Vue.prototype.$vDict('projects.section_project_updates.text'),
    icon: 'arrow-sync',
    dataCy: 'project-updates',
    fields: [
      {
        key: 'latest_update_at',
        label: Vue.prototype.$vDict('projects.field_latest_update_at.text'),
        fieldset: ['latest_update_at'],
        sortable: true,
        section_id: 5,
        sortKeys: ['latest_update_at'],
        dataCy: 'latest-update-at',
        sourceKey: 'latest_update_at',
      },
      {
        key: 'delivered_at',
        label: Vue.prototype.$vDict('projects.field_delivered_at.text'),
        fieldset: ['delivered_at'],
        sortable: (statusId) => !!statusId && statusId !== 'all',
        section_id: 5,
        sortKeys: ['delivered_at'],
        listTypeIds: [LIST_TYPES.USER_PROJECTS],
        dataCy: 'delivered-at',
        sourceKey: 'delivered_at',
      },
      {
        key: 'responsible',
        label: Vue.prototype.$vDict('projects.field_responsible.text'),
        fieldset: ['responsible_ids'],
        section_id: 5,
        listTypeIds: [LIST_TYPES.USER_PROJECTS],
        dataCy: 'responsible',
        // hideOnCard: true,
      },
      {
        key: 'responsible',
        label: Vue.prototype.$vDict('projects.field_responsible.text'),
        fieldset: [],
        section_id: 5,
        listTypeIds: [LIST_TYPES.ALL_PROJECTS],
        dataCy: 'responsible',
        // hideOnCard: true,
      },
      {
        key: 'client_statuses',
        label: Vue.prototype.$vDict('projects.field_client_statuses.text'),
        fieldset: ['users'],
        section_id: 5,
        listTypeIds: [LIST_TYPES.USER_PROJECTS],
        dataCy: 'client-statuses',
        // hideOnCard: true,
      },
    ],
  },
  {
    key: 'additional_fields',
    label: Vue.prototype.$vDict('projects.section_additional_fields.text'),
    icon: 'baseline-format_list_view',
    dataCy: 'additional-fields',
    fields: [
      {
        key: 'roles',
        label: Vue.prototype.$vDict('projects.field_roles.text'),
        fieldset: ['roles'],
        section_id: 8,
        listTypeIds: [
          LIST_TYPES.COMPANY_PROJECTS,
          LIST_TYPES.CONTACT_PROJECTS,
        ],
        dataCy: 'roles',
      },
      {
        key: 'contacts',
        label: Vue.prototype.$vDict('projects.field_contacts.text'),
        fieldset: ['contacts'],
        class: 'column-entity',
        listTypeIds: [
          LIST_TYPES.COMPANY_PROJECTS,
        ],
        section_id: 8,
        dataCy: 'contacts',
      },
    ],
  },
  {
    key: 'custom_fields',
    label: Vue.prototype.$vDict('projects.section_custom_fields.text'),
    icon: 'internal',
    dataCy: 'custom-fields',
    fields: [],
  },
];

export const fieldsBlackList = () => ({
  url: {
    countries: [
      // COUNTRIES.SWEDEN,
      // COUNTRIES.FINLAND,
      // COUNTRIES.NORWAY,
      // COUNTRIES.DENMARK,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  uncertainty_id: {
    countries: [
      COUNTRIES.FINLAND,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  planning_stage_id: {
    countries: [
      COUNTRIES.SWEDEN,
      // COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
    ],
  },
  construction_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  gross_volume: {
    countries: [
      COUNTRIES.DENMARK,
    ],
  },
  renovation_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  demolition_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  decontamination_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  usage_area: {
    countries: [
      COUNTRIES.DENMARK,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  residence_forms: {
    countries: [
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  ecocertified_type_id: {
    countries: [
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  parking_lots: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  building_purpose: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
    ],
  },
  // stage_id: {
  //   countries: [
  //     COUNTRIES.CZECH,
  //     COUNTRIES.SLOVAKIA,
  //   ],
  // },
  materials: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.NORWAY,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
    ],
  },
  publish_source_id: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
    ],
  },
  contract_type_id: {
    countries: [
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  underground_floors: {
    countries: [
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  company_roles_org_number: {
    countries: [
      COUNTRIES.LIECHTENSTEIN,
      COUNTRIES.SWITZERLAND,
    ],
  },
  project_area: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
    ],
  },
  planning_approval_date: {
    countries: [
      COUNTRIES.SWEDEN,
      COUNTRIES.DENMARK,
      COUNTRIES.FINLAND,
      COUNTRIES.NORWAY,
      COUNTRIES.CZECH,
      COUNTRIES.SLOVAKIA,
    ],
  },
});

export const printSettingsFields = () => {
  return [
    {
      key: 'description',
      fieldset: ['description', 'what_happens'],
      label: Vue.prototype.$vDict('projects.field_description.text'),
    },
  ];
};

export const fixedPrintFields = () => {
  return [
    {
      key: 'source_id',
      dataCy: 'project-nr',
      label: Vue.prototype.$vDict('project.info_label_project_nr.text'),
      fieldset: ['source_id'],
    },
  ];
};
