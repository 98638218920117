/*
  VortalLog class
 */

import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getLogs } from "@/api/repositories/vortalConnectRepository";
import { formatDateTimeNoWrap } from "@/shared/utils";
import Vue from "vue";

export class VortalLog extends BaseEntity {
  static entityKey = 'vortal_log';
  static datasetKey = 'vortalLogs';
  static entityTypeId = ENTITY_TYPES.VORTAL_LOG;

  // api
  static loadAllItemsFunc = getLogs;
  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('vortal_connect_logs.logs_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = VortalLog;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'vortal_user_action':
        value = this.getItemFromEnum('VORTAL_USER_ACTIONS', fieldValue);
        res = value?.name || '';
        break;
      case 'company_vortal_identifier_type':
        value = this.getItemFromEnum('VORTAL_COMPANY_IDENTIFIER_TYPES', fieldValue);
        res = value?.name || '';
        break;
      case 'created_at':
        res = fieldValue ? formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'project_smart_id':
        res = this.getDatasetItem(dataset, 'smartProjects', fieldValue);
        break;
      default:
    }

    return res;
  }
}
