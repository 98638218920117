<template>
  <base-table-view
    class="pm-employees-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(pm_client_version_id)="data">
      <client-version-field
        :version-id="data.value"
      />
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import ClientVersionField from "@/entities/parabyg-migration/ClientVersionField";

export default {
  name: 'pm-employees-table-view',
  components: {
    BaseTableView,
    ClientVersionField,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
