/*
  PMCustomField class
 */

import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES, PM_ENTITY_TYPES } from '@/config/enums';
import { getPMCustomFields } from "@/api/repositories/parabygMigrationRepository";
import { Client } from "@/entities";
import { formatDateTime } from "@/shared/utils";
import Vue from "vue";

const defaultData = () => ({
  id: null,
  pm_client_version_id: null,
  hint: '',
  value_type: null,
  custom_field_id: null,
  is_valid: null,
  validation_error_message: null,
});

export class PMCustomField extends BaseEntity {
  static entityKey = 'pm_custom_field';
  static datasetKey = 'pmCustomFields';
  static loadAllItemsFunc = getPMCustomFields;
  static entityTypeId = ENTITY_TYPES.PM_CUSTOM_FIELD;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('parabyg_migration.custom_fields_plural_text.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    this.selfClass = PMCustomField;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'client_id':
        res = this.getClient(dataset);
        break;
      case 'display_name':
        res = this.getDisplayName(dataset);
        break;
      case 'used_in_projects':
        res = this.getUsedCountFromAggs(dataset, PM_ENTITY_TYPES.PROJECT);
        break;
      case 'used_in_companies':
        res = this.getUsedCountFromAggs(dataset, PM_ENTITY_TYPES.COMPANY);
        break;
      case 'used_in_contacts':
        res = this.getUsedCountFromAggs(dataset, PM_ENTITY_TYPES.CONTACT);
        break;
      default:
    }

    return res;
  }

  getClient(dataset = {}) {
    return this.getDatasetItem(dataset, Client.datasetKey, this.getValue('client_id'));
  }

  getAggItemFromDataset(dataset = {}) {
    const aggId = `${this.getValue('pm_client_version_id')}.${this.getValue('hint')}`;
    return this.getDatasetItem(dataset, 'aggregations', aggId) || {};
  }

  getUsedCountFromAggs(dataset = {}, pmEntityTypeId) {
    const aggItem = this.getAggItemFromDataset(dataset);
    const countAggs = aggItem.aggs || [];
    const countAgg = countAggs.find(el => el.entity_type_id === pmEntityTypeId);

    return countAgg?.count || null;
  }

  getDisplayName(dataset = {}) {
    const aggItem = this.getAggItemFromDataset(dataset);
    return aggItem.display_name || '';
  }
}
