import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'pm_client_version_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
  },
  {
    key: 'pm_custom_field_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_custom_field_id.text'),
  },
  {
    key: 'value',
    label: Vue.prototype.$vDict('parabyg_migration.field_value.text'),
  },
  {
    key: 'custom_field_selection_key',
    label: Vue.prototype.$vDict('parabyg_migration.field_custom_field_selection_key.text'),
  },
  {
    key: 'is_valid',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
  },
  {
    key: 'validation_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_validation_error_message.text'),
    details: true,
  }
];
