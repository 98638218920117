/*
  UserTender class
 */

import { BaseTender } from "@/entities/tender/BaseTender";

export class Tender extends BaseTender {
  static routes = BaseTender.allSectionRoutes;

  constructor(...args) {
    super(...args);

    this.selfClass = Tender;
  }
}
