/*
  ClientVersion class
 */

import Vue from 'vue';
import { ENTITY_TYPES } from '@/config/enums';
import { formatDateTime } from "@/shared/utils";
import {
  getPMClientVersions,
  getPMClientVersion,
  deletePMClientVersion,
  postPMClientVersion,
  fetchPMClientVersion,
  validatePMClientVersion,
  migratePMClientVersion,
  forceMigratePMClientVersion,
} from "@/api/repositories/parabygMigrationRepository";
import { ParabygMigrationEntity } from "@/entities/parabyg-migration/ParabygMigrationEntity";
import { PMClient } from "@/entities";

const defaultData = () => ({
  id: null,
  pm_client_id: null,
  created_at: null,
  fetching_launched_at: null,
  fetching_attempts: null,
  fetching_error_message: null,
  fetched_at: null,
  is_fetched: null,
  validation_launched_at: null,
  validation_attempts: null,
  validated_at: null,
  is_valid: null,
  migration_launched_at: null,
  migration_attempts: null,
  migrated_at: null,
  is_migrated: null,
});

export class PMClientVersion extends ParabygMigrationEntity {
  static entityKey = 'pm_client_version';
  static datasetKey = 'pmClientVersions';
  static loadAllItemsFunc = getPMClientVersions;
  static deleteItemFunc = deletePMClientVersion;
  static postItemFunc = postPMClientVersion;
  static routes = {
    list: 'PMClientVersions',
    create: 'PMClientVersionCreate',
  };
  static entityTypeId = ENTITY_TYPES.PM_CLIENT_VERSION;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('parabyg_migration.client_versions_plural_text.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    this.selfClass = PMClientVersion;
  }

  static getAllActions() {
    return [
      {
        key: 'fetch',
        name: Vue.prototype.$vDict('parabyg_migration.client_version_fetch_button.text'),
        icon: 'recycle',
      },
      {
        key: 'validate',
        name: Vue.prototype.$vDict('parabyg_migration.client_version_validate_button.text'),
        icon: 'valid',
      },
      // {
      //   key: 'migrate',
      //   name: Vue.prototype.$vDict('parabyg_migration.client_version_migrate_button.text'),
      //   icon: 'migrate',
      // },
      // {
      //   key: 'force_migrate',
      //   name: Vue.prototype.$vDict('parabyg_migration.client_version_force_migrate_button.text'),
      //   icon: 'force-migrate'
      // },
      {
        key: 'force_migrate',
        name: Vue.prototype.$vDict('parabyg_migration.client_version_migrate_button.text'),
        icon: 'migrate'
      },
      {
        type: 'divider',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
      case 'fetched_at':
      case 'fetching_launched_at':
      case 'validation_launched_at':
      case 'validated_at':
      case 'migration_launched_at':
      case 'migrated_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'is_fetched':
        res = this.getMigrationStatusTag({
          launchedAt: this.getValue('fetching_launched_at'),
          doneAt: this.getValue('fetched_at'),
          isDone: this.getValue('is_fetched'),
          jobSend: this.getValue('fetching_job_send'),
          migrationType: 'fetching',
        });
        break;
      case 'is_valid':
        res = this.getMigrationStatusTag({
          launchedAt: this.getValue('validation_launched_at'),
          doneAt: this.getValue('validated_at'),
          isDone: this.getValue('is_valid'),
          jobSend: this.getValue('validation_job_send'),
          migrationType: 'validation',
        });
        break;
      case 'is_migrated':
        res = this.getMigrationStatusTag({
          launchedAt: this.getValue('migration_launched_at'),
          doneAt: this.getValue('migrated_at'),
          isDone: this.getValue('is_migrated'),
          jobSend: this.getValue('migration_job_send'),
          migrationType: 'migration',
        });
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'pm_client_id':
        res = this.getPMClient(dataset);
        break;
      default:
    }

    return res;
  }

  getPMClient(dataset = {}) {
    return this.getDatasetItem(dataset, PMClient.datasetKey, this.getValue('pm_client_id'));
  }

  async fetchData() {
    try {
      const data = await getPMClientVersion(this.id);
      this.patchData(data);
    } catch (e) {}
  }

  async doFetch() {
    try {
      await fetchPMClientVersion(this.id);
      await this.fetchData();
      Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('parabyg_migration.client_version_fetch_success.text'));
    } catch (error) {
      Vue.prototype.$notifUnexpectedError();
    }
  }

  async doMigrate(force = false) {
    try {
      if (force) {
        await forceMigratePMClientVersion(this.id);
      } else {
        await migratePMClientVersion(this.id);
      }

      await this.fetchData();
      Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('parabyg_migration.client_version_migrate_success.text'));
    } catch (error) {
      Vue.prototype.$notifUnexpectedError();
    }
  }

  async doValidate() {
    try {
      await validatePMClientVersion(this.id);
      await this.fetchData();
      Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('parabyg_migration.client_version_validate_success.text'));
    } catch (error) {
      Vue.prototype.$notifUnexpectedError();
    }
  }

  getPostData(formData) {
    return {
      pm_client_id: formData.pm_client_id,
    };
  }

  getActions() {
    const actions = this.selfClass.getAllActions();
    const res = actions.filter(action => {
      let res = true;

      switch (action.key) {
        case 'validate':
        // case 'force_migrate':
          res = !!this.getValue('is_fetched');
          break;
        // case 'migrate':
        //   res = !!this.getValue('is_valid');
        //   break;
        case 'force_migrate':
          res = !!this.getValue('validation_launched_at') &&
            !!this.getValue('validated_at');
          break;
        default:
      }

      return res;
    });

    return res.map(action => {
      let disabled = false;

      switch (action.key) {
        case 'fetch':
          disabled = this.getValue('fetching_job_send') !== 0;
          break;
        case 'validate':
          disabled = this.getValue('validation_job_send') !== 0;
          break;
        case 'force_migrate':
          disabled = this.getValue('migration_job_send') !== 0;
          break;
        default:
      }

      return { ...action, disabled };
    });
  }

  async doAction(action) {
    switch (action.key) {
      case 'fetch':
        await this.doFetch();
        break;
      case 'validate':
        await this.doValidate();
        break;
      // case 'migrate':
      //   await this.doMigrate();
      //   break;
      case 'force_migrate':
        await this.doMigrate(true);
        break;
      default:
        await super.doAction(action);
    }
  }
}
