<template>
  <base-table-view
    class="pm-companies-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(pm_client_version_id)="data">
      <client-version-field
        :version-id="data.value"
      />
    </template>
    <template v-slot:cell(entity_id)="data">
      <b-link
        v-if="data.value && data.value.name"
        :to="{ name: 'Company', params: { id: data.value.id }  }"
        class="text-secondary"
      >{{ data.value.name }}</b-link>
      <template v-else>{{ data.item.getValue('entity_id') }}</template>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import ClientVersionField from "@/entities/parabyg-migration/ClientVersionField";

export default {
  name: 'pm-companies-table-view',
  components: {
    BaseTableView,
    ClientVersionField,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
