<template>
  <div>
    <base-table-view
      class="subscriptions-table-view"
      selectable
      :items="items"
      :fields="fields"
      :sort="sort"
      :entity-list="entityList"
      :fixed-required-columns="fixedRequiredColumns"
      @change-sort="changeSort"
    >
      <template v-slot:cell(name)="{ item, value }">
        <b-link
          class="entity-link"
          :to="item.getLinkTo()"
          @click.native="item.linkToHandler($event)"
        >{{ item.getName() }}</b-link>
      </template>

      <template v-slot:cell(client_id)="data">
        <b-link
          v-if="data.value"
          class="text-secondary"
          :to="{ name: 'SupportClient', params: { id: data.item.getValue('client_id') } }"
        >{{ data.value.name }}</b-link>
      </template>

      <template v-slot:cell(status)="data">
        <entity-status-tag
          v-if="data.value"
          :variant="data.value.variant"
          :name="data.value.name"
        />
      </template>

      <template v-slot:cell(actions)="data">
        <actions-field
          :possible-actions="possibleActions"
          :actions="data.item.getActions(entityList.dataset)"
          :is-loading="isLoadingEntityId(data.item.id)"
          @action="entityActionHandler(data.item, $event)"
        />
      </template>
    </base-table-view>

    <subscription-actions
      ref="entityActions"
      :dataset="entityList"
    />
  </div>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import SubscriptionActions from "@/entities/subscriptions/views/SubscriptionActions";
import ActionsField from "@/components/table/fields/ActionsField";
import EntityStatusTag from "@/components/entity/EntityStatusTag.vue";

export default {
  name: 'subscriptions-table-view',
  components: {
    BaseTableView,
    SubscriptionActions,
    ActionsField,
    EntityStatusTag,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
