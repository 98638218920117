import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'file_name',
    label: Vue.prototype.$vDict('user_files.field_file_name.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('user_files.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'client_id',
    label: Vue.prototype.$vDict('user_files.field_client_id.text'),
    listTypeIds: [enums.LIST_TYPES.ALL_FILES],
    // formatter: (value, key, item) => this.listInstance.getDatasetItem('clients', value),
    // isSupport: true,
  },
  {
    key: 'user_id',
    label: Vue.prototype.$vDict('user_files.field_user_id.text'),
  },
  {
    key: 'list_type_id',
    label: Vue.prototype.$vDict('user_files.field_list_type_id.text'),
  },
  {
    key: 'file_status',
    label: Vue.prototype.$vDict('user_files.field_file_status.text'),
  },
];
