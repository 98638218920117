<template>
  <base-table-view
    class="email-log-events-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(user_id)="data">
      <field-user
        v-if="data.value"
        :user-data="data.value"
      />
    </template>
    <template v-slot:cell(email_provider_event_type_id)="data">
      <div
        v-if="data.value"
        class="field-email-event-type"
      >
        <div class="field-email-event-type__icon">
          <svg-icon :name="data.value.icon" />
        </div>
        <div class="field-email-event-type__text">{{ data.value.name }}</div>
      </div>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import FieldUser from "@/components/entity-fields/FieldUser";

export default {
  name: 'email-log-events-table-view',
  components: {
    BaseTableView,
    FieldUser,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.field-email-event-type {
  display: flex;

  &__icon {
    --ico-color-2: var(--secondary);

    margin-right: 8px;
    height: 21px;
    display: flex;
    align-items: center;

    .svg-icon {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
</style>
