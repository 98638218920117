import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToJsonRpc } from '@/shared/api';
import { API_FILTER_TYPES } from "@/config/enums";

const BACKEND_ENDPOINTS = {
  SYSTEM_COUNTRY_MODULES_ITEM: { url: '/v2/system/country_modules/{cm_id}' },
  SYSTEM_COUNTRY_MODULES: { url: '/v2/system/country_modules' },
  SYSTEM_COUNTRY_MODULES_QUERY: {
    url: '/v2/system/country_modules/query',
    filter: {
      country_id: { type: API_FILTER_TYPES.IN_INT },
    },
  },
};

export function getCountryModules(data = null) {
  const endpoint = BACKEND_ENDPOINTS.SYSTEM_COUNTRY_MODULES_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getCountryModulesItem(cm_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SYSTEM_COUNTRY_MODULES_ITEM.url, { cm_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

export function createCountryModules(data = {}) {
  const path = BACKEND_ENDPOINTS.SYSTEM_COUNTRY_MODULES.url;
  return BaseRepository.post(path, data);
}

export function patchCountryModules(cm_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SYSTEM_COUNTRY_MODULES_ITEM.url, { cm_id });
  return BaseRepository.patch(path, data);
}

export function deleteCountryModules(cm_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SYSTEM_COUNTRY_MODULES_ITEM.url, { cm_id });
  return BaseRepository.delete(path);
}
