/*
  ReleaseNotesList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import ReleaseNotesTableView from "@/entities/release-note/views/ReleaseNotesTableView";
import Vue from "vue";
import { deleteReleaseNote, getReleaseNotes } from "@/api/repositories/releaseNotesRepository";
import { requiredFields } from "@/entities/release-note/fields";
import { getReleaseNotesListsConfig } from "@/entities/release-note/config";
import { ReleaseNote } from "@/entities";

export class ReleaseNotesList extends EntityList {
  static entityClass = ReleaseNote;

  constructor(listTypeId) {
    super(listTypeId, getReleaseNotesListsConfig);

    this.tableView = ReleaseNotesTableView;

    this.selfClass = ReleaseNotesList;
    this.getRequiredFields = requiredFields;
  }
}
