<template>
  <base-table-view
    row-number
    class="files-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(user_id)="data">
      <div
        v-if="data.value"
        class="field-user"
      >
        <avatar
          :user-data="data.value"
          class="field-user__avatar"
        />
        <div class="field-user__name">
          <template v-if="hasUsersPermissions">
            <b-link :to="{ name: 'SupportUser', params: { id: data.value.id } }">
              {{ data.value.name }}
            </b-link>
          </template>
          <template v-else>
            {{ data.value.name }}
          </template>
        </div>
      </div>
    </template>
    <template v-slot:cell(client_id)="data">
      <b-link
        v-if="data.value"
        :to="{ name: 'SupportClient', params: { id: data.item.getValue('client_id') } }"
      >{{ data.value.name }}</b-link>
    </template>
    <template v-slot:cell(file_status)="data">
      <div
        v-if="data.value"
        class="status-tag"
        :class="data.value.statusTagClass"
      >{{ data.value.name }}</div>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import Avatar from "@/components/Avatar";

export default {
  name: 'files-table-view',
  components: {
    BaseTableView,
    Avatar,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
    hasUsersPermissions() {
      return this.$store.getters['Account/hasComponent']('all_users');
    },
  },
};
</script>

<style lang="scss" scoped>
.files-table-view::v-deep {
  .field-user {
    display: flex;

    &__avatar {
      margin-right: 10px;
    }

    &__name {
      display: flex;
      align-items: center;
    }
  }
}
</style>
