/*
  UserTransferTasksList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import UserTransferTasksTableView from "@/entities/user-transfer-task/views/UserTransferTasksTableView";
import Vue from "vue";
import { getUserTransferTasksListsConfig } from "@/entities/user-transfer-task/config";
import { getTransferTasks } from "@/api/repositories/userTransferTasksRepository";
import { requiredFields } from "@/entities/user-transfer-task/fields";
import { UserTransferTask } from "@/entities";

export class UserTransferTasksList extends EntityList {
  static entityClass = UserTransferTask;

  constructor(listTypeId) {
    super(listTypeId, getUserTransferTasksListsConfig);

    this.tableView = UserTransferTasksTableView;

    this.selfClass = UserTransferTasksList;
    this.getRequiredFields = requiredFields;
  }
}
