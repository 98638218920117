import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'vortal_user_code',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_vortal_user_code.text'),
  },
  {
    key: 'vortal_company_id',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_vortal_company.text'),
  },
  {
    key: 'country_id',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_country.text'),
  },
  {
    key: 'email',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_email.text'),
    sortable: true,
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_name.text'),
    sortable: true,
  },
  {
    key: 'banned',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_banned.text'),
  },
  {
    key: 'unsubscribed',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_unsubscribed.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_modified_at.text'),
    sortable: true,
  },
  {
    key: 'phone_area_code',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_phone_area_code.text'),
    details: true,
  },
  {
    key: 'phone_calling_code',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_phone_calling_code.text'),
    details: true,
  },
  {
    key: 'phone_number',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_phone_number.text'),
    details: true,
  },
  {
    key: 'created_by',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_created_by.text'),
    details: true,
  },
  {
    key: 'modified_by',
    label: Vue.prototype.$vDict('vortal_connect_contacts.field_modified_by.text'),
    details: true,
  },
];
