import enums from "@/config/enums";
import store from "@/store";

export const getActivitiesListsConfig = () => ({
  [enums.LIST_TYPES.USER_ACTIVITIES]: {
    isMain: true,
    isSavePosition: true,
    // fieldset: [
    //   'status',
    //   'activity_type_id',
    //   'start_date_time',
    //   'linked_to',
    //   'assigned_to',
    //   'user_id',
    //   'modified_at',
    //   'created_at',
    // ],
    isOnlyOneSort: true,
    defaultSort: {
      default: {
        start_date_time: { order: 'd' },
      },
    },
    defaultSearchFilter: {
      default: {
        assigned_to: [store.getters['Account/userId']],
      },
    },
    // viewModeComponents: {
    //   [enums.ENTITY_LIST_VIEWS.LIST]: ListActivities,
    // },
  },
});
