import enums from "@/config/enums";
import store from "@/store";
import Vue from 'vue';

export const getProjectsListsConfig = (listTypeId) => ({
  [enums.LIST_TYPES.ALL_PROJECTS]: {
    isMain: true,
    isSavePosition: true,
    showMapView: true,
    showFieldsSettings: true,
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('excel_export'),
      print: store.getters['Account/hasComponent']('print'),
    },
    defaultSort: {
      default: {
        latest_update_at: { order: 'd' },
      },
    },
    defaultSearchFilter: Vue.prototype.$lDict('client.project_frame_agreement', { listTypeId }).length > 0 ? {
      default: {
        framework_agreement: 0,
      },
    } : null,
    customDefaultFieldset: true,
    showSsn: true,
    alternativeListTypeId: enums.LIST_TYPES.USER_PROJECTS,
    hasBulkOperations: true,
    bulkOperations() {
      return [
        {
          key: 'select_responsible',
        },
        {
          key: 'print',
        },
        {
          key: 'excel',
        },
      ];
    },
    hasAccess: () => store.getters['Account/hasComponent']('all_projects'),
  },
  [enums.LIST_TYPES.USER_PROJECTS]: {
    isMain: true,
    isSavePosition: true,
    showMapView: true,
    showFieldsSettings: true,
    hasLockedItems: true,
    couldHasCustomFields: store.getters['Account/hasComponent']('project_custom_fields'),
    defaultSearchFilter: {
      default: {
        'users.user_id': [
          store.getters['Account/userId'],
        ],
      },
    },
    defaultSort: {
      byStatus: {
        [enums.USER_PROJECT_STATUSES.NEW]: {
          delivered_at: { order: 'd' },
        },
      },
      default: {
        latest_update_at: { order: 'd' },
      },
    },
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('excel_export'),
      print: store.getters['Account/hasComponent']('print'),
    },
    actions: {
      clearFolder: {
        byStatus: {
          [enums.USER_PROJECT_STATUSES.NEW]: true,
        },
        default: false,
      },
    },
    customDefaultFieldset: true,
    alternativeListTypeId: enums.LIST_TYPES.ALL_PROJECTS,
    hasBulkOperations: true,
    hasSavedByFilter: true,
    bulkOperations() {
      return [
        {
          key: 'select_responsible',
        },
        {
          key: 'print',
        },
        {
          key: 'excel',
        },
        {
          key: 'delete',
        },
      ];
    },
    hasAccess: () => store.getters['Account/hasComponent']('user_projects'),
  },
  [enums.LIST_TYPES.COMPANY_PROJECTS]: {
    hasLockedItems: true,
    defaultSort: {
      default: {
        latest_update_at: { order: 'd' },
      },
    },
    customDefaultFieldset: true,
    hasAccess: () => store.getters['Account/hasComponent']('company_project_tab'),
  },
  [enums.LIST_TYPES.CONTACT_PROJECTS]: {
    defaultSort: {
      default: {
        title: { order: 'a' },
      },
    },
    customDefaultFieldset: true,
    hasAccess: () => store.getters['Account/hasComponent']('contact_project_tab'),
  },
});
