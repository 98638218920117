/*
  ClientsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import ClientsTableView from "@/entities/client/views/ClientsTableView";
import Vue from "vue";
import { getClientsListsConfig } from "@/entities/client/config";
import { getClients, updateClients } from "@/api/repositories/clientsRepository";
import { requiredFields } from "@/entities/client/fields";
import { getApiClients } from "@/api/repositories/apiClientsRepository";
import { getIdsFromArray } from "@/shared/utils";
import { Client, Subscription } from "@/entities";

export class ClientsList extends EntityList {
  static entityClass = Client;

  constructor(listTypeId) {
    super(listTypeId, getClientsListsConfig);

    this.tableView = ClientsTableView;

    this.selfClass = ClientsList;
    this.getRequiredFields = requiredFields;
    this.permanentQueryData = {
      subscriptions: 1,
    };
  }

  async loadDatasets(items = []) {
    const clientIds = getIdsFromArray(items);
    const query = {
      filter: {
        client_id: {
          in: clientIds,
        },
      },
      limit: clientIds.length,
    };

    try {
      const response = await getApiClients(query);
      const apiClients = response?.data || [];

      this.updateDataset('apiClients', apiClients, 'client_id');
    } catch (e) {}
  }
}
