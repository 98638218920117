<template>
  <id-link-field
    :value="versionId"
    :route-to="{ name: 'PMClientVersions', query: { searchFilter: `id:[${versionId}]` }  }"
    class="client-version-field"
  />
</template>

<script>
import IdLinkField from "@/components/entity-fields/IdLinkField";

export default {
  name: "client-version-field",
  props: {
    versionId: { type: Number, default: null },
  },
  components: {
    IdLinkField,
  },
}
</script>

<style scoped>

</style>
