import Vue from 'vue';

export const requiredFields = () => [
  {
    key: 'id',
    label: Vue.prototype.$vDict('api_clients.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('api_clients.field_name.text'),
    sortable: true,
  },
  {
    key: 'client_id',
    label: Vue.prototype.$vDict('api_clients.field_client_id.text'),
  },
  {
    key: 'subscription_id',
    label: Vue.prototype.$vDict('api_clients.field_subscription_id.text'),
  },
  // {
  //   key: 'subscription_end_date',
  //   label: Vue.prototype.$vDict('api_clients.field_expire_at.text'),
  // },
  {
    key: 'api_client_id',
    label: Vue.prototype.$vDict('api_clients.field_api_client_id.text'),
    class: 'column-api-client-id',
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('api_clients.field_status.text'),
  },
];
