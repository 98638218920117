<template>
  <base-table-view
    class="myran-suspended-users-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(client_version_id)="data">
      <b-link
        class="text-secondary"
        :to="{ name: 'ClientVersions', query: { searchFilter: `id:[${data.value}]` }  }"
      >{{ data.value }}</b-link>
    </template>
    <template v-slot:cell(user_id)="data">
      <template v-if="data.value">
        <b-link
          v-if="hasAccessToAllUsers && !data.value.is_suspended"
          :to="{ name: 'SupportUser', params: { id: data.item.getValue('user_id') } }"
          class="text-secondary"
        >{{ data.value.name }}</b-link>
        <span v-else>{{ data.value.name }}</span>
      </template>
      <template v-else>{{ data.item.getValue('user_id') }}</template>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'myran-suspended-users-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
    hasAccessToAllUsers() {
      return this.$store.getters['Account/hasComponent']('all_users');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
