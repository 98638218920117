<template>
  <div class="user-transfer-tasks-table-view">
    <base-table-view
      class="user-transfer-tasks-table-view"
      selectable
      :items="items"
      :fields="fields"
      :sort="sort"
      :entity-list="entityList"
      :fixed-required-columns="fixedRequiredColumns"
      @change-sort="changeSort"
    >
      <template v-slot:cell(creator_id)="data">
        <field-user
          v-if="data.value"
          :user-data="data.value"
        />
      </template>
      <template v-slot:cell(from_user_id)="data">
        <field-user
          v-if="data.value"
          :user-data="data.value"
        />
      </template>
      <template v-slot:cell(to_user_id)="data">
        <field-user
          v-if="data.value"
          :user-data="data.value"
        />
      </template>
      <template v-slot:cell(stages)="data">
        <items-clamp
          :async-data="data.value"
          lines
        >
          <ul class="transfer-stages">
            <li
              v-for="(stage, stageIndex) in data.value"
              :key="stageIndex"
              class="transfer-stages__item"
            >{{ stage }}</li>
          </ul>
        </items-clamp>
      </template>
      <template v-slot:cell(task_status)="data">
        <div
          v-if="data.value"
          :class="data.value.statusTagClass"
          class="status-tag"
        >{{ data.value.name }}</div>
      </template>
      <template v-slot:cell(created_at)="data">
        <span v-html="data.value"></span>
      </template>
      <template v-slot:cell(actions)="data">
        <actions-field
          :possible-actions="possibleActions"
          :actions="data.item.getActions()"
          :is-loading="isLoadingEntityId(data.item.id)"
          @action="entityActionHandler(data.item, $event)"
        />
      </template>
    </base-table-view>

    <user-transfer-task-actions
      ref="entityActions"
      :dataset="entityList.dataset"
    />
  </div>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import FieldUser from "@/components/entity-fields/FieldUser";
import ItemsClamp from "@/components/ItemsClamp";
import ActionsField from "@/components/table/fields/ActionsField.vue";
import UserTransferTaskActions
  from "@/entities/user-transfer-task/views/UserTransferTaskActions.vue";

export default {
  name: 'user-transfer-tasks-table-view',
  components: {
    BaseTableView,
    FieldUser,
    ItemsClamp,
    UserTransferTaskActions,
    ActionsField,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.transfer-stages {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
