<template>
  <div class="email-logs-table-view">
    <base-table-view
      selectable
      :items="items"
      :fields="fields"
      :sort="sort"
      :entity-list="entityList"
      :fixed-required-columns="fixedRequiredColumns"
      @change-sort="changeSort"
    >
      <template v-slot:cell(user_id)="data">
        <field-user
          v-if="data.value"
          :user-data="data.value"
        />
      </template>
      <template v-slot:cell(sent)="data">
        <div
          v-if="data.value"
          class="status-tag"
          :class="data.value.class"
        >{{ data.value.name }}</div>
      </template>
      <template v-slot:cell(actions)="data">
        <actions-field
          :possible-actions="possibleActions"
          :actions="data.item.getActions()"
          :is-loading="isLoadingEntityId(data.item.id)"
          @action="entityActionHandler(data.item, $event)"
        />
      </template>
    </base-table-view>

    <email-log-actions
      ref="entityActions"
      :dataset="entityList.dataset"
    />
  </div>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import FieldUser from "@/components/entity-fields/FieldUser";
import EmailLogActions from "@/entities/email-log/views/EmailLogActions.vue";
import ActionsField from "@/components/table/fields/ActionsField.vue";

export default {
  name: 'email-logs-table-view',
  components: {
    BaseTableView,
    FieldUser,
    EmailLogActions,
    ActionsField,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
