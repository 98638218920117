import Vue from 'vue';

export const requiredFields = () => [
  {
    key: 'id',
    label: Vue.prototype.$vDict('country_modules.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'country_id',
    label: Vue.prototype.$vDict('country_modules.field_country_id.text'),
    class: 'column-name',
    sortable: true,
  },
  {
    key: 'modules',
    label: Vue.prototype.$vDict('country_modules.field_modules.text'),
  },
];
