/*
  FilesList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import FilesTableView from "@/entities/file/views/FilesTableView";
import Vue from "vue";
import { getFilesListsConfig } from "@/entities/file/config";
import { requiredFields } from "@/entities/file/fields";
import { File } from '@/entities';

export class FilesList extends EntityList {
  static entityClass = File;

  constructor(listTypeId) {
    super(listTypeId, getFilesListsConfig);

    this.tableView = FilesTableView;

    this.selfClass = FilesList;
    this.getRequiredFields = requiredFields;
  }
}
