import {
  LIST_TYPES,
} from "@/config/enums";
import store from "@/store";

export const getUserProfilesListsConfig = () => ({
  [LIST_TYPES.SUBSCRIPTION_USERS]: {
    defaultSort: {
      default: {
        user_id: { order: 'a' },
      },
    },
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('subscription_users_excel_export'),
    },
    hasAccess: () => store.getters['Account/hasComponent']('all_users'),
  },
});
