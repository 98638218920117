import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('vortal_connect_notif_emails.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'email',
    label: Vue.prototype.$vDict('vortal_connect_notif_emails.field_email.text'),
    sortable: true,
  },
  {
    key: 'country_id',
    label: Vue.prototype.$vDict('vortal_connect_notif_emails.field_country.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('vortal_connect_notif_emails.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('vortal_connect_notif_emails.field_modified_at.text'),
    sortable: true,
  },
  {
    key: 'created_by',
    label: Vue.prototype.$vDict('vortal_connect_notif_emails.field_created_by.text'),
    details: true,
  },
  {
    key: 'modified_by',
    label: Vue.prototype.$vDict('vortal_connect_notif_emails.field_modified_by.text'),
    details: true,
  },
];
