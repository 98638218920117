import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('release_notes.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'release_version',
    label: Vue.prototype.$vDict('release_notes.field_release_version.text'),
    class: 'column-min',
    sortable: true,
  },
  {
    key: 'title',
    label: Vue.prototype.$vDict('release_notes.field_title.text'),
    class: 'column-name',
    sortable: true,
  },
  {
    key: 'release_date',
    label: Vue.prototype.$vDict('release_notes.field_release_date.text'),
    sortable: true,
  },
  {
    key: 'enable_languages',
    label: Vue.prototype.$vDict('release_notes.field_enable_languages.text'),
  },
  {
    key: 'countries',
    label: Vue.prototype.$vDict('release_notes.field_countries.text'),
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('release_notes.field_publish_status.text'),
  },
];
