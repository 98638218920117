/*
  License class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import {
  getCountryModules,
  patchCountryModules,
  createCountryModules,
  deleteCountryModules,
  getCountryModulesItem,
} from "./api";
import { ENTITY_TYPES } from '@/config/enums';
import { Module } from "@/entities";

export class CountryModules extends BaseEntity {
  static entityKey = 'country_module';
  static datasetKey = 'countryModules';
  static entityTypeId = ENTITY_TYPES.COUNTRY_MODULES;
  static routes = {
    edit: 'SystemCountryModulesEditor',
    create: 'SystemCountryModulesNew',
  };

  // api
  static loadAllItemsFunc = getCountryModules;
  static loadItemFunc = getCountryModulesItem;
  static patchItemFunc = patchCountryModules;
  static postItemFunc = createCountryModules;
  static deleteItemFunc = deleteCountryModules;

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = CountryModules;
  }

  getModules(dataset) {
    const res = [];

    _.uniq(this.data.modules).forEach(moduleId => {
      const moduleData = this.getDatasetItem(dataset, 'modules', moduleId);

      if (moduleData) {
        res.push(new Module(moduleData));
      }
    });

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'modules':
        res = this.getModules(dataset);
        break;
      default:
    }

    return res;
  }

  getFieldValue(fieldKey) {
    let res;

    switch (fieldKey) {
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', {
          id: this.getValue('country_id'),
          prop: 'name',
        });
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        icon: 'edit',
      },
      {
        key: 'delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }
}
