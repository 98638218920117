<template>
  <div class="user-profile-actions">
    <user-actions
      ref="userActions"
    />
  </div>
</template>

<script>
import UserActions from "@/entities/user/views/UserActions.vue";

export default {
  name: 'user-profile-actions',
  components: {
    UserActions,
  },
  methods: {
    async actionHandler(userProfile, action) {
      if (action.type === 'user') {
        const { user } = userProfile;

        await this.$refs.userActions.actionHandler(user, action);
        return;
      }

      await userProfile.doAction(action);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
