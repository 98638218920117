import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'service_name',
    label: Vue.prototype.$vDict('error_messages.field_service_name.text'),
    class: 'column-service_name',
    sortable: true,
  },
  {
    key: 'error_code',
    label: Vue.prototype.$vDict('error_messages.field_error_code.text'),
    class: 'column-error_code',
  },
  {
    key: 'error_message',
    label: Vue.prototype.$vDict('error_messages.field_error_message.text'),
    class: 'column-error_message',
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('error_messages.field_created_at.text'),
    class: 'column-created_at',
    sortable: true,
  },
  {
    key: 'jwt',
    label: Vue.prototype.$vDict('error_messages.field_jwt.text'),
    class: 'column-jwt',
    details: true,
  },
  {
    key: 'jwt_type',
    label: Vue.prototype.$vDict('error_messages.field_jwt_type.text'),
    class: 'column-jwt_type',
    details: true,
  },
  {
    key: 'jwt_sub',
    label: Vue.prototype.$vDict('error_messages.field_jwt_sub.text'),
    class: 'column-jwt_sub',
    details: true,
  },
  {
    key: 'data',
    label: Vue.prototype.$vDict('error_messages.field_data.text'),
    class: 'column-data',
    details: true,
  },
  {
    key: 'error_context',
    label: Vue.prototype.$vDict('error_messages.field_error_context.text'),
    class: 'column-error_context',
    details: true,
  },
  {
    key: 'datetime',
    label: Vue.prototype.$vDict('error_messages.field_datetime.text'),
    class: 'column-datetime',
    details: true,
  },
];
