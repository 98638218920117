/*
  File class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import {
  getWebhooks,
  patchWebhook,
  getWebhook,
  postWebhookSecretKey,
  putWebhook,
  postWebhook,
} from '@/api/repositories/webhookRepository';
import { formatDateTimeNoWrap, getExternalLink } from "@/shared/utils";
import { Client } from "@/entities";

export class Webhook extends BaseEntity {
  static entityKey = 'webhook';
  static datasetKey = 'webhooks';
  static entityTypeId = ENTITY_TYPES.WEBHOOK;
  static routes = {
    list: 'SupportWebhooks',
    view: 'SupportWebhook',
    edit: 'SupportEditorWebhook',
    create: 'SupportEditorWebhookNew',
  };

  // api
  static loadAllItemsFunc = getWebhooks;
  static loadItemFunc = getWebhook;
  static patchItemFunc = patchWebhook;
  static putItemFunc = putWebhook;
  static postItemFunc = postWebhook;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('webhooks.plural_webhooks_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = Webhook;
  }

  static getAllActions() {
    return [
      {
        key: 'view',
        name: Vue.prototype.$vDict('webhooks.view_button.text'),
        icon: 'pwd-show',
      },
      {
        key: 'edit',
        name: Vue.prototype.$vDict('webhooks.edit_button.text'),
        icon: 'edit',
      },
      {
        class: 'text-secondary',
        key: 'generate-new-secret',
        dataCy: 'generate-new-secret',
        name: Vue.prototype.$vDict('webhooks.generate_secret_button.text'),
        icon: 'key',
      },
      {
        type: 'divider',
      },
      {
        key: 'activate',
        icon: 'power',
        name: Vue.prototype.$vDict('webhooks.activate_button.text'),
        linkClass: 'action-link--secondary',
      },
      {
        linkClass: 'action-link--warning',
        key: 'deactivate',
        icon: 'deactivated',
        name: Vue.prototype.$vDict('webhooks.deactivate_button.text'),
      },
      {
        linkClass: 'action-link--warning',
        key: 'unblock',
        icon: 'power',
        name: Vue.prototype.$vDict('webhooks.unblock_button.text'),
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'endpoint_url':
        res = getExternalLink(fieldValue);
        break;
      case 'created_at':
      case 'modified_at':
        res = fieldValue ? formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'webhook_events':
        res = this.getEventsTypes().map(el => el.name).join(', ');
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;
    let value;

    switch (fieldKey) {
      case 'status':
        res = this.getStatus();
        break;
      case 'client_name':
        value = this.getClient(dataset);
        res = value?.name || '';
        break;
      default:
    }

    return res;
  }

  getEventsTypes() {
    const events = this.getValue('webhook_events', []);
    const eventTypes = Vue.prototype.$getEnumsList('WEBHOOK_EVENT_TYPES');

    return eventTypes.filter(type => events.some(event => event.webhook_event_type === type.id));
  }

  getClient(dataset = {}) {
    return this.getDatasetItem(dataset, Client.datasetKey, this.getValue('client_id'));
  }

  getStatus() {
    let res;

    if (this.getValue('blocked')) {
      res = {
        class: 'status-tag--suspended',
        name: Vue.prototype.$vDict('webhooks.status_blocked.text'),
      };
    } else if (this.getValue('activated')) {
      res = {
        class: 'status-tag--active',
        name: Vue.prototype.$vDict('webhooks.status_active.text'),
      };
    } else {
      res = {
        class: 'status-tag--expired',
        name: Vue.prototype.$vDict('webhooks.status_disabled.text'),
      };
    }

    return res;
  }

  getActions() {
    const actions = this.selfClass.getAllActions();
    return actions.filter(action => {
      let res = true;

      switch (action.key) {
        case 'activate':
          res = !this.getValue('activated');
          break;
        case 'deactivate':
          res = this.getValue('activated');
          break;
        case 'unblock':
          res = this.getValue('activated') && this.getValue('blocked');
          break;
        default:
      }

      return res;
    });
  }

  async changeActiveValue(activated = 1) {
    let response;

    try {
      response = await patchWebhook(this.id, { activated });

      if (response) {
        this.setValue('activated', activated);
      }
    } catch (error) {}
  }

  async changeBlockedValue(blocked = 0) {
    let response;

    try {
      response = await patchWebhook(this.id, { blocked });

      if (response) {
        this.setValue('blocked', blocked);
      }
    } catch (error) {}
  }

  async generateNewSecretKey() {
    try {
      const response = await postWebhookSecretKey(this.id);
      const secretKey = response?.data?.secret_key;

      if (secretKey) {
        this.setValue('secret_key', secretKey);
        Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('webhooks.text_secret_key_updated_success.text', {
          key: secretKey,
        }));
      }
    } catch (error) {}
  }

  async doAction(action) {
    switch (action.key) {
      case 'activate':
        await this.changeActiveValue(1);
        break;
      case 'deactivate':
        await this.changeActiveValue(0);
        break;
      case 'unblock':
        await this.changeBlockedValue(0);
        break;
      case 'generate-new-secret':
        await this.generateNewSecretKey();
        break;
      default:
        await super.doAction(action);
    }
  }
}
