import enums from "@/config/enums";
import store from "@/store";

export const getUsersListsConfig = () => ({
  [enums.LIST_TYPES.ALL_USERS]: {
    isMain: true,
    isSavePosition: true,
    defaultSearchFilter: {
      default: {
        'user_profile.is_suspended': 0,
        'subscription_status': 'at_least_one_active',
      },
    },
    defaultSort: {
      default: {
        id: { order: 'a' },
      },
    },
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('all_users_excel_export'),
    },
    affectTotalFilterKeys: {
      default: ['suspended'],
    },
    hasAccess: () => store.getters['Account/hasComponent']('all_users'),
  },
});
