import Vue from 'vue';

export const requiredFields = () => [
  {
    key: 'id',
    label: 'ID',
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'name',
    label: 'Name',
    class: 'column-name',
    sortable: true,
  },
  {
    key: 'modules',
    label: 'Module',
  },
];
