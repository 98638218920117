import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'pm_client_version_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
  },
  {
    key: 'pm_custom_field_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_custom_field_id.text'),
  },
  {
    key: 'display_name',
    label: Vue.prototype.$vDict('parabyg_migration.field_display_name.text'),
  },
  {
    key: 'hint',
    label: Vue.prototype.$vDict('parabyg_migration.field_hint.text'),
  },
  {
    key: 'value_type',
    label: Vue.prototype.$vDict('parabyg_migration.field_value_type.text'),
  },
  {
    key: 'value_as_string',
    label: Vue.prototype.$vDict('parabyg_migration.field_value_as_string.text'),
  },
  {
    key: 'is_valid',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
  },
  {
    key: 'is_migrated',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_migrated.text'),
  },
  {
    key: 'pm_entity_type_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_entity_type_id.text'),
    details: true,
  },
  {
    key: 'pm_entity_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_entity_id.text'),
    details: true,
  },
  {
    key: 'entity_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_smart_entity_id.text'),
    details: true,
  },
  {
    key: 'custom_field_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_custom_field_id.text'),
    details: true,
  },
  {
    key: 'custom_field_selection_keys',
    label: Vue.prototype.$vDict('parabyg_migration.field_custom_field_selection_keys.text'),
    details: true,
  },
  {
    key: 'reference_source',
    label: Vue.prototype.$vDict('parabyg_migration.field_reference_source.text'),
    details: true,
  },
  {
    key: 'reference_as_string',
    label: Vue.prototype.$vDict('parabyg_migration.field_reference_as_string.text'),
    details: true,
  },
  {
    key: 'validation_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_validation_error_message.text'),
    details: true,
  },
  {
    key: 'migration_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_migration_error_message.text'),
    details: true,
  },
];
