/*
  ActivitiesList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import { requiredFields } from "@/entities/activity/fields";
import { getActivitiesListsConfig } from "@/entities/activity/config";
import {
  Project,
  Company,
  Contact,
  Tender,
  Activity,
} from "@/entities";

const ActivitiesTableView = () => import('./views/ActivitiesTableView');

export class ActivitiesList extends EntityList {
  static entityClass = Activity;

  constructor(listTypeId) {
    super(listTypeId, getActivitiesListsConfig);

    this.tableView = ActivitiesTableView;
    this.selfClass = ActivitiesList;
    this.getRequiredFields = requiredFields;
  }

  loadDatasets(items = []) {
    const loadEntitiesData = [
      {
        entityClass: Project,
        queryParams: {
          fields: ['id', 'title', 'subtitle', 'source_id', 'deleted'],
          show_locked: 1,
        },
      },
      {
        entityClass: Company,
        queryParams: {
          fields: ['id', 'name'],
          show_locked: 1,
        },
      },
      {
        entityClass: Contact,
        queryParams: {
          fields: ['id', 'first_name', 'last_name'],
          show_locked: 1,
        },
      },
      {
        entityClass: Tender,
        queryParams: {
          fields: ['id', 'name'],
          show_locked: 1,
        },
      },
    ];

    this.loadEntitiesDatasets(loadEntitiesData, items);
  }
}
