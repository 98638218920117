/*
  ContactsList class
 */

import Vue from "vue";
import CRMEntityList from "@/shared/classes/list/CRMEntityList";
import {
  LIST_TYPES,
} from "@/config/enums";
import { getContacts } from "@/api/repositories/leadsRepository";
import {
  deleteContact,
  getContacts as getUserContacts
} from "@/api/repositories/salesRepository";
import { requiredFields, fieldsSections } from "@/entities/contact/fields";
import { getContactsListsConfig } from "@/entities/contact/config";
import store from "@/store";
import {
  Contact,
  Company,
} from "@/entities";

const ContactsTableView = () => import('./views/ContactsTableView');
const ContactCardView = () => import('./views/ContactCardView');

export class BaseContactsList extends CRMEntityList {
  static entityClass = Contact;
  static isStaticNavigation = true;

  constructor(listTypeId) {
    super(listTypeId, getContactsListsConfig);

    this.filterKeysWithScore = ['keywords'];
    this.printFieldsBlacklist = [
      'status',
      'notes',
      'tags',
      'email',
    ];

    // view components
    this.tableView = ContactsTableView;
    this.cardView = ContactCardView;

    //
    this.selfClass = BaseContactsList;
    this.getRequiredFields = requiredFields;
    this.getFieldsSections = fieldsSections;
  }

  getEmptyListTitle() {
    return this.isFiltersFilled ?
      Vue.prototype.$vDict('entity_list.contacts_list_empty_by_search.text') :
      Vue.prototype.$vDict('entity_list.contacts_list_empty.text');
  }

  async loadItemsStatuses(items = []) {
    if (this.selfClass.isUserSection) {
      return;
    }

    const { statusKey, loadUserItemsFunc } = this.selfClass.entityClass;

    const itemsIds = items.map(el => el.id);
    const query = {
      limit: itemsIds.length,
      fields: ['id'],
      filter: {
        id: {
          in: itemsIds,
        },
      },
    };

    try {
      const response = await loadUserItemsFunc(query);
      const contacts = response?.data || [];

      contacts.forEach(contact => {
        const entity = items.findById(contact.id);

        if (entity) {
          entity.setValue(statusKey, true);
        }
      });
    } catch (error) {}
  }

  loadDatasetByQueryFields(items = [], queryFields = []) {
    if (queryFields.includes('original_companies')) {
      this.loadDatasetEntities(Company, {
        ids: items.reduce((acum, item) => _.union(acum, item.original_companies), []),
        fields: ['id', 'name', 'contact_data'],
      });
    }

    if (queryFields.includes('users')) {
      store.dispatch('UserClient/fetchUsers');
    }
  }

  async bulkDelete(ids = []) {
    const { entityClass } = this.selfClass;
    const { idsKey } = entityClass;

    if (!entityClass.deleteItemsFunc ||
      !idsKey) {
      return;
    }

    return await entityClass.deleteItemsFunc({
      [idsKey]: ids,
    });
  }

  async bulkAdd(ids = []) {
    const { entityClass } = this.selfClass;
    const { idsKey } = entityClass;

    if (!entityClass.putItemsFunc ||
      !idsKey) {
      return;
    }

    return await entityClass.putItemsFunc({
      [idsKey]: ids,
    });
  }

  async doBulkAction(operationKey, payload = {}) {
    if (!this.selectedItems.length) {
      return;
    }

    switch (operationKey) {
      case 'add':
        await this.bulkAdd(this.selectedItems);
        break;
      default:
        await super.doBulkAction(operationKey, payload);
    }
  }
}
