/*
  PMClient class
 */

import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getPMClients, postPMClient } from "@/api/repositories/parabygMigrationRepository";
import { Client } from "@/entities";
import { formatDateTime } from "@/shared/utils";
import Vue from "vue";

const defaultData = () => ({
  id: null,
  hint: '',
  client_id: null,
  created_at: null,
});

export class PMClient extends BaseEntity {
  static entityKey = 'pm_client';
  static datasetKey = 'pmClients';
  static loadAllItemsFunc = getPMClients;
  static postItemFunc = postPMClient;
  static routes = {
    list: 'PMClients',
    create: 'PMClientCreate',
  };
  static entityTypeId = ENTITY_TYPES.PM_CLIENT;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('parabyg_migration.clients_plural_text.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    this.selfClass = PMClient;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'client_id':
        res = this.getClient(dataset);
        break;
      default:
    }

    return res;
  }

  getClient(dataset = {}) {
    return this.getDatasetItem(dataset, Client.datasetKey, this.getValue('client_id'));
  }

  getPostData(formData) {
    return {
      hint: formData.hint,
      client_id: formData.client_id,
    };
  }
}
