/*
  DeadMessageSubscription class
 */

import BaseEntity from '@/shared/classes/entity/BaseEntity';
import {
  ENTITY_TYPES,
} from '@/config/enums';
import {
  deleteDeadMessageSubscription,
  getDeadMessagesSubscriptions,
  patchDeadMessageSubscription,
  getDeadMessageSubscription,
} from "@/api/repositories/dictionariesRepository";
import Vue from "vue";

export class DeadMessageSubscription extends BaseEntity {
  static entityKey = 'dead_message_subscription';
  static datasetKey = 'deadMessageSubscriptions';
  static entityTypeId = ENTITY_TYPES.DEAD_MESSAGE_SUBSCRIPTION;
  static routes = {
    edit: 'SystemDeadMessageSubscriptionEditor',
    list: 'SystemDeadMessageSubscriptions',
  };

  // api
  static loadAllItemsFunc = getDeadMessagesSubscriptions;
  static loadItemFunc = getDeadMessageSubscription;
  static patchItemFunc = patchDeadMessageSubscription;
  static deleteItemFunc = deleteDeadMessageSubscription;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('dead_message_subscriptions.plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = DeadMessageSubscription;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }
}
