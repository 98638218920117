import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'pm_client_version_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
  },
  {
    key: 'display_name',
    label: Vue.prototype.$vDict('parabyg_migration.field_display_name.text'),
  },
  {
    key: 'hint',
    label: Vue.prototype.$vDict('parabyg_migration.field_hint.text'),
  },
  {
    key: 'value_type',
    label: Vue.prototype.$vDict('parabyg_migration.field_value_type.text'),
  },
  {
    key: 'custom_field_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_custom_field_id.text'),
  },
  {
    key: 'is_valid',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
  },
  {
    key: 'validation_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_validation_error_message.text'),
    details: true,
  },
  {
    key: 'used_in_projects',
    label: Vue.prototype.$vDict('parabyg_migration.field_used_in_projects.text'),
    details: true,
  },
  {
    key: 'used_in_companies',
    label: Vue.prototype.$vDict('parabyg_migration.field_used_in_companies.text'),
    details: true,
  },
  {
    key: 'used_in_contacts',
    label: Vue.prototype.$vDict('parabyg_migration.field_used_in_contacts.text'),
    details: true,
  },
  {
    key: 'pm_custom_field_selections',
    label: Vue.prototype.$vDict('parabyg_migration.custom_field_selections_title.text'),
    details: true,
  },
  {
    key: 'pm_custom_field_employees',
    label: Vue.prototype.$vDict('parabyg_migration.custom_field_employees_title.text'),
    details: true,
  },
  {
    key: 'pm_custom_field_values',
    label: Vue.prototype.$vDict('parabyg_migration.custom_field_values_title.text'),
    details: true,
  },
];
