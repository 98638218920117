/*
  PMActivitiesList class
 */

import Vue from 'vue';
import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import ActivitiesTableView from "@/entities/parabyg-migration/pm-activity/views/ActivitiesTableView";
import { getPMActivityListsConfig } from "@/entities/parabyg-migration/pm-activity/config";
import { getPMActivities } from "@/api/repositories/parabygMigrationRepository";
import { requiredFields } from "@/entities/parabyg-migration/pm-activity/fields";
import { User } from "@/entities";
import { PMActivity } from "@/entities";

export class PMActivitiesList extends EntityList {
  static entityClass = PMActivity;

  constructor(listTypeId) {
    super(listTypeId, getPMActivityListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = ActivitiesTableView;
    this.settingsForLoadingDatasets = [
      {
        datasetKey: User.datasetKey,
        loadFunc: User.loadAllItemsFunc,
        fieldKeys: ['user_id'],
      },
    ];

    this.selfClass = PMActivitiesList;
  }

  getListFiltersSetting() {
    const rbOptions = [
      { id: 0, name: Vue.prototype.$vDict('global.text_no.text') },
      { id: 1, name: Vue.prototype.$vDict('global.text_yes.text') },
      { id: -1, name: Vue.prototype.$vDict('parabyg_migration.filter_option_for_null_value.text') }
    ];

    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('entity_list.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'pm_client_version_id',
        dataCy: 'pm-client-version-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'source_id',
        dataCy: 'source-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_source_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'is_valid',
        dataCy: 'is-valid',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
      {
        key: 'is_migrated',
        dataCy: 'is-migrated',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_migrated.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
    ];
  }
}
