<template>
  <div class="client-actions">
    <create-user-modal
      ref="createUserModal"
    />
  </div>
</template>

<script>
import CreateUserModal from "@/entities/user/views/CreateUserModal";

export default {
  name: 'client-actions',
  components: {
    CreateUserModal,
  },
  methods: {
    async actionHandler(client, action) {
      switch (action.key) {
        case 'add_user':
        case 'create_user':
          await this.$refs.createUserModal.show({
            client,
          });
          break;
        default:
          await client.doAction(action);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
