/*
  PMCustomFieldsList class
 */

import Vue from 'vue';
import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  VALUE_TYPES,
} from "@/config/enums";
import CustomFieldsTableView from "@/entities/parabyg-migration/pm-custom-field/views/CustomFieldsTableView";
import { getPMCustomFieldListsConfig} from "@/entities/parabyg-migration/pm-custom-field/config";
import {
  getPMCustomFields,
  getPMCustomFieldsAggregations
} from "@/api/repositories/parabygMigrationRepository";
import { requiredFields } from "@/entities/parabyg-migration/pm-custom-field/fields";
import { PMCustomField } from "@/entities";

// import { Client } from "@/entities";

export class PMCustomFieldsList extends EntityList {
  static entityClass = PMCustomField;

  constructor(listTypeId) {
    super(listTypeId, getPMCustomFieldListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = CustomFieldsTableView;
    // this.settingsForLoadingDatasets = [
    //   {
    //     datasetKey: Client.datasetKey,
    //     loadFunc: Client.loadAllItemsFunc,
    //     fieldKeys: ['client_id'],
    //   },
    // ];

    this.selfClass = PMCustomFieldsList;
  }

  async loadDatasets(items = []) {
    const hintsByVersion = items.reduce((acum, item) => {
      const hints = acum[item.pm_client_version_id] || [];
      return {
        ...acum,
        [item.pm_client_version_id]: hints.indexOf(item.hint) !== -1 ? hints : [...hints, item.hint],
      };
    }, {});

    const requests = Object.entries(hintsByVersion).map(([pm_client_version_id, hints]) => {
      return getPMCustomFieldsAggregations({
        filter: {
          pm_client_version_id: {
            eq: Number(pm_client_version_id),
          },
          hint: {
            in: hints,
          },
        },
      });
    });
    let aggregations = [];

    try {
      const responses = await Promise.all(requests);
      aggregations = _.flatten(responses).map(agg => ({ ...agg, id: `${agg.pm_client_version_id}.${agg.hint}` }));
    } catch (e) {}

    this.updateDataset('aggregations', aggregations);
  }

  getListFiltersSetting() {
    const rbOptions = [
      { id: 0, name: Vue.prototype.$vDict('global.text_no.text') },
      { id: 1, name: Vue.prototype.$vDict('global.text_yes.text') },
      { id: -1, name: Vue.prototype.$vDict('parabyg_migration.filter_option_for_null_value.text') }
    ];

    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('entity_list.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'pm_client_version_id',
        dataCy: 'pm-client-version-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'value_type',
        dataCy: 'value-type',
        name: Vue.prototype.$vDict('parabyg_migration.field_value_type.text'),
        // filterType: SEARCH_FILTER_TYPES.INPUT,
        // filterAttrs: {
        //   placeholder: Vue.prototype.$vDict('parabyg_migration.field_value_type_input_placeholder.text'),
        // },
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: Object.keys(VALUE_TYPES).map(key => ({ id: VALUE_TYPES[key], name: VALUE_TYPES[key] })),
        },
      },
      {
        key: 'hint',
        dataCy: 'hint',
        name: Vue.prototype.$vDict('parabyg_migration.field_hint.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_hint_input_placeholder.text'),
        },
      },
      {
        key: 'custom_field_id',
        dataCy: 'custom-field-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_custom_field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'is_valid',
        dataCy: 'is-valid',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
    ];
  }
}
