import Vue from 'vue';
import { LIST_TYPES } from "@/config/enums";

export const requiredFields = () => [
  {
    key: 'id',
    label: Vue.prototype.$vDict('webhooks.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'client_name',
    label: Vue.prototype.$vDict('webhooks.field_client_name.text'),
    listTypeIds: [LIST_TYPES.ALL_WEBHOOKS],
  },
  // {
  //   key: 'client_activated',
  //   label: Vue.prototype.$vDict('webhooks.field_client_activated.text'),
  //   listTypeIds: [LIST_TYPES.ALL_WEBHOOKS],
  // },
  {
    key: 'endpoint_url',
    label: Vue.prototype.$vDict('webhooks.field_endpoint_url.text'),
    class: 'column-url',
  },
  {
    key: 'description',
    label: Vue.prototype.$vDict('webhooks.field_description.text'),
  },
  {
    key: 'webhook_version_name',
    label: Vue.prototype.$vDict('webhooks.field_webhook_version_name.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('webhooks.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('webhooks.field_modified_at.text'),
    sortable: true,
  },
  {
    key: 'webhook_events',
    label: Vue.prototype.$vDict('webhooks.field_webhook_events.text'),
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('webhooks.field_status.text'),
  },
];
