<template>
  <base-table-view
    class="files-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    :tbody-tr-class="tbodyTrClass"
    @change-sort="changeSort"
  >
    <template v-slot:cell(subscription_version_id)="data">
      <b-link
        class="text-secondary"
        :to="{ name: 'SubscriptionVersions', query: { id: data.value } }"
      >{{ data.value }}</b-link>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'files-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
  methods: {
    tbodyTrClass(entity) {
      return !entity.getValue('valid') ? 'row-danger' : '';
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
