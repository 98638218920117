import enums from "@/config/enums";
import store from '@/store';

export const getPMProjectsListsConfig = () => ({
  [enums.LIST_TYPES.ALL_PM_PROJECTS]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
});
