<template>
  <div class="subscription-name">
    <div class="subscription-name__name">
      <b-link
        class="subscription-name__link entity-link"
        :to="linkTo"
      >{{ name }}</b-link>
    </div>

    <entity-status-tag
      v-if="isShowStatusTag"
      class="subscription-name__status-tag"
      :variant="statusParams.variant"
      :name="statusParams.name"
    />
  </div>
</template>

<script>
import EntityStatusTag from "@/components/entity/EntityStatusTag.vue";

export default {
  name: 'subscription-name',
  props: {
    subscription: { type: Object, default: Object },
  },
  components: {
    EntityStatusTag,
  },
  computed: {
    name() {
      return this.subscription.getName();
    },
    linkTo() {
      return this.subscription.getLinkTo();
    },
    statusParams() {
      return this.subscription.getSubscriptionStatus();
    },
    isShowStatusTag() {
      return !!this.statusParams.variant &&
        this.statusParams.variant !== 'active';
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-name {
  display: flex;
  align-items: center;

  &__name {
    margin-right: 4px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
