import enums from "@/config/enums";

export const getApiClientsListsConfig = () => ({
  [enums.LIST_TYPES.ALL_API_CLIENTS]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'a' },
      },
    },
  },
});
