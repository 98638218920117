<template>
  <div class="dead-messages-table-view">
    <base-table-view
      selectable
      :items="items"
      :fields="fields"
      :sort="sort"
      :entity-list="entityList"
      :fixed-required-columns="fixedRequiredColumns"
      @change-sort="changeSort"
    >
      <template v-slot:cell(actions)="data">
        <actions-field
          :possible-actions="possibleActions"
          :actions="data.item.getActions(entityList.dataset)"
          :is-loading="isLoadingEntityId(data.item.id)"
          @action="entityActionHandler(data.item, $event)"
        />
      </template>
    </base-table-view>

    <dead-message-actions
      ref="entityActions"
    />
  </div>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import DeadMessageActions from "@/entities/dead-message/views/DeadMessageActions.vue";
import ActionsField from "@/components/table/fields/ActionsField.vue";

export default {
  name: 'dead-messages-table-view',
  components: {
    BaseTableView,
    DeadMessageActions,
    ActionsField,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.dead-messages-table-view {
  &::v-deep .column-service-name {
    white-space: nowrap;
  }
}
</style>
