import enums from "@/config/enums";
import store from '@/store';

export const getFilesListsConfig = () => ({
  [enums.LIST_TYPES.USER_FILES]: {
    isMain: true,
    isSavePosition: true,
    onlyOneSort: true,
    defaultSort: {
      default: {
        created_at: { order: 'd' },
      },
    },
    defaultSearchFilter: {
      default: {
        user_id: [store.getters['Account/userId']],
      },
    },
  },
  [enums.LIST_TYPES.ALL_FILES]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        created_at: { order: 'd' },
      },
    },
  },
});
