/*
  ApiClientsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import ApiClientsTableView from "@/entities/api-client/views/ApiClientsTableView";
import Vue from "vue";
import { getApiClientsListsConfig } from "@/entities/api-client/config";
import { requiredFields } from "@/entities/api-client/fields";
import { Client, ApiClient } from "@/entities";

export class ApiClientsList extends EntityList {
  static entityClass = ApiClient;

  constructor(listTypeId) {
    super(listTypeId, getApiClientsListsConfig);

    this.tableView = ApiClientsTableView;
    this.settingsForLoadingDatasets = [
      {
        datasetKey: Client.datasetKey,
        loadFunc: Client.loadAllItemsFunc,
        fieldKeys: ['client_id'],
      },
    ];

    this.selfClass = ApiClientsList;
    this.getRequiredFields = requiredFields;
  }
}
