import Vue from 'vue';
import { TABLE_FIELD_TYPES } from "@/config/enums";

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('dead_messages.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'service_name',
    label: Vue.prototype.$vDict('dead_messages.field_service_name.text'),
    class: 'column-service-name',
    sortable: true,
  },
  {
    key: 'topic',
    label: Vue.prototype.$vDict('dead_messages.field_topic.text'),
    sortable: true,
  },
  {
    key: 'schema_name',
    label: Vue.prototype.$vDict('dead_messages.field_schema_name.text'),
    sortable: true,
  },
  {
    key: 'idempotency_key',
    label: Vue.prototype.$vDict('dead_messages.field_idempotency_key.text'),
    details: true,
  },
  {
    key: 'request_id',
    label: Vue.prototype.$vDict('dead_messages.field_request_id.text'),
    details: true,
  },
  {
    key: 'reason',
    label: Vue.prototype.$vDict('dead_messages.field_reason.text'),
  },
  {
    key: 'failed_at',
    label: Vue.prototype.$vDict('dead_messages.field_failed_at.text'),
    sortable: true,
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('dead_messages.field_created_at.text'),
    sortable: true,
    details: true,
  },
  {
    key: 'resent_at',
    label: Vue.prototype.$vDict('dead_messages.field_resent_at.text'),
    sortable: true,
  },
  {
    key: 'payload',
    label: Vue.prototype.$vDict('dead_messages.field_payload.text'),
    details: true,
    type: TABLE_FIELD_TYPES.JSON,
  },
];
