import enums from "@/config/enums";

export const getCustomFieldsetListConfig = () => ({
  [enums.LIST_TYPES.ALL_CUSTOM_FIELDSET_TEMPLATES]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
});
