<template>
  <base-table-view
    class="webhooks-queue-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(endpoint_url)="data">
      <copied-text
        :text="data.value"
      />
    </template>
    <template v-slot:cell(client_id)="data">
      <b-link
        v-if="data.value"
        :to="{ name: 'SupportClient', params: { id: data.item.getValue('client_id') } }"
      >{{ data.value.name }}</b-link>
    </template>
    <template v-slot:cell(webhook_id)="data">
      <b-link
        v-if="hasWebhooksPermissions && data.value"
        :to="{ name: 'SupportWebhook', params: { id: data.item.getValue('webhook_id') } }"
      >{{ data.value.description }}</b-link>
      <b-link
        v-else-if="data.value"
        :to="{ name: 'SettingsWebhook', params: { id: data.item.getValue('webhook_id') } }"
      >{{ data.value.description }}</b-link>
    </template>
    <template v-slot:cell(secret_key)="data">
      <copied-text
        :text="data.value"
      />
    </template>
    <template v-slot:cell(status)="data">
      <div
        v-if="data.value"
        class="status-tag"
        :class="data.value.statusTagClass"
      >{{ data.value.name }}</div>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import CopiedText from "@/components/CopiedText";

export default {
  name: 'webhooks-queue-table-view',
  components: {
    BaseTableView,
    CopiedText,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
    hasWebhooksPermissions() {
      return this.$store.getters['Account/hasComponent']('webhook_support');
    },
  },
};
</script>

<style lang="scss" scoped>
.webhooks-queue-table-view {
  &::v-deep {
    .column-url {
      min-width: 250px;
    }
  }
}
</style>
