// @vue/component
import Vue from "vue";
import MixinFormValidate from '@/mixins/MixinFormValidate';
import { getIdsFromArray } from "@/shared/utils";
import { required } from "vuelidate/lib/validators";
import { vNotEmpty } from "@/shared/validates";
import { SmartSelection } from "@/entities/smart-selection/SmartSelection";

export const smartSelectionFormMixin = Vue.extend({
  mixins: [
    MixinFormValidate,
  ],
  props: {
    selection: { type: Object, default: Object },
    locations: { type: Array, default: Array },
  },
  data() {
    return {
      formItems: {
        categories: [],
        development_types: [],
        planning_stages: [],
        roles: [],
        tender_roles: [],
      },
    };
  },
  computed: {
    isEditMode() {
      return !!this.formData.id;
    },
    locationsMapByCountries() {
      return this.locations.reduce((acum, location) => {
        return {
          ...acum,
          [location.country_id]: getIdsFromArray(location.items),
        };
      }, {});
    },
    countryIds() {
      const res = [];

      Object.entries(this.locationsMapByCountries).forEach(([countryId, locationIds]) => {
        const hasIntersection = _.intersection(this.formData.administrative_units, locationIds).length > 0;

        if (hasIntersection) {
          res.push(Number(countryId));
        }
      });

      return res;
    },
    formErrors() {
      return {
        formData: {
          administrative_units: {
            required: this.$vDict('forms.error_field_is_required.text', {
              fieldName: this.$vDict('smart_selections.field_administrative_units.text'),
            }),
          },
        },
      };
    },
    budgetSize() {
      const max = this.formData.value_max === 0 ? vars.CONST_MAX_BUDGET_LIMIT : this.formData.value_max || 0;
      const min = this.formData.value_min || 0;
      return min || max ? [min, max] : null;
    },
  },
  validations: {
    formData: {
      administrative_units: {
        required,
        vNotEmpty,
      },
    },
  },
  watch: {
    countryIds: {
      handler(val, oldValue) {
        if (!val) {
          return;
        }

        this.formData.countries = val;
        this.formItems = this.getFormItems(val);

        if (!oldValue) {
          return;
        }

        if (oldValue.length === 0 || oldValue.length > val.length) {
          this.recalculateFormData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChangeBudgetSize(budgetSize) {
      this.formData.value_min = budgetSize[0] || 0;
      this.formData.value_max = budgetSize[1] && budgetSize[1] !== vars.CONST_MAX_BUDGET_LIMIT ? budgetSize[1] : 0;
    },
    getFormItems(countries = []) {
      const lookupOptions = {
        all: true,
        countries,
      };

      return {
        categories: this.$lDict('client.project_categories', lookupOptions),
        development_types: this.$lDict('client.development_types', lookupOptions),
        planning_stages: this.$lDict('client.planning_stages', lookupOptions),
        roles: this.$lGetCompanyStageRoles(lookupOptions),
        tender_roles: this.$lGetCompanyStageRoles({
          ...lookupOptions,
          rolesLookupKey: 'client.company_role_tender',
        }),
      };
    },
    recalculateFormData() {
      const formItemIds = {};

      Object.entries(this.formData).forEach(([valueKey, value]) => {
        let itemsKey;

        switch (valueKey) {
          case 'event_roles':
          case 'view_roles':
            itemsKey = 'roles';
            break;
          case 'tender_event_roles':
          case 'tender_view_roles':
            itemsKey = 'tender_roles';
            break;
          case 'categories':
          case 'development_types':
          case 'planning_stages':
            itemsKey = valueKey;
            break;
          default:
        }

        if (itemsKey && Array.isArray(value)) {
          this.formData[valueKey] = this.recalculateFormFieldData(formItemIds, itemsKey, value);
        }
      });
    },
    recalculateFormFieldData(formItemIds = {}, itemsKey = '', value = []) {
      const items = this.formItems[itemsKey] || [];

      if (items.length === 0) {
        return [];
      }

      if (!formItemIds.hasOwnProperty(itemsKey)) {
        formItemIds[itemsKey] = getIdsFromArray(this.formItems[itemsKey]);
      }

      return value.filter(itemId => formItemIds[itemsKey].includes(itemId));
    },
    getFieldLabel(label, valueKey = null) {
      const formValues = this.formData[valueKey] || [];
      let numberText = formValues.length;

      if (formValues.length === 0) {
        numberText = this.$vDict('smart_selections.selection_filter_access_any.text');
      }

      return `${label} (${numberText})`;
    },
    getResultSelection() {
      const selectionData = SmartSelection.convertFormToData(this.formData, this.locations);
      return new SmartSelection(selectionData, this.locations);
    },
  },
});
