/*
  UserTransferTask class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import {
  getTransferTasks,
  postTransferTask,
} from "@/api/repositories/userTransferTasksRepository";
import { formatDateTime } from "@/shared/utils";

export class UserTransferTask extends BaseEntity {
  static entityKey = 'userTransferTask';
  static datasetKey = 'userTransferTasks';
  static entityTypeId = ENTITY_TYPES.USER_TRANSFER_TASK;
  static routes = {
    create: 'SupportEditorUserTransferTaskNew',
    list: 'SupportUserTransferTasks',
  };

  // api
  static loadAllItemsFunc = getTransferTasks;
  static postItemFunc = postTransferTask;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('user_transfer_tasks.user_transfer_tasks_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = UserTransferTask;
  }

  static getAllActions() {
    return [
      {
        key: 'view_stages',
        dataCy: 'view-stages',
        icon: 'popup',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'created_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'operation_id':
        value = this.getOperation();
        res = value?.name || '';
        break;
      case 'stages':
        res = this.getStages();
        break;
      case 'task_status':
        res = this.getTaskStatus();
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'creator_id':
      case 'from_user_id':
      case 'to_user_id':
        res = this.getUser(dataset, fieldKey);
        break;
      default:
    }

    return res;
  }

  getOperation() {
    return this.getItemFromEnum('USER_TRANSFER_OPERATIONS', this.getValue('operation_id'))
  }

  getTaskStatus() {
    return this.getItemFromEnum('USER_TRANSFER_TASK_STATUSES', this.getValue('task_status'))
  }

  getStages() {
    const stages = this.getValue('stages');
    const enumStages = Vue.prototype.$getEnumsList('USER_TRANSFER_STAGES');
    const enumStatuses = Vue.prototype.$getEnumsList('USER_TRANSFER_TASK_STATUSES');
    const res = [];

    stages.forEach(stageData => {
      const stage = enumStages.find(el => el.id === stageData.stage_id);
      const stageStatus = enumStatuses.find(el => el.id === stageData.status);
      let stageName = stage?.name || '';

      if (stageStatus) {
        stageName += ` (${stageStatus.name})`;
      }

      if (stageName) {
        res.push(stageName);
      }
    });

    return res;
  }

  getActions() {
    const actions = this.selfClass.getAllActions();
    return actions.filter(action => {
      let res = true;

      switch (action.key) {
        // case 'download':
        //   res = this.getValue('file_status') === enums.FILE_STATUSES.GENERATED;
        //   break;
        // case 'delete':
        //   res = this.getValue('file_status') === enums.FILE_STATUSES.GENERATED ||
        //     this.getValue('file_status') === enums.FILE_STATUSES.FAILED;
        //   break;
        default:
      }

      return res;
    });
  }
}
