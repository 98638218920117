import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('users.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('users.field_name.text'),
    sortable: true,
  },
  {
    key: 'email',
    label: Vue.prototype.$vDict('users.field_email.text'),
  },
  {
    key: 'source_id',
    label: Vue.prototype.$vDict('users.field_source_id.text'),
  },
  {
    key: 'source_type_id',
    label: Vue.prototype.$vDict('users.field_source_type_id.text'),
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('users.field_modified_at.text'),
    sortable: true,
  },
  {
    key: 'license_id',
    label: Vue.prototype.$vDict('users.field_license_id.text'),
  },
  {
    key: 'status_onboarding',
    label: Vue.prototype.$vDict('users.field_status_onboarding.text'),
  },
  {
    key: 'invite_status',
    label: Vue.prototype.$vDict('users.field_invite_status.text'),
  },
  {
    key: 'invited_at',
    label: Vue.prototype.$vDict('users.field_invited_at.text'),
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('users.field_status.text'),
  },
];
