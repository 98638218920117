<template>
  <div
    class="client-user-status"
    :class="`client-user-status--${variant}`"
  >
    <div class="client-user-status__text">{{ name }}</div>
  </div>
</template>

<script>
export default {
  name: 'client-user-status',
  props: {
    variant: { type: String, default: '' },
    name: { type: String, default: '' },
  },
};
</script>

<style lang="scss" scoped>
.client-user-status {
  --color: var(--primary);

  display: inline-flex;
  color: var(--color);
  line-height: 12px;
  align-items: center;
  padding: 2px 4px;
  border: 1px solid var(--color);
  border-radius: 4px;

  &__text {
    font-size: 10px;
    white-space: nowrap;
  }

  &--not-invited {
    --color: var(--client-user-status-not-invited);
  }

  &--invited {
    --color: var(--client-user-status-invited);
  }

  &--active {
    --color: var(--client-user-status-active);
  }

  &--muted,
  .dt-table__td--muted &--active {
    --color: var(--client-user-status-muted);
  }
}
</style>
