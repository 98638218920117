/*
  EmailLogEventsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import EmailLogEventsTableView from "@/entities/email-log-event/views/EmailLogEventsTableView";
import Vue from "vue";
import { getEmailLogEventsListsConfig } from "@/entities/email-log-event/config";
import { getEmailSenderEvents } from "@/api/repositories/emailSenderRepository";
import { requiredFields } from "@/entities/email-log-event/fields";
import { EmailLogEvent } from "@/entities";

export class EmailLogEventsList extends EntityList {
  static entityClass = EmailLogEvent;

  constructor(listTypeId) {
    super(listTypeId, getEmailLogEventsListsConfig);

    this.tableView = EmailLogEventsTableView;

    this.selfClass = EmailLogEventsList;
    this.getRequiredFields = requiredFields;
  }
}
