import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'vortal_company_code',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_vortal_company_code.text'),
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_name.text'),
    sortable: true,
  },
  {
    key: 'country_id',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_country.text'),
    details: true,
  },
  {
    key: 'vortal_company_identifier_type',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_vortal_company_identifier_type.text'),
    details: true,
  },
  {
    key: 'company_identifier',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_company_identifier.text'),
    details: true,
  },
  {
    key: 'address',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_address.text'),
    sortable: true,
  },
  {
    key: 'postcode',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_postcode.text'),
    sortable: true,
  },
  {
    key: 'city',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_city.text'),
    sortable: true,
  },
  {
    key: 'province',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_province.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'modified_at',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_modified_at.text'),
    sortable: true,
  },
  {
    key: 'created_by',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_created_by.text'),
    details: true,
  },
  {
    key: 'modified_by',
    label: Vue.prototype.$vDict('vortal_connect_companies.field_modified_by.text'),
    details: true,
  },
];
