/*
  WebhooksList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  SEARCHABLE_LIST_TYPES,
  LIST_TYPES,
} from "@/config/enums";
import WebhooksTableView from "@/entities/webhook/table-views/WebhooksTableView.vue";
import Vue from "vue";
import { getWebhooksListsConfig } from "@/entities/webhook/config";
import { getWebhooks } from '@/api/repositories/webhookRepository';
import { requiredFields } from "@/entities/webhook/fields";
import { Webhook } from "@/entities";

export class WebhooksList extends EntityList {
  static entityClass = Webhook;

  constructor(listTypeId) {
    super(listTypeId, getWebhooksListsConfig);

    this.tableView = WebhooksTableView;

    this.selfClass = WebhooksList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'client_id',
        dataCy: 'client',
        name: Vue.prototype.$vDict('webhooks.filter_organization_label.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          type: SEARCHABLE_LIST_TYPES.CLIENTS,
          placeholder: Vue.prototype.$vDict('webhooks.filter_organization_input_placeholder.text'),
        },
        listTypeIds: [LIST_TYPES.ALL_WEBHOOKS],
      },
      {
        key: 'activated',
        dataCy: 'activated',
        name: Vue.prototype.$vDict('webhooks.filter_activated_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [{id: 0, name: Vue.prototype.$vDict('global.text_no.text')}, {id: 1, name: Vue.prototype.$vDict('global.text_yes.text')}],
        },
      },
      {
        key: 'blocked',
        dataCy: 'blocked',
        name: Vue.prototype.$vDict('webhooks.filter_blocked_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [{id: 0, name: Vue.prototype.$vDict('global.text_no.text')}, {id: 1, name: Vue.prototype.$vDict('global.text_yes.text')}],
        },
      },
      {
        key: 'webhook_event_type',
        dataCy: 'webhook-event-type',
        name: Vue.prototype.$vDict('webhooks.filter_webhook_event_type_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('WEBHOOK_EVENT_TYPES'),
        },
      },
    ];
  }
}
