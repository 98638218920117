import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: 'id',
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'client_version_id',
    label: 'Client version id',
  },
  {
    key: 'source_id',
    label: 'Source id',
  },
  {
    key: 'user_id',
    label: 'User id',
  },
  {
    key: 'created_at',
    label: 'Created at',
    sortable: true,
  },
];
