import enums from "@/config/enums";
import store from "@/store";

export const getClientsListsConfig = () => ({
  [enums.LIST_TYPES.ALL_CLIENTS]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'a' },
      },
    },
    hasAccess: () => store.getters['Account/hasComponent']('all_clients'),
  },
  [enums.LIST_TYPES.CLIENT_SELECTIONS]: {
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('excel_export'),
    },
    // hasAccess: () => store.getters['Account/hasComponent']('all_clients'),
  },
});
