/*
  UserCompany class
 */

import { BaseCompany } from "@/entities/company/BaseCompany";

export class UserCompany extends BaseCompany {
  static routes = BaseCompany.userSectionRoutes;

  constructor(...args) {
    super(...args);

    this.selfClass = UserCompany;
  }
}
