/*
  EmailLogEvent class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getEmailSenderEvents } from "@/api/repositories/emailSenderRepository";
import { formatDateTime } from "@/shared/utils";

export class EmailLogEvent extends BaseEntity {
  static entityKey = 'email_log_event';
  static datasetKey = 'emailLogEvents';
  static entityTypeId = ENTITY_TYPES.EMAIL_LOG_EVENT;

  // api
  static loadAllItemsFunc = getEmailSenderEvents;
  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('email_sender_events.email_logs_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = EmailLogEvent;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'email_provider_event_type_id':
        res = this.getEventType();
        break;
      case 'event_registered_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getEventType() {
    const types = Vue.prototype.$getEnumsList('EMAIL_PROVIDER_EVENT_TYPES');
    return types.find(el => el.id === this.getValue('email_provider_event_type_id'));
  }
}
