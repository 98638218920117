import enums from "@/config/enums";
import store from '@/store';

export const getMyranSubscriptionVersionsListsConfig = () => ({
  [enums.LIST_TYPES.MYRAN_LOGS_SUBSCRIPTION_VERSIONS]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
  [enums.LIST_TYPES.SUBSCRIPTION_MYRAN_UPDATES]: {
    defaultSearchFilter: {
      default: {
        status: 1,
      },
    },
    defaultSort: {
      default: {
        id: { order: 'd' },
      },
    },
  },
});
