import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'pm_client_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_client_id.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'is_fetched',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_fetched.text'),
  },
  {
    key: 'is_valid',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
  },
  {
    key: 'is_migrated',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_migrated.text'),
  },
  {
    key: 'fetching_launched_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_fetching_launched_at.text'),
    details: true,
  },
  {
    key: 'fetched_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_fetched_at.text'),
    details: true,
  },
  {
    key: 'fetching_attempts',
    label: Vue.prototype.$vDict('parabyg_migration.field_fetching_attempts.text'),
    details: true,
  },
  {
    key: 'fetching_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_fetching_error_message.text'),
    details: true,
  },
  {
    key: 'validation_launched_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_validation_launched_at.text'),
    details: true,
  },
  {
    key: 'validated_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_validated_at.text'),
    details: true,
  },
  {
    key: 'validation_attempts',
    label: Vue.prototype.$vDict('parabyg_migration.field_validation_attempts.text'),
    details: true,
  },
  {
    key: 'migration_launched_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_migration_launched_at.text'),
    details: true,
  },
  {
    key: 'migrated_at',
    label: Vue.prototype.$vDict('parabyg_migration.field_migrated_at.text'),
    details: true,
  },
  {
    key: 'migration_attempts',
    label: Vue.prototype.$vDict('parabyg_migration.field_migration_attempts.text'),
    details: true,
  },
  {
    key: 'pm_projects',
    label: Vue.prototype.$vDict('parabyg_migration.projects_title.text'),
    details: true,
  },
  {
    key: 'pm_companies',
    label: Vue.prototype.$vDict('parabyg_migration.companies_title.text'),
    details: true,
  },
  {
    key: 'pm_contacts',
    label: Vue.prototype.$vDict('parabyg_migration.contacts_title.text'),
    details: true,
  },
  {
    key: 'pm_employees',
    label: Vue.prototype.$vDict('parabyg_migration.employees_title.text'),
    details: true,
  },
  {
    key: 'pm_documents',
    label: Vue.prototype.$vDict('parabyg_migration.documents_title.text'),
    details: true,
  },
  {
    key: 'pm_activities',
    label: Vue.prototype.$vDict('parabyg_migration.activities_title.text'),
    details: true,
  },
  {
    key: 'pm_custom_fields',
    label: Vue.prototype.$vDict('parabyg_migration.custom_fields_title.text'),
    details: true,
  },
  {
    key: 'pm_custom_field_values',
    label: Vue.prototype.$vDict('parabyg_migration.custom_field_values_title.text'),
    details: true,
  },
  {
    key: 'pm_custom_field_selections',
    label: Vue.prototype.$vDict('parabyg_migration.custom_field_selections_title.text'),
    details: true,
  },
  {
    key: 'pm_custom_field_employees',
    label: Vue.prototype.$vDict('parabyg_migration.custom_field_employees_title.text'),
    details: true,
  },
];
