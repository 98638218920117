/*
  UserCompany class
 */

import { BaseCompany } from "@/entities/company/BaseCompany";

export class Company extends BaseCompany {
  static routes = BaseCompany.allSectionRoutes;

  constructor(...args) {
    super(...args);

    this.selfClass = Company;
  }
}
