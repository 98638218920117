<template>
  <div class="smart-selection-info">
    <slot
      name="fields"
      :fields="selectionFields"
    >
      <b-form-group
        v-for="field in selectionFields"
        :key="field.key"
        :label="field.name"
      >{{ field.value }}</b-form-group>
    </slot>

    <collapsible-list
      :items="filters"
    >
      <template v-slot:item(administrative_units)="{ item }">
        <b-form-group class="form-group--section">
          <div class="form-group__centered-content form-group__section-content">
            <checkboxes-related
              view-only
              :value="item.value"
              :items="locations"
              name-key="title"
            />
          </div>
        </b-form-group>
      </template>
      <template v-slot:item(categories)="{ item }">
        <b-form-group class="form-group--section">
          <div class="form-group__centered-content form-group__section-content">
            <checkboxes-related
              view-only
              :value="item.value"
              :items="item.items"
            />
          </div>
        </b-form-group>
      </template>
      <template v-slot:item(development_types)="{ item }">
        <b-form-group class="form-group--section">
          <div class="form-group__centered-content form-group__section-content">
            <checkboxes-column
              view-only
              :value="item.value"
              :items="item.items"
            />
          </div>
        </b-form-group>
      </template>
      <template v-slot:item(planning_stages)="{ item }">
        <b-form-group class="form-group--section">
          <div class="form-group__centered-content form-group__section-content">
            <checkboxes-column
              view-only
              :value="item.value"
              :items="item.items"
            />
          </div>
        </b-form-group>
      </template>
      <template v-slot:item(event_roles)="{ item }">
        <b-form-group class="form-group--section">
          <div class="form-group__centered-content form-group__section-content">
            <checkboxes-related
              view-only
              :value="item.value"
              :items="item.items"
            />
          </div>
        </b-form-group>
      </template>
      <template v-slot:item(view_roles)="{ item }">
        <b-form-group class="form-group--section">
          <div class="form-group__centered-content form-group__section-content">
            <checkboxes-related
              view-only
              :value="item.value"
              :items="item.items"
            />
          </div>
        </b-form-group>
      </template>
      <template v-slot:item(tender_event_roles)="{ item }">
        <b-form-group class="form-group--section">
          <div class="form-group__centered-content form-group__section-content">
            <checkboxes-related
              view-only
              :value="item.value"
              :items="item.items"
            />
          </div>
        </b-form-group>
      </template>
      <template v-slot:item(tender_view_roles)="{ item }">
        <b-form-group class="form-group--section">
          <div class="form-group__centered-content form-group__section-content">
            <checkboxes-related
              view-only
              :value="item.value"
              :items="item.items"
            />
          </div>
        </b-form-group>
      </template>
      <template v-slot:li(value)="{ item }">
        <li class="selection-not-collapsible-item">
          <b>{{ item.originName }}:</b> {{ item.value }}
        </li>
      </template>
    </collapsible-list>
  </div>
</template>

<script>
import CheckboxesRelated from "@/components/forms/checkboxes-related/CheckboxesRelated";
import CheckboxesColumn from "@/components/forms/CheckboxesColumn";
import CollapsibleList from "@/components/collapsible-list/CollapsibleList";
import { getIdsFromArray } from "@/shared/utils";
import { USER_ADDONS } from "@/config/enums";

export default {
  name: "smart-selection-info",
  components: {
    CheckboxesRelated,
    CheckboxesColumn,
    CollapsibleList,
  },
  props: {
    selection: { type: Object, default: Object },
    locations: { type: Array, default: Array },
    userModules: { type: Array, default: Array },
  },
  data() {
    return {
      filters: [],
      filtersLookups: [],
    };
  },
  computed: {
    selectionFields() {
      return [
        {
          key: 'description',
          name: this.$vDict('smart_selections.field_description.text'),
          value: this.selection.getValue('description'),
        },
      ].filter(field => field.value);
    },
  },
  mounted() {
    this.filtersLookups = this.selection.getFiltersLookups();
    this.filters = this.getSelectionFilters();
  },
  methods: {
    hasAccessToFilter(filterKey) {
      const { subscription } = this.selection;
      const modules = this.userModules.length > 0 ?
        this.userModules :
        subscription.getValue('smart_settings.modules', []);
      let res;

      switch (filterKey) {
        case 'tender_view_roles':
        case 'tender_event_roles':
          res = modules.includes(USER_ADDONS.TENDERS);
          break;
        default:
          res = true;
      }

      return res;
    },
    getSelectionFilters() {
      const filters = this.selection.getFilters(this.locations);

      return filters
        .filter(filter => {
          const isLookupsEmpty = filter.lookupKey && !this.filtersLookups[filter.lookupKey]?.length;
          return !isLookupsEmpty && filter.value;
        })
        .map(filter => {
          const isAvailable = this.hasAccessToFilter(filter.key);
          let items;

          if (filter.lookupKey) {
            items = this.filtersLookups[filter.lookupKey];
          }

          return {
            ...filter,
            originName: filter.name,
            name: this.getFilterNameWithCountOfSelected(filter.name, filter.value, isAvailable),
            value: items ? this.getFilterValue(filter.value, items, isAvailable) : filter.value,
            items,
            class: !isAvailable ? 'not-available' : null,
          };
        });
    },
    getFilterValue(value, items, showAllAvailable = true) {
      if (!items) {
        return [];
      }

      let res = [];

      if (showAllAvailable) {
        res = _.isEmpty(value) ? getIdsFromArray(items) : value;
      } else {
        res = value;
      }

      return res;
    },
    getFilterNameWithCountOfSelected(name, value, showAllText = true) {
      let res = name;

      if (Array.isArray(value)) {
        res = value.length > 0 || !showAllText ? `${name} (${value.length})` : `${name} (${this.$vDict('smart_selections.selection_filter_access_any.text')})`
      }

      return res;
    },
  },
}
</script>

<style lang="scss" scoped>
.selection-not-collapsible-item {
  padding: 9px 8px 9px 34px;
  border-bottom: 1px solid var(--line-color-main);

  b {
    font-weight: 500;
  }
}

.smart-selection-info::v-deep li.not-available {
  color: var(--link-disable-color);
}
</style>
