<template>
  <base-modal
    ref="modalLogEvents"
    :title="$vDict('user_transfer_tasks.transfer_task_stages_list_title.text')"
    size="xl"
    hide-footer
    @hidden="onHiddenModalLogEvents"
  >
    <columns-info
      v-if="currentUserTransferTask"
      :fields="userTransferTaskFields"
      :item="currentUserTransferTask"
      :columns="2"
      :dataset="dataset"
      class="task-info-columns"
    >
      <template v-slot:creator_id="data">
        <field-user
          v-if="data.value"
          :user-data="data.value"
          hide-avatar
        />
      </template>
      <template v-slot:from_user_id="data">
        <field-user
          v-if="data.value"
          :user-data="data.value"
          hide-avatar
        />
      </template>
      <template v-slot:to_user_id="data">
        <field-user
          v-if="data.value"
          :user-data="data.value"
          hide-avatar
        />
      </template>
      <template v-slot:task_status="data">
        <div
          v-if="data.value"
        >{{ data.value.name }}</div>
      </template>
      <template v-slot:created_at="data">
        <span v-html="data.value"></span>
      </template>
    </columns-info>

    <entity-table
      v-if="currentUserTransferTask"
      :fields="stageFields"
      :items="stages"
      is-static-items
      show-pagination
    >
      <template v-slot:cell(status)="data">
        <div
          v-if="data.value"
          class="status-tag"
          :class="data.value.statusTagClass"
        >{{ data.value.name }}</div>
      </template>
    </entity-table>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import ColumnsInfo from "@/components/ColumnsInfo";
import FieldUser from "@/components/entity-fields/FieldUser";
import MixinModal from "@/mixins/MixinModal";
import EntityTable from "@/components/table/EntityTable";

export default {
  name: "user-transfer-task-stages-modal",
  props: {
    dataset: { type: Object, default: Object },
  },
  components: {
    BaseModal,
    ColumnsInfo,
    FieldUser,
    EntityTable,
  },
  mixins: [
    MixinModal,
  ],
  data() {
    return {
      elInstance: null,
      currentUserTransferTask: null,
    };
  },
  computed: {
    userTransferTaskFields() {
      return [
        {
          key: 'id',
          label: this.$vDict('user_transfer_tasks.field_id.text'),
        },
        {
          key: 'operation_id',
          label: this.$vDict('user_transfer_tasks.field_operation_id.text'),
        },
        {
          key: 'creator_id',
          label: this.$vDict('user_transfer_tasks.field_creator_id.text'),
        },
        {
          key: 'from_user_id',
          label: this.$vDict('user_transfer_tasks.field_from_user_id.text'),
        },
        {
          key: 'to_user_id',
          label: this.$vDict('user_transfer_tasks.field_to_user_id.text'),
        },
        {
          key: 'created_at',
          label: this.$vDict('user_transfer_tasks.field_created_at.text'),
        },
        {
          key: 'task_status',
          label: this.$vDict('user_transfer_tasks.field_task_status.text'),
        },
      ];
    },
    stageFields() {
      return [
        {
          key: 'id',
          label: 'Id',
        },
        {
          key: 'stage_id',
          label: 'Stage',
          formatter: (value, key, item) => {
            const stages = this.$getEnumsList('USER_TRANSFER_STAGES');
            const stage = stages.find(el => el.id === value);

            return stage?.name || '';
          },
        },
        {
          key: 'error_code',
          label: 'Error code'
        },
        {
          key: 'error_message',
          label: 'Error message'
        },
        {
          key: 'status',
          label: 'Status',
          formatter: (value, key, item) => {
            const statuses = this.$getEnumsList('USER_TRANSFER_TASK_STATUSES');
            return statuses.find(el => el.id === value);
          },
        },
      ];
    },
    stages() {
      return this.currentUserTransferTask.getValue('stages', []);
    },
  },
  methods: {
    showStagesModal(entity) {
      this.currentUserTransferTask = entity;
      this.$refs.modalLogEvents.show();
    },
    onHiddenModalLogEvents() {
      this.elInstance = null;
      this.currentUserTransferTask = null;
    },
  },
}
</script>

<style lang="scss" scoped>
.transfer-stages {
  list-style: none;
  margin: 0;
  padding: 0;
}
.task-info-columns {
  margin-bottom: 30px;
}
</style>
