<template>
  <base-table-view
    class="api-clients-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(name)="data">
      <div class="field-title">
        <b-link
          class="field-title__text"
          :to="data.item.getLinkTo()"
          @click.native="data.item.linkToHandler($event)"
        >{{ data.value }}</b-link>
      </div>
    </template>
    <template v-slot:cell(api_client_id)="data">
      <copied-text
        :text="data.value"
      />
    </template>
    <template v-slot:cell(status)="data">
      <div
        v-if="data.value"
        class="status-tag"
        :class="data.value.class"
      >{{ data.value.name }}</div>
    </template>
    <template v-slot:cell(client_id)="{ item }">
      <b-link
        v-if="item.client"
        :to="item.client.getLinkTo()"
      >{{ item.client.getName() }}</b-link>
    </template>
    <template v-slot:cell(subscription_id)="{ item }">
      <b-link
        class="entity-link"
        :to="item.subscription.getLinkTo()"
      >{{ item.subscription.getName() }}</b-link>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import CopiedText from "@/components/CopiedText";

export default {
  name: 'api-clients-table-view',
  components: {
    BaseTableView,
    CopiedText,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.api-clients-table-view {
  &::v-deep {
    .column-api-client-id {
      min-width: 200px;

      @include media-breakpoint-up(xl) {
        min-width: 320px;
      }
    }
  }
}
</style>
