<template>
  <base-table-view
    class="pm-activities-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(pm_client_version_id)="data">
      <client-version-field
        :version-id="data.value"
      />
    </template>
    <template v-slot:cell(user_id)="data">
      <field-user
        v-if="data.value"
        :user-data="data.value"
      />
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import ClientVersionField from "@/entities/parabyg-migration/ClientVersionField";
import FieldUser from "@/components/entity-fields/FieldUser";

export default {
  name: 'pm-activities-table-view',
  components: {
    BaseTableView,
    ClientVersionField,
    FieldUser,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
