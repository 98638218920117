<template>
  <base-table-view
    class="webhooks-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(description)="data">
      <b-link
        :to="data.item.getLinkTo()"
        @click.native="data.item.linkToHandler($event)"
      >{{ data.value }}</b-link>
    </template>
    <template v-slot:cell(client_name)="data">
      <b-link
        :to="{ name: 'SupportClient', params: { id: data.item.getValue('client_id') } }"
      >{{ data.value }}</b-link>
    </template>
    <template v-slot:cell(status)="data">
      <div
        v-if="data.value"
        class="status-tag"
        :class="data.value.class"
      >{{ data.value.name }}</div>
    </template>
    <template v-slot:cell(endpoint_url)="data">
      <copied-text
        :text="data.value"
      />
    </template>
    <template v-slot:cell(created_at)="data">
      <div v-html="data.value" />
    </template>
    <template v-slot:cell(modified_at)="data">
      <div v-html="data.value" />
    </template>
    <!--        <template v-slot:cell(webhook_events)="data">
              <ul
                v-if="data.value && data.value.length > 0"
                class="sm-tags-list"
              >
                <li
                  v-for="(field, index) in data.value"
                  :key="index"
                  class="sm-tags-list__item"
                >
                  {{ field.name }}
                </li>
              </ul>
            </template>-->
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import CopiedText from "@/components/CopiedText";

export default {
  name: 'webhooks-table-view',
  components: {
    BaseTableView,
    CopiedText,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.webhooks-table-view {
  &::v-deep {
    .column-url {
      width: 300px;
      min-width: 300px;
      word-break: break-all;
    }
  }
}
</style>
