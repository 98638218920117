/*
  UserProject class
 */

import { BaseProject } from "@/entities/project/BaseProject";

export class UserProject extends BaseProject {
  static routes = BaseProject.userSectionRoutes;

  constructor(...args) {
    super(...args);

    this.selfClass = UserProject;
  }
}
