import { render, staticRenderFns } from "./UserAccountStatus.vue?vue&type=template&id=00a464dc&scoped=true&"
import script from "./UserAccountStatus.vue?vue&type=script&lang=js&"
export * from "./UserAccountStatus.vue?vue&type=script&lang=js&"
import style0 from "./UserAccountStatus.vue?vue&type=style&index=0&id=00a464dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a464dc",
  null
  
)

export default component.exports