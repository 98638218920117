import { LIST_TYPES } from '@/config/enums';
import {
  ProjectsList,
  UserProjectsList,
  TendersList,
  UserTendersList,
  CompaniesList,
  UserCompaniesList,
  ContactsList,
  UserContactsList,
  ActivitiesList,
  NotesList,
  FilesList,
  ClientsList,
  UsersList,
  ReleaseNotesList,
  ApiClientsList,
  WebhooksList,
  WebhooksQueueList,
  EmailLogsList,
  SavedSearchNotificationsList,
  UserTransferTasksList,
  EmailLogEventsList,
  ComponentsList,
  ModulesList,
  DeadMessageSubscriptionsList,
  DeadMessagesList,
  DefaultFieldsetsList,
  VortalCompaniesList,
  VortalContactsList,
  VortalNotificationEmailsList,
  VortalLogsList,
  VortalProjectsList,
  MyranSubscriptionVersionsList,
  MyranUserVersionsList,
  MyranUserProfilesList,
  MyranSuspendedUsersList,
  MyranClientVersionsList,
  UsageStatsUsersList,
  UsageStatsClientsList,
  CustomFieldsetList,
  CustomFieldMetricsList,
  CustomFieldSelectionMetricsList,
  PMClientsList,
  PMClientVersionsList,
  PMCompaniesList,
  PMContactsList,
  PMDocumentsList,
  PMActivitiesList,
  PMEmployeesList,
  PMProjectsList,
  PMCustomFieldsList,
  PMCustomFieldSelectionsList,
  PMCustomFieldValuesList,
  PMCustomFieldEmployeesList,
  ErrorMessagesList,
  SubscriptionsList,
  LicensesList,
  ClientUsersList,
  UserProfilesList,
} from './index';

const defaultTypeId = LIST_TYPES.ALL_PROJECTS;

export class EntityListFactory {
  static getClass(typeId) {
    let res;

    switch (typeId) {
      case LIST_TYPES.ALL_PROJECTS:
      case LIST_TYPES.COMPANY_PROJECTS:
      case LIST_TYPES.CONTACT_PROJECTS:
        res = ProjectsList;
        break;
      case LIST_TYPES.USER_PROJECTS:
        res = UserProjectsList;
        break;
      case LIST_TYPES.ALL_TENDERS:
      case LIST_TYPES.PROJECT_TENDERS:
      case LIST_TYPES.COMPANY_TENDERS:
      case LIST_TYPES.CONTACT_TENDERS:
      case LIST_TYPES.TENDER_TENDERS:
        res = TendersList;
        break;
      case LIST_TYPES.USER_TENDERS:
        res = UserTendersList;
        break;
      case LIST_TYPES.ALL_COMPANIES:
      case LIST_TYPES.PROJECT_COMPANIES:
        res = CompaniesList;
        break;
      case LIST_TYPES.USER_COMPANIES:
        res = UserCompaniesList;
        break;
      case LIST_TYPES.ALL_CONTACTS:
      case LIST_TYPES.COMPANY_CONTACTS:
        res = ContactsList;
        break;
      case LIST_TYPES.USER_CONTACTS:
        res = UserContactsList;
        break;
      case LIST_TYPES.USER_ACTIVITIES:
        res = ActivitiesList;
        break;
      case LIST_TYPES.USER_NOTES:
        res = NotesList;
        break;
      case LIST_TYPES.ALL_FILES:
      case LIST_TYPES.USER_FILES:
        res = FilesList;
        break;
      case LIST_TYPES.ALL_CLIENTS:
        res = ClientsList;
        break;
      case LIST_TYPES.ALL_USERS:
        res = UsersList;
        break;
      case LIST_TYPES.ALL_RELEASE_NOTES:
        res = ReleaseNotesList;
        break;
      case LIST_TYPES.ALL_API_CLIENTS:
        res = ApiClientsList;
        break;
      case LIST_TYPES.ALL_WEBHOOKS:
      case LIST_TYPES.USER_WEBHOOKS:
        res = WebhooksList;
        break;
      case LIST_TYPES.ALL_WEBHOOKS_QUEUE:
      case LIST_TYPES.USER_WEBHOOKS_QUEUE:
        res = WebhooksQueueList;
        break;
      case LIST_TYPES.ALL_EMAIL_LOGS:
        res = EmailLogsList;
        break;
      case LIST_TYPES.ALL_SAVED_SEARCH_NOTIFICATIONS_LIST:
        res = SavedSearchNotificationsList;
        break;
      case LIST_TYPES.ALL_USER_TRANSFER_TASKS:
        res = UserTransferTasksList;
        break;
      case LIST_TYPES.ALL_EMAIL_LOG_EVENTS:
        res = EmailLogEventsList;
        break;
      case LIST_TYPES.ALL_COMPONENTS:
        res = ComponentsList;
        break;
      case LIST_TYPES.ALL_MODULES:
        res = ModulesList;
        break;
      case LIST_TYPES.DEAD_MESSAGES:
        res = DeadMessagesList;
        break;
      case LIST_TYPES.DEAD_MESSAGE_SUBSCRIPTIONS:
        res = DeadMessageSubscriptionsList;
        break;
      case LIST_TYPES.ALL_DEFAULT_FIELDSETS:
        res = DefaultFieldsetsList;
        break;
      case LIST_TYPES.VORTAL_CONNECT_COMPANIES:
        res = VortalCompaniesList;
        break;
      case LIST_TYPES.VORTAL_CONNECT_CONTACTS:
        res = VortalContactsList;
        break;
      case LIST_TYPES.VORTAL_CONNECT_PROJECTS:
        res = VortalProjectsList;
        break;
      case LIST_TYPES.VORTAL_CONNECT_NOTIFICATION_EMAILS:
        res = VortalNotificationEmailsList;
        break;
      case LIST_TYPES.VORTAL_CONNECT_LOGS:
        res = VortalLogsList;
        break;
      case LIST_TYPES.MYRAN_LOGS_USER_VERSIONS:
        res = MyranUserVersionsList;
        break;
      case LIST_TYPES.MYRAN_LOGS_SUBSCRIPTION_VERSIONS:
        res = MyranSubscriptionVersionsList;
        break;
      case LIST_TYPES.MYRAN_LOGS_SUSPENDED_USERS:
        res = MyranSuspendedUsersList;
        break;
      case LIST_TYPES.MYRAN_LOGS_USER_PROFILES:
      case LIST_TYPES.USER_MYRAN_UPDATES:
        res = MyranUserProfilesList;
        break;
      case LIST_TYPES.MYRAN_LOGS_CLIENT_VERSIONS:
        res = MyranClientVersionsList;
        break;
      case LIST_TYPES.USAGE_STATISTICS_USERS:
        res = UsageStatsUsersList;
        break;
      case LIST_TYPES.USAGE_STATISTICS_CLIENTS:
        res = UsageStatsClientsList;
        break;
      case LIST_TYPES.ALL_CUSTOM_FIELDSET_TEMPLATES:
        res = CustomFieldsetList;
        break;
      case LIST_TYPES.ALL_CUSTOM_FIELD_METRICS:
        res = CustomFieldMetricsList;
        break;
      case LIST_TYPES.ALL_CUSTOM_FIELD_SELECTION_METRICS:
        res = CustomFieldSelectionMetricsList;
        break;
      case LIST_TYPES.ALL_PM_CLIENTS:
        res = PMClientsList;
        break;
      case LIST_TYPES.ALL_PM_CLIENT_VERSIONS:
        res = PMClientVersionsList;
        break;
      case LIST_TYPES.ALL_PM_COMPANIES:
        res = PMCompaniesList;
        break;
      case LIST_TYPES.ALL_PM_PROJECTS:
        res = PMProjectsList;
        break;
      case LIST_TYPES.ALL_PM_CONTACTS:
        res = PMContactsList;
        break;
      case LIST_TYPES.ALL_PM_ACTIVITIES:
        res = PMActivitiesList;
        break;
      case LIST_TYPES.ALL_PM_DOCUMENTS:
        res = PMDocumentsList;
        break;
      case LIST_TYPES.ALL_PM_EMPLOYEES:
        res = PMEmployeesList;
        break;
      case LIST_TYPES.ALL_PM_CUSTOM_FIELDS:
        res = PMCustomFieldsList;
        break;
      case LIST_TYPES.ALL_PM_CUSTOM_FIELD_VALUES:
        res = PMCustomFieldValuesList;
        break;
      case LIST_TYPES.ALL_PM_CUSTOM_FIELD_SELECTIONS:
        res = PMCustomFieldSelectionsList;
        break;
      case LIST_TYPES.ALL_PM_CUSTOM_FIELD_EMPLOYEES:
        res = PMCustomFieldEmployeesList;
        break;
      case LIST_TYPES.ERROR_MESSAGES:
        res = ErrorMessagesList;
        break;
      case LIST_TYPES.SUBSCRIPTIONS:
      case LIST_TYPES.CLIENT_SUBSCRIPTIONS:
      case LIST_TYPES.USER_SUBSCRIPTIONS:
        res = SubscriptionsList;
        break;
      case LIST_TYPES.LICENSES:
        res = LicensesList;
        break;
      case LIST_TYPES.CLIENT_USERS:
        res = ClientUsersList;
        break;
      case LIST_TYPES.SUBSCRIPTION_USERS:
        res = UserProfilesList;
        break;
      default:
        res = ProjectsList;
    }

    return res;
  }

  static create(...args) {
    const typeId = args[0] || defaultTypeId;
    const EntityListClass = this.getClass(typeId);
    return new EntityListClass(...args);
  }
}
