<template>
  <base-modal
    ref="modalLogEvents"
    :title="$vDict('email_sender_events.list_email_log_events_title.text')"
    size="xl"
    hide-footer
    @hidden="onHiddenModalLogEvents"
  >
    <columns-info
      v-if="currentEmailLog"
      :fields="emailLogFields"
      :item="currentEmailLog"
      :columns="2"
      :dataset="dataset"
      class="log-info-columns"
    >
      <template v-slot:user_id="data">
        <field-user
          v-if="data.value"
          :user-data="data.value"
          hide-avatar
        />
      </template>
    </columns-info>

    <entity-list
      v-if="elInstance"
      class="email-log-events"
      :entity-list="elInstance"
      load-immediate
    />
  </base-modal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import ColumnsInfo from "@/components/ColumnsInfo";
import FieldUser from "@/components/entity-fields/FieldUser";
import EntityList from "@/components/entity-list/EntityList";
import MixinModal from "@/mixins/MixinModal";
import { EmailLogEventsList } from "@/entities/email-log-event/EmailLogEventsList";

export default {
  name: "email-log-events-modal",
  props: {
    dataset: { type: Object, default: Object },
  },
  components: {
    BaseModal,
    ColumnsInfo,
    FieldUser,
    EntityList,
  },
  mixins: [
    MixinModal,
  ],
  data() {
    return {
      elInstance: null,
      currentEmailLog: null,
    };
  },
  computed: {
    emailLogFields() {
      return [
        {
          key: 'id',
          label: this.$vDict('email_sender_logs.field_id.text'),
        },
        {
          key: 'email',
          label: this.$vDict('email_sender_logs.field_email.text'),
        },
        {
          key: 'subject',
          label: this.$vDict('email_sender_logs.field_subject.text'),
        },
        {
          key: 'provider_msg_id',
          label: this.$vDict('email_sender_logs.field_provider_msg_id.text'),
        },
        {
          key: 'message_id',
          label: this.$vDict('email_sender_logs.field_message_id.text'),
        },
        {
          key: 'user_id',
          label: this.$vDict('email_sender_logs.field_user_id.text'),
        },
      ];
    },
  },
  methods: {
    showLogEvents(entity) {
      this.currentEmailLog = entity;
      this.elInstance = new EmailLogEventsList(enums.LIST_TYPES.ALL_EMAIL_LOG_EVENTS);

      Object.assign(this.elInstance, {
        permanentQueryData: {
          filter: {
            message_id: {
              in: [entity.getValue('message_id')],
            },
          },
        },
      });

      this.$refs.modalLogEvents.show();
    },
    onHiddenModalLogEvents() {
      this.elInstance = null;
      this.currentEmailLog = null;
    },
  },
}
</script>

<style lang="scss" scoped>
.email-log-events {
  margin-top: 24px;
}
</style>
