<template>
  <div class="subscription-actions">
    <create-user-modal
      ref="createUserModal"
    />
  </div>
</template>

<script>
import CreateUserModal from "@/entities/user/views/CreateUserModal";

export default {
  name: 'subscription-actions',
  components: {
    CreateUserModal,
  },
  methods: {
    async actionHandler(subscription, action) {
      switch (action.key) {
        case 'create_user':
          await this.$refs.createUserModal.show({
            subscription,
          });
          break;
        default:
          await subscription.doAction(action);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
