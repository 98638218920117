<template>
  <div class="app">
    <loading />
  </div>
</template>

<script>
export default {
  name: "loading-app-layout",
}
</script>

<style scoped>
.app {
  align-items: center;
  justify-content: center;
}
</style>
