import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('user_transfer_tasks.field_id.text'),
    sortable: true,
  },
  {
    key: 'operation_id',
    label: Vue.prototype.$vDict('user_transfer_tasks.field_operation_id.text'),
  },
  {
    key: 'creator_id',
    label: Vue.prototype.$vDict('user_transfer_tasks.field_creator_id.text'),
  },
  {
    key: 'from_user_id',
    label: Vue.prototype.$vDict('user_transfer_tasks.field_from_user_id.text'),
  },
  {
    key: 'to_user_id',
    label: Vue.prototype.$vDict('user_transfer_tasks.field_to_user_id.text'),
  },
  {
    key: 'stages',
    label: Vue.prototype.$vDict('user_transfer_tasks.field_stages.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('user_transfer_tasks.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'task_status',
    label: Vue.prototype.$vDict('user_transfer_tasks.field_task_status.text'),
  },
];
