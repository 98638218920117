import enums from "@/config/enums";
import store from "@/store";
import vars from "@/config/vars";

export const getCompaniesListsConfig = () => ({
  [enums.LIST_TYPES.ALL_COMPANIES]: {
    isMain: true,
    isSavePosition: true,
    showFieldsSettings: true,
    showMapView: true,
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('excel_export'),
      print: store.getters['Account/hasComponent']('print'),
    },
    defaultSort: {
      default: {
        name: { order: 'a' },
      },
    },
    permanentQueryData: {
      default: {
        filter: {
          company_type_id: {
            not_in: vars.FICTITIOUS_COMPANY_TYPES,
          },
        },
      },
    },
    customDefaultFieldset: true,
    showSsn: true,
    alternativeListTypeId: enums.LIST_TYPES.USER_COMPANIES,
    hasBulkOperations: true,
    bulkOperations() {
      return [
        {
          key: 'print',
        },
        {
          key: 'excel',
        },
      ];
    },
  },
  [enums.LIST_TYPES.USER_COMPANIES]: {
    isMain: true,
    isSavePosition: true,
    showFieldsSettings: true,
    showMapView: true,
    sharedOptions: {
      exportToFile: store.getters['Account/hasComponent']('excel_export'),
      print: store.getters['Account/hasComponent']('print'),
    },
    defaultSearchFilter: {
      default: {
        'users.user_id': [
          store.getters['Account/userId'],
        ],
      },
    },
    defaultSort: {
      byStatus: {
        [enums.USER_COMPANY_FOLDERS.PROSPECTS]: {
          delivered_at: { order: 'd' },
        },
      },
      default: {
        latest_update_at: { order: 'd' },
      },
    },
    permanentQueryData: {
      default: {
        filter: {
          company_type_id: {
            not_in: vars.FICTITIOUS_COMPANY_TYPES,
          },
        },
      },
    },
    hasLockedItems: true,
    customDefaultFieldset: true,
    actions: {
      clearFolder: {
        byStatus: {
          [enums.USER_COMPANY_FOLDERS.PROSPECTS]: true,
        },
        default: false,
      },
    },
    couldHasCustomFields: store.getters['Account/hasComponent']('company_custom_fields'),
    alternativeListTypeId: enums.LIST_TYPES.ALL_COMPANIES,
    hasBulkOperations: true,
    hasSavedByFilter: true,
    bulkOperations() {
      return [
        {
          key: 'print',
        },
        {
          key: 'excel',
        },
        {
          key: 'delete',
        },
      ];
    },
  },
  [enums.LIST_TYPES.PROJECT_COMPANIES]: {
    defaultListFilter: {
      default: {
        show_mode: 'by_profile',
      },
    },
    fieldset: [
      'project_company_roles_icon',
      'project_company_roles',
      'addresses',
      'phones',
      'website',
      'email',
      'contacts',
    ],
  },
});
