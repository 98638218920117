/*
  ApiClientsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  SEARCHABLE_LIST_TYPES,
  LIST_TYPES,
} from "@/config/enums";
import WebhooksQueueTableView from "@/entities/webhooks-queue/views/WebhooksQueueTableView";
import Vue from "vue";
import { getWebhooksQueueListsConfig } from "@/entities/webhooks-queue/config";
import {
  getWebhookQueues,
  getWebhooks,
  getWebhookVersions
} from "@/api/repositories/webhookRepository";
import { requiredFields } from "@/entities/webhooks-queue/fields";
import { WebhooksQueue } from "@/entities";
// import { Client } from "@/entities";

export class WebhooksQueueList extends EntityList {
  static entityClass = WebhooksQueue;

  constructor(listTypeId) {
    super(listTypeId, getWebhooksQueueListsConfig);

    this.tableView = WebhooksQueueTableView;

    this.selfClass = WebhooksQueueList;
    this.getRequiredFields = requiredFields;
  }

  async loadDataForInitList() {
    // this.dataset = {
    //   queueStatuses: Vue.prototype.$getEnumsList('WEBHOOK_QUEUE_STATUSES'),
    //   webhookEventTypes: Vue.prototype.$getEnumsList('WEBHOOK_EVENT_TYPES'),
    // };
    // this.loadWebhookVersions();
    const webhookVersions = await this.loadWebhookVersions();
    return {
      webhookVersions,
    };
  }

  async loadWebhookVersions() {
    let webhookVersions = [];

    try {
      const response = await getWebhookVersions();
      webhookVersions = response?.data || [];
    } catch (error) {
      console.log(error);
    }

    return webhookVersions;
  }

  getListFiltersSetting(payload = {}) {
    return [
      {
        key: 'webhook_id',
        dataCy: 'webhook',
        name: Vue.prototype.$vDict('webhook_queue.filter_webhook_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('webhook_queue.filter_webhook_id_input_placeholder.text'),
          nameKey: 'description',
          isMultiple: true,
          searchFunc: getWebhooks,
        },
      },
      {
        key: 'webhook_version_id',
        dataCy: 'webhook-version',
        name: Vue.prototype.$vDict('webhook_queue.filter_webhook_version_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.CHECKBOXES_LIST,
        filterAttrs: {
          items: payload.webhookVersions,
          placeholder: Vue.prototype.$vDict('webhook_queue.filter_webhook_version_id_input_placeholder.text'),
        },
      },
      {
        key: 'webhook_event_type',
        dataCy: 'webhook-event-type',
        name: Vue.prototype.$vDict('webhook_queue.filter_webhook_event_type_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('WEBHOOK_EVENT_TYPES'),
        },
      },
      {
        key: 'client_id',
        dataCy: 'client',
        name: Vue.prototype.$vDict('webhook_queue.filter_client_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          isMultiple: true,
          type: SEARCHABLE_LIST_TYPES.CLIENTS,
          placeholder: Vue.prototype.$vDict('webhook_queue.filter_client_id_input_placeholder.text'),
        },
        listTypeIds: [LIST_TYPES.ALL_WEBHOOKS_QUEUE],
      },
      {
        key: 'activated',
        dataCy: 'activated',
        name: Vue.prototype.$vDict('webhook_queue.filter_activated_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [{id: 0, name: Vue.prototype.$vDict('global.text_no.text')}, {id: 1, name: Vue.prototype.$vDict('global.text_yes.text')}],
        },
      },
      {
        key: 'blocked',
        dataCy: 'blocked',
        name: Vue.prototype.$vDict('webhook_queue.filter_blocked_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [{id: 0, name: Vue.prototype.$vDict('global.text_no.text')}, {id: 1, name: Vue.prototype.$vDict('global.text_yes.text')}],
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('webhook_queue.filter_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'scheduled_at',
        dataCy: 'scheduled-at',
        name: Vue.prototype.$vDict('webhook_queue.filter_scheduled_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'launched_at',
        dataCy: 'launched-at',
        name: Vue.prototype.$vDict('webhook_queue.filter_launched_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'sent_at',
        dataCy: 'sent-at',
        name: Vue.prototype.$vDict('webhook_queue.filter_sent_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'status',
        dataCy: 'status',
        name: Vue.prototype.$vDict('webhook_queue.filter_status_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('WEBHOOK_QUEUE_STATUSES'),
        },
      },
      {
        key: 'retry',
        dataCy: 'retry',
        name: Vue.prototype.$vDict('webhook_queue.filter_retry_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('webhook_queue.filter_retry_input_placeholder.text'),
        },
      },
      {
        key: 'has_error',
        dataCy: 'has-error',
        name: Vue.prototype.$vDict('webhook_queue.filter_has_error_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [{id: 0, name: Vue.prototype.$vDict('global.text_no.text')}, {id: 1, name: Vue.prototype.$vDict('global.text_yes.text')}],
        },
      },
    ];
  }
}
