<template>
  <div class="ssn-actions">
    <login-as-user
      ref="loginAsUser"
    />
  </div>
</template>

<script>
import LoginAsUser from "@/entities/client-user/views/LoginAsUser";

export default {
  name: 'ssn-actions',
  components: {
    LoginAsUser,
  },
  methods: {
    async goToSSN(ssn) {
      const ssnLinkData = ssn.getSSNLinkData();

      if (!ssnLinkData) {
        return;
      }

      if (ssnLinkData.type === 'router') {
        await this.$router.push(ssnLinkData.data);
      } else if (ssnLinkData.type === 'login_as_user') {
        await this.$refs.loginAsUser.login(ssnLinkData.userData, ssnLinkData.urlParams);
      }
    },
    async actionHandler(ssn, action) {
      switch (action.key) {
        case 'go_to_ssn':
          await this.goToSSN(ssn);
          break;
        default:
          await ssn.doAction(action.key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
