<template>
  <base-modal
    ref="modal"
    :title="modalTitle"
    title-tag="h3"
    size="lg"
    hide-footer
    @shown="onShowModal"
    @hide="onHideModal"
  >
    <smart-selection-form
      :selection="selection"
      @hideModal="hideAction"
    />
  </base-modal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import MixinModal from "@/mixins/MixinModal";
import SmartSelectionForm from "@/entities/smart-selection/views/SmartSelectionForm.vue";

export default {
  name: "edit-user-selection-modal",
  components: {
    BaseModal,
    SmartSelectionForm,
  },
  mixins: [
    MixinModal,
  ],
  props: {
    selection: { type: Object, default: Object },
  },
  computed: {
    modalTitle() {
      if(!this.selection.name) return '';
      return `Selection ${this.selection.name}`;
    },
  },
  methods: {
    hideAction() {
      this.$refs.modal.hide();
    }
  },
}
</script>
