/*
  CustomFieldsetList class
 */

import Vue from 'vue';
import EntityList from "@/shared/classes/list/EntityList";
import CustomFieldsetTableView from "@/entities/custom-fieldset/views/CustomFieldsetTableView";
import { getCustomFieldsetListConfig } from "@/entities/custom-fieldset/config";
import { getCustomFieldsets, deleteCustomFieldset } from "@/api/repositories/customFieldsRepository";
import { requiredFields } from "@/entities/custom-fieldset/fields";
import { CustomFieldset } from "@/entities";

export class CustomFieldsetList extends EntityList {
  static entityClass = CustomFieldset;

  constructor(listTypeId) {
    super(listTypeId, getCustomFieldsetListConfig);

    this.tableView = CustomFieldsetTableView;

    this.selfClass = CustomFieldsetList;
    this.getRequiredFields = requiredFields;
  }
}
