import Vue from 'vue';
import { LIST_TYPES } from "@/config/enums";

export const requiredFields = () => [
  {
    key: 'id',
    label: Vue.prototype.$vDict('webhook_queue.field_id.text'),
    class: 'column-id',
    sortable: true,
    main: true,
  },
  {
    key: 'webhook_id',
    label: Vue.prototype.$vDict('webhook_queue.field_webhook_name.text'),
  },
  {
    key: 'client_id',
    label: Vue.prototype.$vDict('webhook_queue.field_client_name.text'),
    listTypeIds: [LIST_TYPES.ALL_WEBHOOKS_QUEUE],
  },
  {
    key: 'endpoint_url',
    label: Vue.prototype.$vDict('webhook_queue.field_endpoint_url.text'),
    class: 'column-url',
  },
  {
    key: 'activated',
    label: Vue.prototype.$vDict('webhook_queue.field_activated.text'),
  },
  {
    key: 'blocked',
    label: Vue.prototype.$vDict('webhook_queue.field_blocked.text'),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('webhook_queue.field_created_at.text'),
  },
  {
    key: 'scheduled_at',
    label: Vue.prototype.$vDict('webhook_queue.field_scheduled_at.text'),
    details: true,
  },
  {
    key: 'launched_at',
    label: Vue.prototype.$vDict('webhook_queue.field_launched_at.text'),
    details: true,
  },
  {
    key: 'sent_at',
    label: Vue.prototype.$vDict('webhook_queue.field_sent_at.text'),
    details: true,
  },
  {
    key: 'retry',
    label: Vue.prototype.$vDict('webhook_queue.field_retry.text'),
    details: true,
  },
  {
    key: 'last_attempt_error_code',
    label: Vue.prototype.$vDict('webhook_queue.field_last_attempt_error_code.text'),
    details: true,
  },
  {
    key: 'status',
    label: Vue.prototype.$vDict('webhook_queue.field_status.text'),
  },
  {
    key: 'webhook_event_id',
    label: Vue.prototype.$vDict('webhook_queue.field_webhook_event_id.text'),
    details: true,
  },
  {
    key: 'webhook_version_name',
    label: Vue.prototype.$vDict('webhook_queue.field_webhook_version_name.text'),
    details: true,
  },
  {
    key: 'webhook_event_type',
    label: Vue.prototype.$vDict('webhook_queue.field_webhook_event_type.text'),
    details: true,
  },
  {
    key: 'secret_key',
    label: Vue.prototype.$vDict('webhook_queue.field_secret_key.text'),
    details: true,
  },
  {
    key: 'payload',
    label: Vue.prototype.$vDict('webhook_queue.field_payload.text'),
    details: true,
  },
  {
    key: 'last_attempt_error_message',
    label: Vue.prototype.$vDict('webhook_queue.field_last_attempt_error_message.text'),
    details: true,
  },
];
