/*
  Contact class
 */

import Vue from "vue";
import store from '@/store';
import CRMEntity from '@/shared/classes/entity/CRMEntity';
import {
  ENTITY_TYPES,
  TRIGGER_TYPES,
  COMMUNICATION_TYPES,
  CONTACT_ALL_ROLES_INDEXES, LIST_TYPES,
} from '@/config/enums';
import { Company } from '@/entities';
import { getContacts } from "@/api/repositories/leadsRepository";
import {
  getContacts as getUserContacts,
  putContact,
  deleteContact,
  putContactView,
  putBulkContacts,
  deleteContacts,
} from "@/api/repositories/salesRepository";

const FieldContactTitle = () => import("@/entities/contact/views/FieldContactTitle.vue");

export class BaseContact extends CRMEntity {
  static entityKey = 'contact';
  static userSectionAccessComponent = 'user_contacts';
  static userSectionListTypeId = LIST_TYPES.USER_CONTACTS;
  static statusKey = 'user_contact_status_id';
  static idsKey = 'contact_ids';
  static nameFieldKey = 'name';
  static datasetKey = 'contacts';
  static smallIcon = 'person-sm';
  static triggerTypesForTags = [
    TRIGGER_TYPES.CONTACT,
  ];
  static entityTypeId = ENTITY_TYPES.CONTACT;

  //
  static allSectionRoutes = {
    view: 'Contact',
    list: 'Contacts',
  };
  static userSectionRoutes = {
    view: 'MyContact',
    list: 'MyContacts',
  };
  static titleComponent = FieldContactTitle;

  // api methods
  static loadAllItemsFunc = getContacts;
  static loadUserItemsFunc = getUserContacts;
  static putViewedFunc = putContactView;
  static putItemsFunc = putBulkContacts;
  static deleteItemsFunc = deleteContacts;

  static getEntityTypeText() {
    return Vue.prototype.$vDict(`contacts.entity_type.text`);
  }

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict(`contacts.entity_plural_type.text`);
  }

  static getNotAvailableText() {
    return Vue.prototype.$vDict('contacts.contact_not_available.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.initStatusValue();
    this.selfClass = BaseContact;
  }

  initStatusValue() {
    const { statusKey } = this.constructor;
    const users = this.getValue('users', []);
    const currentUserId = store.getters['Account/userId'];
    let statusValue;

    if (users.length > 0) {
      statusValue = users.some(user => user.user_id === currentUserId);
    } else {
      statusValue = this.getValue('user_id') === currentUserId;
    }

    this.data[statusKey] = statusValue;
  }

  getName() {
    return _.compact([
      this.getValue('first_name'),
      this.getValue('last_name'),
    ]).join(' ');
  }

  getTypeName() {
    return Vue.prototype.$vDict('contacts.contact_type_text.text');
  }

  getFieldValue(fieldKey) {
    let res;
    let value;

    switch (fieldKey) {
      case 'phones':
        value = this.getCommunicationsInfo([COMMUNICATION_TYPES.PHONE, COMMUNICATION_TYPES.MOBILE]);
        res = value.length > 0 ? value : null;
        break;
      case 'fax':
        value = this.getCommunicationsInfo([COMMUNICATION_TYPES.FAX]);
        res = value.length > 0 ? value[0] : null;
        break;
      case 'email':
        value = this.getCommunicationsInfo([COMMUNICATION_TYPES.EMAIL]);
        res = value.length > 0 ? value[0].value : null;
        break;
      case 'roles':
        res = this.getFieldRoles();
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;
    let value;

    switch (fieldKey) {
      case 'tags':
        res = this.getFieldTags(dataset);
        break;
      case 'companies':
        res = this.getOriginalCompaniesWithContactTitle(dataset);
        break;
      case 'client_statuses':
        value = this.getClientStatuses(dataset);
        res = value.length > 0 ? value : null;
        break;
      default:
        res = undefined;
    }

    return res;
  }

  getOriginalCompaniesWithContactTitle(dataset) {
    const companies = this.getOriginalCompanies(dataset);
    const res = [];

    companies.forEach(company => {
      const contactData = company.getValue('contact_data', []);

      contactData
        .filter(el => el.contact_id === this.getValue('id'))
        .forEach(data => {
          const item = {
            company,
            contactTitle: Vue.prototype.$lFind('client.contact_titles', { id: data.contact_title_id, prop: 'name' }),
            companyNameWithDot: company.getName(),
          };

          if (item.companyNameWithDot &&
            item.companyNameWithDot[item.companyNameWithDot.length - 1] !== '.' &&
            item.contactTitle) {
            item.companyNameWithDot += '.';
          }

          res.push(item);
        });
    });

    return res;
  }

  getOriginalCompanies(dataset) {
    const origCompanyIds = this.data.original_companies || [];
    const res = [];

    origCompanyIds.forEach(companyId => {
      const companyData = this.getDatasetItem(dataset, 'companies', companyId);

      if (companyData) {
        res.push(new Company(companyData));
      }
    });

    return res;
  }

  getFieldRoles() {
    let roles = [];

    if (this.data.roles) {
      roles = this.getCompanyContactRoles(this.data.roles);
    } else if (this.data.all_roles) {
      roles = this.getContactAllRoles(this.data.all_roles);
    } else if (this.data.project_company_roles) {
      const roleIds = this.data.project_company_roles.map(roleId => ({
        company_role_id: roleId,
      }));
      roles = this.getContactAllRoles(roleIds);
    }

    return roles;
  }

  getContactAllRoles(allRoles) {
    let roles = allRoles.map(el => {
      const arrRoles = el.split('.');
      return {
        company_role_id: Number(arrRoles[CONTACT_ALL_ROLES_INDEXES.COMPANY_ROLE_ID]),
        contact_role_id: Number(arrRoles[CONTACT_ALL_ROLES_INDEXES.CONTACT_ROLE_ID]),
      };
    });

    roles = _.uniqBy(roles, role => [role.company_role_id, role.contact_role_id].join('.'));

    return this.getCompanyContactRoles(_.uniq(roles));
  }

  async reqUpdateStatus(value) {
    const contactId = this.getValue('id');
    let res;

    if (value) {
      res = await putContact(contactId);
    } else {
      res = await deleteContact(contactId);
    }

    if (res) {
      this.updateStatus(value);
    }
  }

  getClientStatuses() {
    const clientStatuses = this.getValue('users', []);
    const users = store.getters['UserClient/users'] || [];
    const res = [];

    clientStatuses.forEach(clientStatus => {
      const contactData = users.find(el => el.id === clientStatus.user_id);

      if (contactData) {
        const status = {
          boldIcon: 'phonebook-active',
          coworkerIcon: 'coworker-has-contact',
        };

        res.push({
          userId: contactData.id,
          userName: contactData.name || contactData.email,
          statusIcon: contactData.isCurrentUser ? status.boldIcon : status.coworkerIcon,
          status,
          isCurrentUser: contactData.isCurrentUser,
        });
      }
    });

    return res;
  }

  async loadStatus() {
    const { statusKey, loadUserItemsFunc } = this.selfClass;
    const query = {
      limit: 1,
      fields: ['id'],
      filter: {
        id: {
          in: [this.id],
        },
      },
    };

    try {
      const response = await loadUserItemsFunc(query);
      const contacts = response?.data || [];

      if (contacts.some(contact => contact.id === this.id)) {
        this.setValue(statusKey, true);
      }
    } catch (error) {}
  }

  getEntityUsers() {
    return this.getValue('users', []);
  }
}
