import { render, staticRenderFns } from "./DeadMessagesTableView.vue?vue&type=template&id=6c9d4071&scoped=true&"
import script from "./DeadMessagesTableView.vue?vue&type=script&lang=js&"
export * from "./DeadMessagesTableView.vue?vue&type=script&lang=js&"
import style0 from "./DeadMessagesTableView.vue?vue&type=style&index=0&id=6c9d4071&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c9d4071",
  null
  
)

export default component.exports