/*
  Activity class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import {
  ENTITY_TYPES,
  ACTIVITY_STATUSES,
  ACTIVITY_TYPES, LIST_TYPES,
} from '@/config/enums';
import store from '@/store';
import { formatDateTimeNoWrap } from '@/shared/utils';
import { EntityFactory } from "@/entities/EntityFactory";
import {
  getActivities,
  getActivity,
} from "@/api/repositories/salesRepository";

export class Activity extends BaseEntity {
  static entityKey = 'activity';
  static userSectionAccessComponent = 'user_activities';
  static userSectionListTypeId = LIST_TYPES.USER_ACTIVITIES;
  static datasetKey = 'activities';
  static loadAllItemsFunc = getActivities;
  static loadItemFunc = getActivity;
  static entityTypeId = ENTITY_TYPES.ACTIVITY;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict(`activities.entity_plural_type.text`);
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = Activity;
  }

  prepareItemData(item) {
    const itemData = _.cloneDeep(item);

    _.merge(this.data, {
      ...itemData,
      disabled: !(store.getters['Account/userId'] === item.user_id ||
        store.getters['Account/userId'] === item.assigned_to),
    });
  }

  getName() {
    return this.getValue('title');
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'activity_type_id':
        res = this.getType();
        break;
      case 'created_at':
      case 'modified_at':
        res = formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE, userZone: true });
        break;
      case 'start_date_time':
        res = formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true });
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'linked_to':
        res = this.getLinkedTo(dataset);
        break;
      case 'user_id':
      case 'assigned_to':
        res = this.getAuthorName(fieldValue, dataset);
        break;
      default:
        res = undefined;
    }

    return res;
  }

  getStatus() {
    const status = ACTIVITY_STATUSES.properties[this.getValue('activity_status')];
    return status ? {
      id: this.getValue('activity_status'),
      name: Vue.prototype.$vDict(`enums.activity_status_${status.key}.text`),
      icon: status.icon,
      class: status.class,
    } : null;
  }

  getStatusClass() {
    return this.getStatus().class;
  }

  getType() {
    const type = ACTIVITY_TYPES.properties[this.getValue('activity_type_id')];
    return type ? {
      id: this.getValue('activity_type_id'),
      name: Vue.prototype.$vDict(`enums.activity_type_${type.key}.text`),
      icon: type.icon,
    } : null;
  }

  getAuthorName(userId, dataset) {
    const clientUsers = store.getters['UserClient/users'] || [];
    const user = clientUsers.find(el => el.id === userId);
    return user ? user.me_name || user.name : '';
  }

  isStatus(statusId) {
    return this.getValue('activity_status') === statusId;
  }

  isDone() {
    return Boolean(this.getValue('done'));
  }

  getLinkedTo(dataset) {
    const entityClass = EntityFactory.getClass(this.getValue('entity_type_id'));

    if (!entityClass) {
      return null;
    }

    const data = this.getDatasetItem(dataset, entityClass.datasetKey, this.getValue('entity_id'));
    return data ? new entityClass(data) : null;
  }

  isAllowActions() {
    return store.getters['Account/userId'] === this.getValue('user_id') ||
        store.getters['Account/userId'] === this.getValue('assigned_to');
  }
}
