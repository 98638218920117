/*
  LicensesList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import LicensesTableView from "@/entities/licenses/views/LicensesTableView";
import Vue from "vue";
import { getLicenseListsConfig } from '@/entities/licenses/config';
import { getLicenses } from "@/api/repositories/licensesRepository";
import { requiredFields } from "@/entities/licenses/fields";
import { License } from "./License";
import { Module } from "../module/Module";

export class LicensesList extends EntityList {
  static entityClass = License;

  constructor(listTypeId) {
    super(listTypeId, getLicenseListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = LicensesTableView;
    this.settingsForLoadingDatasets = [
      {
        datasetKey: Module.datasetKey,
        loadFunc: Module.loadAllItemsFunc,
        fieldKeys: ['modules'],
      },
    ];

    this.selfClass = LicensesList;
  }
}
