<template>
  <base-table-view
    class="myran-client-versions-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    :tbody-tr-class="tbodyTrClass"
    @change-sort="changeSort"
  >
    <template v-slot:cell(link_user_versions)="data">
      <b-link
        class="text-secondary"
        :to="{ name: 'UserVersions', query: { client_version_id: data.value } }"
      >Show list</b-link>
    </template>
    <template v-slot:cell(client_id)="data">
      <b-link
        v-if="data.value"
        class="text-secondary"
        :to="{ name: 'SupportClient', params: { id: data.value } }"
      >{{ data.value }}</b-link>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'myran-client-versions-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
  methods: {
    tbodyTrClass(entity) {
      return !entity.getValue('valid') ? 'row-danger' : '';
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
