/*
  EmailLog class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getEmailSenderLogs } from "@/api/repositories/emailSenderRepository";
import { formatDateTime } from "@/shared/utils";

export class EmailLog extends BaseEntity {
  static entityKey = 'email_log';
  static datasetKey = 'emailLogs';
  static entityTypeId = ENTITY_TYPES.EMAIL_LOG;

  // api
  static loadAllItemsFunc = getEmailSenderLogs;
  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('email_sender_logs.email_logs_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = EmailLog;
  }

  static getAllActions() {
    return [
      {
        key: 'view_events',
        dataCy: 'view-events',
        icon: 'popup',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;
    let tag;

    switch (fieldKey) {
      case 'subject':
        value = this.getSubject();
        res = value?.name || '';
        break;
      case 'created_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'sent':
        res = this.getSentStatus();
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getSubject() {
    const subjects = Vue.prototype.$lDict('client.notif_subjects');
    return subjects.find(subject => subject.key === this.getValue('subject'));
  }

  getSentStatus() {
    return this.getValue('sent') ? {
      name: Vue.prototype.$vDict('email_sender_logs.sent_status_success.text'),
      class: 'status-tag--active',
    } : {
      name: Vue.prototype.$vDict('email_sender_logs.sent_status_failed.text'),
      class: 'status-tag--suspended',
    };
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'user_id':
        res = this.getUser(dataset);
        break;
      default:
    }

    return res;
  }

  getActions() {
    const actions = this.selfClass.getAllActions();
    return actions.filter(action => {
      let res = true;

      switch (action.key) {
        case 'view_events':
          res = !!this.getValue('provider_msg_id');
          break;
        default:
      }

      return res;
    });
  }
}
