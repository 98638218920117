import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: 'id',
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'name',
    label: 'Name',
    main: true,
  },
  {
    key: 'subscription_customer_id',
    label: 'Subscription customer id',
    details: true,
  },
  {
    key: 'package_id',
    label: 'Package Id',
    details: true,
  },
  {
    key: 'activated',
    label: 'Activated',
    details: true,
  },
  {
    key: 'activated_at',
    label: 'Activated at',
    details: true,
  },
  {
    key: 'history',
    label: 'History',
    details: true,
  },
  {
    key: 'history_start_date',
    label: 'History start date',
    details: true,
  },
  {
    key: 'package_registered_at',
    label: 'Package registered at',
    details: true,
  },
  {
    key: 'package_expire_at',
    label: 'Package expire at',
    details: true,
  },
  {
    key: 'source_type_id',
    label: 'Source type',
  },
  {
    key: 'source_id',
    label: 'Source Id',
  },
  {
    key: 'support_name',
    label: 'Support name',
    details: true,
  },
  {
    key: 'support_email',
    label: 'Support email',
    details: true,
  },
  {
    key: 'support_phone',
    label: 'Support phone',
    details: true,
  },
  {
    key: 'country_id',
    label: 'Country',
    details: true,
  },
  {
    key: 'send_from',
    label: 'Send from',
    details: true,
  },
  {
    key: 'base_url',
    label: 'Base url',
    details: true,
  },
  {
    key: 'client_filters',
    label: 'Client filters',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
  },
  {
    key: 'client_folders',
    label: 'Client folders',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
  },
  {
    key: 'client_roles',
    label: 'Client roles',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
  },
  {
    key: 'created_at',
    label: 'Created at',
  },
  {
    key: 'account_launched_at',
    label: 'Account launched at',
    details: true,
  },
  {
    key: 'trigger_launched_at',
    label: 'Trigger launched at',
    details: true,
  },
  {
    key: 'client_id',
    label: 'Client Id',
  },
  {
    key: 'account_processed_at',
    label: 'Account processed at',
    details: true,
  },
  {
    key: 'trigger_processed_at',
    label: 'Trigger processed at',
    details: true,
  },
  {
    key: 'error_code',
    label: 'Error code',
    details: true,
  },
  {
    key: 'error_message',
    label: 'Error message',
    details: true,
  },
  {
    key: 'client_modules',
    label: 'Client modules',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
  },
  {
    key: 'link_user_versions',
    label: 'Imported User Updates',
    details: true,
  },
  {
    key: 'valid',
    label: 'Valid',
  },
];
