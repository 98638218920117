/*
  UserProjectsList class
 */

import { BaseProjectsList } from "@/entities/project/BaseProjectsList";
import { Project } from "@/entities/project/Project";

export class ProjectsList extends BaseProjectsList {
  static entityClass = Project;

  constructor(listTypeId) {
    super(listTypeId);

    this.selfClass = ProjectsList;
  }
}
