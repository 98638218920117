import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('system_components.field_id.text'),
    class: 'column-id',
  },
  {
    key: 'name',
    label: Vue.prototype.$vDict('system_components.field_name.text'),
    class: 'column-name',
  },
];
