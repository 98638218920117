import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'name',
    label: Vue.prototype.$vDict('custom_field_metrics.field_name.text'),
    // sortable: true,
  },
  {
    key: 'key',
    label: Vue.prototype.$vDict('custom_field_metrics.field_key.text'),
  },
  {
    key: 'description',
    label: Vue.prototype.$vDict('custom_field_metrics.field_description.text'),
  },
];
