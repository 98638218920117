import enums from "@/config/enums";

export const getWebhooksListsConfig = () => ({
  [enums.LIST_TYPES.ALL_WEBHOOKS]: {
    isMain: true,
    isSavePosition: true,
  },
  [enums.LIST_TYPES.USER_WEBHOOKS]: {
    isMain: true,
    isSavePosition: true,
  },
});
