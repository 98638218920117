import enums from "@/config/enums";
import store from '@/store';

export const getNotesListsConfig = () => ({
  [enums.LIST_TYPES.USER_NOTES]: {
    isMain: true,
    isSavePosition: true,
    onlyOneSort: true,
    defaultSearchFilter: {
      default: {
        user_id: [store.getters['Account/userId']],
      },
    },
  },
});
