/*
  ModulesList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import ModulesTableView from "@/entities/module/views/ModulesTableView";
import Vue from "vue";
import { getModulesListsConfig } from "@/entities/module/config";
import { getModules, deleteModule } from "@/api/repositories/systemRepository";
import { requiredFields } from "@/entities/module/fields";
import { Module } from "@/entities";

export class ModulesList extends EntityList {
  static entityClass = Module;

  constructor(listTypeId) {
    super(listTypeId, getModulesListsConfig);

    this.tableView = ModulesTableView;

    this.selfClass = ModulesList;
    this.getRequiredFields = requiredFields;
  }
}
