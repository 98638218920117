<template>
  <div class="login-as-user">
    <login-as-user-modal
      ref="loginAsUserModal"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import {
  token,
} from "@/api/repositories/authRepository";
import {
  USER_TOKEN_TYPES,
  ENV_MODES,
} from "@/config/enums";

const LoginAsUserModal = () => import("@/entities/client-user/views/LoginAsUserModal");

export default {
  name: 'login-as-user',
  components: {
    LoginAsUserModal,
  },
  methods: {
    async getLoginAsUserLink(userId, urlParams = {}) {
      let loginHref = '';

      try {
        const response = await token({
          id: userId,
          token_type: USER_TOKEN_TYPES.REDIRECT_LINK,
        }, false);

        if (response && response.token) {
          const token = encodeURIComponent(response.token);

          if (window.envMode === ENV_MODES.LOCALHOST) {
            const { port } = window.location;

            loginHref = `http://user.${window.location.hostname}`;
            if (port) {
              loginHref += `:${port}`;
            }
          } else {
            loginHref = `http://user.${window.location.hostname}`;
          }

          loginHref += `/login-user?token=${token}`;

          Object.keys(urlParams).forEach(paramKey => {
            loginHref += `&${paramKey}=${urlParams[paramKey]}`;
          });
        }
      } catch (error) {
        console.log(error);
      }

      return loginHref;
    },
    async login(user = {}, urlParams = {}) {
      const loginLink = await this.getLoginAsUserLink(user.id, urlParams);

      if (!loginLink) {
        Vue.prototype.$notifUnexpectedError();
        return;
      }

      let openedWindow;

      try {
        openedWindow = window.open(loginLink, '_blank');
      } finally {
        if (!openedWindow && this.$refs.loginAsUserModal) {
          this.$refs.loginAsUserModal.show({
            name: user.name,
            email: user.email,
          }, loginLink);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
