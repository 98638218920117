<template>
  <div class="user-actions">
    <login-as-user
      ref="loginAsUser"
    />

    <create-user-modal
      ref="createUserModal"
      @user-added="onActionDone"
    />
  </div>
</template>

<script>
import LoginAsUser from "@/entities/client-user/views/LoginAsUser";
import CreateUserModal from "@/entities/user/views/CreateUserModal";

export default {
  name: 'user-actions',
  components: {
    LoginAsUser,
    CreateUserModal,
  },
  data() {
    return {
      actionInfo: null,
    };
  },
  methods: {
    async actionHandler(user, action) {
      const { key } = action;

      this.actionInfo = {
        entity: user,
        action,
      };

      switch (key) {
        case 'login_as':
          await this.$refs.loginAsUser.login({
            id: user.id,
            name: user.getValue('name'),
            email: user.getValue('email'),
          });
          this.onActionDone();
          break;
        case 'create_user':
          const data = { ...user };

          if (user.hasOwnProperty('userProfile')) {
            Object.assign(data, {
              user: user.userProfile.user,
              subscription: user.data,
            });
          }

          await this.$refs.createUserModal.show({ ...data });
          break;
        case 'add_subscription':
          await this.$refs.createUserModal.show({user});
          break;
        case 'add_to_organization':
          await this.$refs.createUserModal.show({user});
          break;
        default:
          await user.doAction(action);
          this.onActionDone();
      }
    },
    onActionDone(update) {
      this.$emit('done', {
        ...this.actionInfo,
        update,
      });
      this.actionInfo = null;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
