import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'pm_client_version_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
  },
  {
    key: 'pm_custom_field_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_custom_field_id.text'),
  },
  {
    key: 'pm_employee_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_employee_id.text'),
  },
  {
    key: 'source_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_source_id.text'),
  },
  {
    key: 'full_name',
    label: Vue.prototype.$vDict('parabyg_migration.field_full_name.text'),
  },
  {
    key: 'email',
    label: Vue.prototype.$vDict('parabyg_migration.field_email.text'),
  },
  {
    key: 'active',
    label: Vue.prototype.$vDict('parabyg_migration.field_active.text'),
  },
  {
    key: 'is_valid',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
  },
  {
    key: 'is_migrated',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_migrated.text'),
  },
  {
    key: 'validation_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_validation_error_message.text'),
    details: true,
  },
  {
    key: 'migration_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_migration_error_message.text'),
    details: true,
  }
];
