<template>
  <base-table-view
    class="release-notes-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(title)="data">
      <b-link
        :to="data.item.getEditLink()"
      >{{ data.value }}</b-link>
    </template>
    <template v-slot:cell(enable_languages)="data">
      <ul class="enable-languages">
        <li
          v-for="(lang, i) in data.value"
          :key="i"
          class="enable-languages__item"
        >
          <div class="enable-languages__icon">
            <img :src="lang.img" alt="">
          </div>
          <div class="enable-languages__text">{{ lang.iso }}</div>
        </li>
      </ul>
    </template>
    <template v-slot:cell(countries)="data">
      <ul class="country-badges">
        <li
          v-for="(country, i) in data.value"
          :key="i"
          class="country-badges__item"
        >{{ country.isoCode }}</li>
      </ul>
    </template>
    <template v-slot:cell(status)="data">
      <div
        v-if="data.value"
        class="status-tag"
        :class="data.value.class"
      >{{ data.value.name }}</div>
    </template>
    <template v-slot:cell(release_date)="data">
      <div v-html="data.value" />
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'release-notes-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.enable-languages {
  list-style: none;
  margin: 0 0 -10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &__icon {
    margin-right: 4px;

    img {
      width: 20px;
      display: block;
    }
  }

  &__text {
    text-transform: uppercase;
    line-height: 16px;
  }
}
</style>
