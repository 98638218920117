/*
  PMClientVersionsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import Vue from "vue";
import ClientVersionsTableView from "@/entities/parabyg-migration/pm-client-version/views/ClientVersionsTableView";
import { getPMClientVersionListsConfig } from "@/entities/parabyg-migration/pm-client-version/config";
import { requiredFields } from "@/entities/parabyg-migration/pm-client-version/fields";
import { getPMClientVersions, deletePMClientVersion, getPMClients } from "@/api/repositories/parabygMigrationRepository";
import { PMClient, PMClientVersion } from "@/entities";

export class PMClientVersionsList extends EntityList {
  static entityClass = PMClientVersion;

  constructor(listTypeId) {
    super(listTypeId, getPMClientVersionListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = ClientVersionsTableView;
    this.settingsForLoadingDatasets = [
      {
        datasetKey: PMClient.datasetKey,
        loadFunc: PMClient.loadAllItemsFunc,
        fieldKeys: ['pm_client_id'],
      },
    ];

    this.selfClass = PMClientVersionsList;
  }

  getListFiltersSetting() {
    const rbOptions = [
      { id: 0, name: Vue.prototype.$vDict('global.text_no.text') },
      { id: 1, name: Vue.prototype.$vDict('global.text_yes.text') },
      { id: -1, name: Vue.prototype.$vDict('parabyg_migration.filter_option_for_null_value.text') }
    ];

    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('entity_list.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'pm_client_id',
        dataCy: 'pm-client-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_client.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          nameKey: 'hint',
          placeholder: Vue.prototype.$vDict('parabyg_migration.filter_pm_client_input_placeholder.text'),
          searchFunc: async({limit, search, filter}) => {
            let query;

            if (filter) {
              query = { filter };
            } else {
              query = {
                filter: {
                  hint: { q: search },
                },
                limit
              };
            }

            return await getPMClients(query);
          },
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('parabyg_migration.field_created_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'fetched_at',
        dataCy: 'fetched-at',
        name: Vue.prototype.$vDict('parabyg_migration.field_fetched_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'validated_at',
        dataCy: 'validated-at',
        name: Vue.prototype.$vDict('parabyg_migration.field_validated_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'migrated_at',
        dataCy: 'migrated-at',
        name: Vue.prototype.$vDict('parabyg_migration.field_migrated_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'fetching_launched_at',
        dataCy: 'fetching-launched-at',
        name: Vue.prototype.$vDict('parabyg_migration.field_fetching_launched_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'validation_launched_at',
        dataCy: 'validation-launched-at',
        name: Vue.prototype.$vDict('parabyg_migration.field_validation_launched_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'migration_launched_at',
        dataCy: 'migration-launched-at',
        name: Vue.prototype.$vDict('parabyg_migration.field_migration_launched_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'is_fetched',
        dataCy: 'is-fetched',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_fetched.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
      {
        key: 'is_valid',
        dataCy: 'is-valid',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
      {
        key: 'is_migrated',
        dataCy: 'is-migrated',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_migrated.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
    ];
  }
}
