/*
  Activity class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import {
  ENTITY_TYPES, LIST_TYPES,
} from '@/config/enums';
import { getNotes } from "@/api/repositories/salesRepository";

export class Note extends BaseEntity {
  static entityKey = 'note';
  static userSectionAccessComponent = 'user_notes';
  static userSectionListTypeId = LIST_TYPES.USER_NOTES;
  static datasetKey = 'notes';
  static entityTypeId = ENTITY_TYPES.NOTE;

  // api
  static loadAllItemsFunc = getNotes;
  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('notes.notes_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = Note;
  }
}
