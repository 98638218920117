import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'vortal_user_action',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_vortal_user_action.text'),
  },
  {
    key: 'vortal_contact_id',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_vortal_contact.text'),
  },
  {
    key: 'vortal_company_id',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_vortal_company.text'),
  },
  {
    key: 'vortal_project_id',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_vortal_project.text'),
    details: true,
  },
  {
    key: 'contact_email',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_contact_email.text'),
    details: true,
  },
  {
    key: 'contact_vortal_user_code',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_contact_vortal_user_code.text'),
    details: true,
  },
  {
    key: 'company_vortal_company_code',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_company_vortal_company_code.text'),
    details: true,
  },
  {
    key: 'company_vortal_identifier_type',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_company_vortal_identifier_type.text'),
    details: true,
  },
  {
    key: 'company_identifier',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_company_identifier.text'),
    details: true,
  },
  {
    key: 'project_smart_id',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_project_smart.text'),
  },
  {
    key: 'project_rsm_id',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_project_rsm_id.text'),
  },
  {
    key: 'project_vortal_operation_uid',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_project_vortal_operation_uid.text'),
    details: true,
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_created_at.text'),
    sortable: true,
  },
  {
    key: 'vortal_error_message',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_vortal_error_message.text'),
    details: true,
  },
  {
    key: 'vortal_error_request',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_vortal_error_request.text'),
    details: true,
  },
  {
    key: 'vortal_error_response',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_vortal_error_response.text'),
    details: true,
  },
  {
    key: 'created_by',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_created_by.text'),
    details: true,
  },
  {
    key: 'modified_by',
    label: Vue.prototype.$vDict('vortal_connect_logs.field_modified_by.text'),
    details: true,
  },
];
