import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('email_sender_events.field_id.text'),
    sortable: true,
  },
  {
    key: 'email_provider_event_type_id',
    label: Vue.prototype.$vDict('email_sender_events.field_email_provider_event_type_id.text'),
  },
  {
    key: 'event_reason',
    label: Vue.prototype.$vDict('email_sender_events.field_event_reason.text'),
  },
  {
    key: 'event_registered_at',
    label: Vue.prototype.$vDict('email_sender_events.field_event_registered_at.text'),
    sortable: true,
  },
];
