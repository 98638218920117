/*
  UserProject class
 */

import { BaseProject } from "@/entities/project/BaseProject";

export class Project extends BaseProject {
  static routes = BaseProject.allSectionRoutes;

  constructor(...args) {
    super(...args);

    this.selfClass = Project;
  }
}
