/*
  MyranClientVersionsList class
 */

import Vue from "vue";
import EntityList from "@/shared/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  SEARCHABLE_LIST_TYPES,
} from "@/config/enums";
import MyranClientVersionsTableView from "@/entities/myran-client-version/views/MyranClientVersionsTableView";
import { getMyranClientVersionsListsConfig } from "@/entities/myran-client-version/config";
import { requiredFields } from "@/entities/myran-client-version/fields";
import { getClientVersions } from '@/api/repositories/myranRepository';
import { MyranClientVersion } from "@/entities";

export class MyranClientVersionsList extends EntityList {
  static entityClass = MyranClientVersion;

  constructor(listTypeId) {
    super(listTypeId, getMyranClientVersionsListsConfig);

    this.tableView = MyranClientVersionsTableView;

    this.selfClass = MyranClientVersionsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'keywords',
        dataCy: 'keywords',
        name: 'Keywords',
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: 'Type keywords',
          // searchFunc: getClientVersions,
        },
      },
      {
        key: 'id',
        dataCy: 'id',
        name: 'Id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type id',
        },
      },
      {
        key: 'subscription_customer_id',
        dataCy: 'subscription-customer',
        name: 'Subscription customer id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type subscription customer id',
        },
      },
      {
        key: 'client_id',
        dataCy: 'client',
        name: 'Organization',
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          type: SEARCHABLE_LIST_TYPES.CLIENTS,
          placeholder: 'Type organization name',
        },
      },
      {
        key: 'source_type_id',
        dataCy: 'source-type',
        name: 'Source type',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('SOURCE_TYPES'),
        },
      },
      {
        key: 'source_id',
        dataCy: 'source',
        name: 'Source Id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type source id',
        },
      },
      {
        key: 'support_name',
        dataCy: 'support-name',
        name: 'Support name',
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: 'Type support name',
        },
      },
      {
        key: 'support_email',
        dataCy: 'support-email',
        name: 'Support email',
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: 'Type support email',
        },
      },
      {
        key: 'country_id',
        dataCy: 'country',
        name: 'Countries',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
      {
        key: 'history_start_date',
        dataCy: 'history-start-date',
        name: 'History start date',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'package_expire_at',
        dataCy: 'package-expire-at',
        name: 'Expire at',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: 'Created at',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      // {
      //   key: 'valid',
      //   name: 'Valid',
      //   filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
      //   showEmptyOption: true,
      //   options: [
      //     { id: 0, name: 'Not valid' },
      //     { id: 1, name: 'Valid' },
      //   ],
      // },
    ];
  }
}
