import Vue from 'vue';
import { getUserProfiles } from "@/api/repositories/myranRepository";

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_id.text'),
    class: 'column-id',
    sortable: true,
    fieldset: ['id'],
  },
  {
    key: 'subscription_version_id',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_subscription_version_id.text'),
    details: true,
    fieldset: ['subscription_version_id'],
  },
  {
    key: 'source_id',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_source_id.text'),
    fieldset: ['source_id'],
  },
  {
    key: 'last_name',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_last_name.text'),
    fieldset: ['last_name'],
  },
  {
    key: 'first_name',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_first_name.text'),
    fieldset: ['first_name'],
  },
  {
    key: 'email',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_email.text'),
    main: true,
    fieldset: ['email'],
  },
  {
    key: 'source_created_at',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_source_created_at.text'),
    fieldset: ['source_created_at'],
  },
  {
    key: 'source_modified_at',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_source_modified_at.text'),
    fieldset: ['source_modified_at'],
  },
  {
    key: 'selection',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_selection.text'),
    fieldset: [],
    asyncData: (item) => ({
      requestMethod: getUserProfiles,
      requestQuery: {
        fields: ['selection'],
        filter: {
          id: { in: [item.id] },
        },
      },
      responseMethod: (response) => _.get(response, 'data[0].selection') || {},
    }),
  },
  {
    key: 'created_at',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_created_at.text'),
    fieldset: ['created_at'],
  },
  {
    key: 'valid',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_valid.text'),
    fieldset: ['valid'],
  },
  {
    key: 'active',
    label: Vue.prototype.$vDict('myran_logs_user_profiles.field_active.text'),
    fieldset: ['active'],
  },
  {
    key: 'addons',
    label: 'Addons',
    details: true,
    type: enums.TABLE_FIELD_TYPES.JSON,
    fieldset: ['addons'],
  },
];
