/*
  Parabyg migration entity class for extends
 */

import Vue from "vue";
import BaseEntity from "@/shared/classes/entity/BaseEntity";

export class ParabygMigrationEntity extends BaseEntity {
  getMigrationStatusTag(payload = {}) {
    const { launchedAt, doneAt, isDone, jobSend, migrationType } = payload;

    if (jobSend === 1) {
      return {
        name: Vue.prototype.$vDict('parabyg_migration.migration_status_queue.text'),
        class: 'status-tag--queue',
      };
    }

    if (!launchedAt && !doneAt && !isDone) {
      return null;
    }

    let res = null;

    if (launchedAt && !doneAt && !isDone) {
      res = {
        name: Vue.prototype.$vDict('parabyg_migration.migration_status_processing.text'),
        class: 'status-tag--disabled',
      };
    } else if (migrationType !== 'fetching' && launchedAt && doneAt) {
      res = {
        name: Vue.prototype.$vDict('parabyg_migration.migration_status_completed.text'),
        class: 'status-tag--completed',
      };
    } else if (migrationType === 'fetching' && launchedAt && doneAt && !isDone) {
      res = {
        name: Vue.prototype.$vDict('parabyg_migration.migration_status_failed.text'),
        class: 'status-tag--suspended',
      };
    } else if (migrationType === 'fetching' && launchedAt && doneAt && isDone) {
      res = {
        name: Vue.prototype.$vDict('parabyg_migration.migration_status_done.text'),
        class: 'status-tag--active',
      };
    }

    return res;
  }
}
