/*
  SavedSearchNotificationsList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import {
  LIST_TYPES,
  SEARCH_FILTER_TYPES,
  SEARCHABLE_LIST_TYPES,
  FILTER_OPERATORS,
  FILTER_VALUE_TYPES,
} from "@/config/enums";
import SavedSearchNotificationTableView from "@/entities/saved-search-notif/views/SavedSearchNotificationTableView";
import Vue from "vue";
import router from "@/router";
import store from "@/store";
import { getSavedSearchNotificationsListsConfig } from "@/entities/saved-search-notif/config";
import { getSavedSearchNotifications} from "@/api/repositories/searchesRepository";
import { requiredFields } from "@/entities/saved-search-notif/fields";
import { getEntityListConfigs } from "@/entities";
import { regexGuid } from "@/shared/validates";
import { User, SavedSearchNotification } from "@/entities";

export class SavedSearchNotificationsList extends EntityList {
  static entityClass = SavedSearchNotification;

  constructor(listTypeId) {
    super(listTypeId, getSavedSearchNotificationsListsConfig);

    this.tableView = SavedSearchNotificationTableView;

    this.selfClass = SavedSearchNotificationsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    const allListTypes = this.selfClass.entityClass.getListTypes();
    const listTypes = allListTypes.filter(listType => !listType.hasAccess || listType.hasAccess());

    return [
      {
        key: 'keywords',
        dataCy: 'keywords',
        name: Vue.prototype.$vDict('saved_search_notifs.filter_keywords_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('saved_search_notifs.filter_keywords_input_placeholder.text'),
        },
      },
      {
        key: 'access_code',
        dataCy: 'access_code',
        name: Vue.prototype.$vDict('saved_search_notifs.filter_access_code_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          validation: {
            inputRegex: regexGuid,
            invalidText: Vue.prototype.$vDict('errors.value_must_be_guid.text'),
          },
          placeholder: Vue.prototype.$vDict('saved_search_notifs.filter_access_code_input_placeholder.text'),
        },
      },
      {
        key: 'user_id',
        dataCy: 'user',
        name: Vue.prototype.$vDict('saved_search_notifs.filter_user_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          type: SEARCHABLE_LIST_TYPES.USERS,
          placeholder: Vue.prototype.$vDict('saved_search_notifs.filter_user_id_placeholder.text'),
        },
      },
      {
        key: 'client_id',
        dataCy: 'client',
        name: Vue.prototype.$vDict('saved_search_notifs.field_client_id.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          type: SEARCHABLE_LIST_TYPES.CLIENTS,
          placeholder: Vue.prototype.$vDict('saved_search_notifs.field_client_id_input_placeholder.text'),
          isMultiple: true,
        },
      },
      {
        key: 'list_type_id',
        dataCy: 'list-type',
        name: Vue.prototype.$vDict('saved_search_notifs.filter_list_type_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: listTypes,
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('saved_search_notifs.filter_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
        settings: {
          default: {
            operators: [FILTER_OPERATORS.GTE, FILTER_OPERATORS.LTE],
            type: FILTER_VALUE_TYPES.DATETIME,
          },
        },
      },
    ];
  }
}
