<template>
  <base-table-view
    class="vortal-contacts-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(created_at)="data">
      <div v-html="data.value" />
    </template>
    <template v-slot:cell(modified_at)="data">
      <div v-html="data.value" />
    </template>
    <template v-slot:cell(vortal_company_id)="data">
      <b-link
        v-if="data.value"
        :to="{ name: 'VortalConnectCompanies', query: { searchFilter: `id:[${data.item.getValue('vortal_company_id')}]` }}"
        class="text-secondary"
      >{{ data.value }}</b-link>
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";

export default {
  name: 'vortal-contacts-table-view',
  components: {
    BaseTableView,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>
