<template>
  <div
    class="smart-selection-form"
    data-cy="selection-form"
  >
<!--    <div
      v-if="isEditMode && formData.source_id"
      class="smart-selection-form__header"
    >
      <b-form-group
        :label="$vDict('smart_selections.field_source_id.text')"
      >
        <b-form-input
          :value="formData.source_id"
          disabled
        />
      </b-form-group>

      <b-form-group
        :label="$vDict('smart_selections.field_source_type_id.text')"
      >
        <b-form-input
          :value="formData.sourceType"
          disabled
        />
      </b-form-group>

      <b-form-group
        :label="$vDict('smart_selections.field_name.text')"
      >
        <b-form-input
          v-model="formData.name"
          name="field-title"
          size="lg"
        />
      </b-form-group>

      <b-form-group
        :label="$vDict('smart_selections.field_description.text')"
      >
        <b-form-textarea
          id="description"
          v-model="formData.description"
          name="description"
          rows="2"
        />
      </b-form-group>
    </div>-->

    <b-form-group
      :state="!$v.formData.administrative_units.$anyError"
      class="form-group--section form-group--label-underline"
      data-cy="administrative-units"
    >
      <template #label>
        <div class="required">{{ getFieldLabel($vDict('smart_selections.field_administrative_units.text'), 'administrative_units') }}</div>
        <b-form-invalid-feedback
          v-if="$v.formData.administrative_units.$anyError"
          force-show
          v-html="getFormErrorsAsText('formData.administrative_units')"
        />
      </template>

      <form-field-selector
        v-model="formData.administrative_units"
        :items="locations"
      />

      <div class="form-group__centered-content">
        <select-geography
          v-model="formData.administrative_units"
          :list-type-id="LIST_TYPES.ALL"
          :locations="locations"
          plain-view
          id-key="id"
        />
      </div>
    </b-form-group>

    <transition-group name="fade">
      <b-form-group
        v-if="formItems.categories.length > 0"
        :label="getFieldLabel($vDict('smart_selections.field_categories.text'), 'categories')"
        key="categories"
        class="form-group--section form-group--label-underline"
        data-cy="categories"
      >
        <form-field-selector
          v-model="formData.categories"
          :items="formItems.categories"
        />

        <div class="form-group__centered-content">
          <checkboxes-related
            v-model="formData.categories"
            :items="formItems.categories"
          />
        </div>
      </b-form-group>

      <b-form-group
        v-if="formItems.development_types.length > 0"
        :label="getFieldLabel($vDict('smart_selections.field_development_types.text'), 'development_types')"
        key="development_types"
        class="form-group--section form-group--label-underline"
        data-cy="development-types"
      >
        <form-field-selector
          v-model="formData.development_types"
          :items="formItems.development_types"
        />

        <div class="form-group__centered-content">
          <checkboxes-column
            v-model="formData.development_types"
            :items="formItems.development_types"
          />
        </div>
      </b-form-group>

      <b-form-group
        v-if="formItems.planning_stages.length > 0"
        :label="getFieldLabel($vDict('smart_selections.field_planning_stages.text'), 'planning_stages')"
        key="planning_stages"
        class="form-group--section form-group--label-underline"
        data-cy="planning-stages"
      >
        <form-field-selector
          v-model="formData.planning_stages"
          :items="formItems.planning_stages"
        />

        <div class="form-group__centered-content">
          <checkboxes-column
            v-model="formData.planning_stages"
            :items="formItems.planning_stages"
          />
        </div>
      </b-form-group>

      <b-form-group
        v-if="formItems.roles.length > 0"
        :label="getFieldLabel($vDict('smart_selections.field_event_roles.text'), 'event_roles')"
        key="event_roles"
        class="form-group--section form-group--label-underline"
        data-cy="event-roles"
      >
        <form-field-selector
          v-model="formData.event_roles"
          :items="formItems.roles"
        />

        <div class="form-group__centered-content">
          <checkboxes-related
            v-model="formData.event_roles"
            :items="formItems.roles"
          />
        </div>
      </b-form-group>

      <b-form-group
        v-if="formItems.roles.length > 0"
        :label="getFieldLabel($vDict('smart_selections.field_view_roles.text'), 'view_roles')"
        key="view_roles"
        class="form-group--section form-group--label-underline"
        data-cy="view-roles"
      >
        <form-field-selector
          v-model="formData.view_roles"
          :items="formItems.roles"
        />

        <div class="form-group__centered-content">
          <checkboxes-related
            v-model="formData.view_roles"
            :items="formItems.roles"
          />
        </div>
      </b-form-group>

      <b-form-group
        v-if="formItems.tender_roles.length > 0"
        :label="getFieldLabel($vDict('smart_selections.field_tender_event_roles.text'), 'tender_event_roles')"
        key="tender_event_roles"
        class="form-group--section form-group--label-underline"
        data-cy="tender-event-roles"
      >
        <form-field-selector
          v-model="formData.tender_event_roles"
          :items="formItems.tender_roles"
        />

        <div class="form-group__centered-content">
          <checkboxes-related
            v-model="formData.tender_event_roles"
            :items="formItems.tender_roles"
          />
        </div>
      </b-form-group>
    </transition-group>

    <b-form-group
      v-if="formItems.tender_roles.length > 0"
      :label="getFieldLabel($vDict('smart_selections.field_tender_view_roles.text'), 'tender_view_roles')"
      key="tender_view_roles"
      class="form-group--section form-group--label-underline"
      data-cy="tender-view-roles"
    >
      <form-field-selector
        v-model="formData.tender_view_roles"
        :items="formItems.tender_roles"
      />

      <div class="form-group__centered-content">
        <checkboxes-related
          v-model="formData.tender_view_roles"
          :items="formItems.tender_roles"
        />
      </div>
    </b-form-group>

    <b-form-group
      :label="$vDict('smart_selections.field_value.text')"
      class="form-group--section form-group--label-underline"
      data-cy="budget"
    >
      <div class="form-group__centered-content">
        <budget-slider
          :value="budgetSize"
          :currency-id="currencyId"
          @input="onChangeBudgetSize"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import SelectGeography from '@/components/forms/select-geography/SelectGeography';
import FormFieldSelector from '@/views/_includes/FormFieldSelector';
import CheckboxesRelated from '@/components/forms/checkboxes-related/CheckboxesRelated';
import CheckboxesColumn from '@/components/forms/CheckboxesColumn';
import BudgetSlider from '@/components/forms/BudgetSlider';
import VDatePicker from "@/components/forms/datetime/VDatePicker";
import { LIST_TYPES } from "@/config/enums";
import { smartSelectionFormMixin } from "@/entities/smart-selection/views/smartSelectionMixin";

const { DateTime } = require('luxon');

export default {
  name: 'smart-selection-form',
  components: {
    CheckboxesRelated,
    SelectGeography,
    FormFieldSelector,
    CheckboxesColumn,
    BudgetSlider,
    VDatePicker,
  },
  mixins: [
    smartSelectionFormMixin,
  ],
  props: {
    // selection: { type: Object, default: Object },
    locations: { type: Array, default: Array },
    currencyId: { type: Number, default: null },
    formData: { type: Object, default: Object },
  },
  data() {
    return {
      LIST_TYPES,
      isLoadingForm: false,
      isLoadingSubmit: false,
    };
  },
  // computed: {
  //   sourceTypeName() {
  //     const sourceType = this.selection.getSourceType();
  //     return sourceType?.name || '';
  //   },
  // },
  // beforeMount() {
  //   this.selection.setFormDataFromData(this.locations);
  // },
};
</script>

<style lang="scss" scoped>
.smart-selection-form {
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    max-width: 820px;
    margin-bottom: 32px;
  }

  &::v-deep {
    .budget-slider {
      &__slider {
        margin: 50px 10px 30px;
      }
    }
  }
}
</style>
