import Vue from 'vue';

export const requiredFields = () =>  [
  {
    key: 'id',
    label: Vue.prototype.$vDict('entity_list.field_id.text'),
    sortable: true,
  },
  {
    key: 'pm_client_version_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
  },
  {
    key: 'subject',
    label: Vue.prototype.$vDict('parabyg_migration.field_subject.text'),
  },
  {
    key: 'source_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_source_id.text'),
  },
  {
    key: 'entity_type_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_entity_type_id.text'),
  },
  {
    key: 'entity_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_entity_id.text'),
  },
  {
    key: 'user_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_user_id.text'),
  },
  {
    key: 'is_valid',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
  },
  {
    key: 'is_migrated',
    label: Vue.prototype.$vDict('parabyg_migration.field_is_migrated.text'),
  },
  {
    key: 'text',
    label: Vue.prototype.$vDict('parabyg_migration.field_text.text'),
    details: true,
  },
  {
    key: 'responsible',
    label: Vue.prototype.$vDict('parabyg_migration.field_responsible.text'),
    details: true,
  },
  {
    key: 'responsible_ref',
    label: Vue.prototype.$vDict('parabyg_migration.field_responsible_ref.text'),
    details: true,
  },
  {
    key: 'rsm_project_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_rsm_project_id.text'),
    details: true,
  },
  {
    key: 'rsm_company_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_rsm_company_id.text'),
    details: true,
  },
  {
    key: 'rsm_contact_id',
    label: Vue.prototype.$vDict('parabyg_migration.field_rsm_contact_id.text'),
    details: true,
  },
  {
    key: 'done',
    label: Vue.prototype.$vDict('parabyg_migration.field_done.text'),
    details: true,
  },
  {
    key: 'start_date_time',
    label: Vue.prototype.$vDict('parabyg_migration.field_start_date_time.text'),
    details: true,
  },
  {
    key: 'validation_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_validation_error_message.text'),
    details: true,
  },
  {
    key: 'migration_error_message',
    label: Vue.prototype.$vDict('parabyg_migration.field_migration_error_message.text'),
    details: true,
  },
];
