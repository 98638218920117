/*
  VortalNotificationEmail class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import {
  deleteNotificationEmail,
  getNotificationEmails,
  putNotificationEmail,
  postNotificationEmail,
} from "@/api/repositories/vortalConnectRepository";
import { formatDateTimeNoWrap } from "@/shared/utils";

export class VortalNotificationEmail extends BaseEntity {
  static entityKey = 'vortal_notification_email';
  static datasetKey = 'vortalNotificationEmails';
  static entityTypeId = ENTITY_TYPES.VORTAL_NOTIFICATION_EMAIL;
  static routes = {
    edit: 'VortalConnectEditorNotificationEmail',
    create: 'VortalConnectEditorNotificationEmailNew',
    list: 'VortalConnectNotificationEmails',
  };

  static loadAllItemsFunc = getNotificationEmails;
  static deleteItemFunc = deleteNotificationEmail;
  static putItemFunc = putNotificationEmail;
  static postItemFunc = postNotificationEmail;
  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('vortal_connect_notif_emails.notif_emails_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = VortalNotificationEmail;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
      case 'modified_at':
        res = fieldValue ? formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', { id: fieldValue, prop: 'name' });
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }
}
