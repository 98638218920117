import enums from "@/config/enums";

export const getModulesListsConfig = () => ({
  [enums.LIST_TYPES.ALL_MODULES]: {
    isMain: true,
    isSavePosition: true,
    defaultSort: {
      default: {
        priority: { order: 'a' },
      },
    },
  },
});
