<template>
  <div class="users-table-view">
    <base-table-view
      :striped="false"
      selectable
      :items="tableRows"
      :fields="tableFields"
      :sort="sort"
      :entity-list="entityList"
      :fixed-required-columns="fixedRequiredColumns"
      @change-sort="changeSort"
    >
      <template v-slot:row="{ item }">
        <td
          v-for="field in getRowFields(item)"
          class="dt-table__td"
          v-bind="getTdParams(item, field)"
        >
          <template v-if="field.key === 'id'">{{ item.user.id }}</template>
          <template v-else-if="field.key === 'name'">
            <field-user
              :user="item.user"
              show-email
            />
          </template>
          <template v-else-if="field.key === 'account_status'">
            <user-account-status
              v-if="item.userData.accountStatus"
              :variant="item.userData.accountStatus.variant"
              :name="item.userData.accountStatus.name"
            />
          </template>
          <template v-else-if="field.key === 'client_user_license_id' && item.clientData">
            {{ item.clientData.licenseName }}
          </template>
          <template v-else-if="field.key === 'client_name' && item.clientData">
            <b-link
              class="entity-link"
              :to="item.clientData.linkTo"
            >{{ item.clientData.name }}</b-link>
          </template>
          <template v-else-if="field.key === 'client_user_status' && item.clientData">
            <client-user-status
              v-if="item.clientData.onboardingStatus"
              :variant="item.clientData.onboardingStatus.variant"
              :name="item.clientData.onboardingStatus.name"
            />
          </template>
          <template v-else-if="field.key === 'subscription_name' && item.subscriptionData">
            <subscription-name
              :subscription="item.subscription"
            />
          </template>
          <template v-else-if="field.key === 'subscription_source_type_id' && item.subscriptionData">
            {{ item.subscriptionData.sourceType }}
          </template>
          <template v-else-if="field.key === 'subscription_end_date' && item.subscriptionData">
            {{ item.subscriptionData.endDate }}
          </template>
          <template v-else-if="field.key === 'subscription_status' && item.subscriptionData">
            <div
              class="status-tag"
              :class="item.subscriptionData.status.class"
            >{{ item.subscriptionData.status.name }}</div>
          </template>
          <template v-else-if="field.key === 'actions'">
            <actions-field
              :possible-actions="possibleActions"
              :actions="item.user.getActions()"
              :is-loading="isLoadingEntityId(item.user.id)"
              @action="entityActionHandler(item.user, $event)"
            />
          </template>
        </td>
      </template>
    </base-table-view>

    <user-actions
      ref="entityActions"
      @done="onActionDone"
    />
  </div>
</template>

<script>
import BaseTableView from "@/components/entity-list/views/table/BaseTableView";
import tableViewMixin from "@/components/entity-list/views/table/tableViewMixin";
import ActionsField from "@/components/table/fields/ActionsField.vue";
import FieldUser from "@/components/entity-fields/FieldUser.vue";
import UserActions from "@/entities/user/views/UserActions.vue";
import UserAccountStatus from "@/entities/user/views/UserAccountStatus.vue";
import ClientUserStatus from "@/entities/client-user/views/ClientUserStatus.vue";
import SubscriptionName from "@/entities/subscriptions/views/SubscriptionName.vue";

export default {
  name: 'users-table-view',
  components: {
    BaseTableView,
    ActionsField,
    FieldUser,
    UserActions,
    UserAccountStatus,
    ClientUserStatus,
    SubscriptionName,
  },
  mixins: [
    tableViewMixin,
  ],
  computed: {
    tableFields() {
      return this.fields.map(field => ({
        ...field,
        class: [field.class, { 'subrow-column': field.client || field.subscription }],
      }));
    },
    tableRows() {
      let rows = [];

      this.items.forEach((user, userIndex) => {
        const { clients } = user;
        const isUserSuspended = user.getValue('is_suspended');
        const userNumber = userIndex + 1;

        const userRows = [{
          id: user.id,
          user,
          userData: {
            id: user.getValue('id'),
            name: user.getName(),
            email: user.getValue('email'),
            sourceId: user.getValue('source_id'),
            linkTo: user.getLinkTo(),
            accountStatus: user.getAccountStatus(),
            isSuspended: isUserSuspended,
          },
        }];

        clients.forEach((client, clientIndex) => {
          const { subscriptions } = client;
          const clientUserLicense = client.clientUser?.getLicense();
          const userOnboardingStatus = client.clientUser?.getUserOnboardingStatus({
            user,
            subscriptions,
          });

          const clientRow = {
            id: _.compact([
              user.id,
              client.id,
            ]).join('_'),
            clientData: {
              id: client.getValue('id'),
              name: client.getName(),
              email: client.getValue('email'),
              sourceId: client.getValue('source_id'),
              linkTo: client.getLinkTo(),
              licenseName: clientUserLicense?.name || '',
              onboardingStatus: userOnboardingStatus,
              hasActiveSubscriptionOnUser: client.hasActiveSubscriptionOnUser(),
              _rowspan: subscriptions.length || 1,
            },
          };

          if (clientIndex === 0) {
            Object.assign(userRows[userRows.length - 1], clientRow);
          } else {
            userRows.push(clientRow);
          }

          subscriptions.forEach((subscription, subscriptionIndex) => {
            const subscriptionRow = {
              id: _.compact([
                user.id,
                client.id,
                subscription.id,
              ]).join('_'),
              subscriptionData: {
                id: subscription.getValue('id'),
                name: subscription.getName(),
                sourceType: subscription.getFieldValue('source_type_id'),
                endDate: subscription.getFieldValue('end_date'),
                linkTo: subscription.getLinkTo(),
                title: subscription.getValue('title'),
                isActiveOnUser: subscription.isActiveOnUser(),
              },
              subscription,
              _tdClass: (clientIndex + 1) % 2 === 1 ? 'odd-row' : 'even-row',
            };

            if (subscriptionIndex === 0) {
              Object.assign(userRows[userRows.length - 1], subscriptionRow);
            } else {
              userRows.push(subscriptionRow);
            }
          });
        });

        if (userRows.length > 0) {
          const userIsActive = user.isActive();

          userRows[0].userData._rowspan = userRows.length || 1;
          rows = rows.concat(userRows.map(rowData => ({
            ...rowData,
            userIsActive,
            _rowClass: userNumber % 2 === 1 ? 'odd-row' : 'even-row',
          })));
        }
      });

      return rows;
    },
  },
  methods: {
    getRowFields(item) {
      let fields;

      if (item.userData) {
        fields = this.tableFields;
      } else if (item.clientData) {
        fields = this.tableFields.filter(field => field.client || field.subscription);
      } else {
        fields = this.tableFields.filter(field => field.subscription);
      }

      return fields;
    },
    getTdParams(item, field) {
      const tdClasses = [item._tdClass];
      let rowspan;

      if (field.user && item.userData) {
        rowspan = item.userData._rowspan;
      } else if (field.client && item.clientData) {
        rowspan = item.clientData._rowspan;
      }

      const isUserInactive = !item.userIsActive && !['id', 'name'].includes(field.key);
      const isClientInactive = field.client && !item.clientData.hasActiveSubscriptionOnUser;
      const isSubscriptionInactive = field.subscription && !item.subscriptionData?.isActiveOnUser;

      if (isUserInactive || isClientInactive || isSubscriptionInactive) {
        tdClasses.push('dt-table__td--muted');
      }

      return {
        class: [
          field.class,
          ...tdClasses,
        ],
        rowspan,
      };
    },
    async onActionDone(payload = {}) {
      const { action, entity } = payload;

      switch (action.key) {
        case 'add_subscription':
          await this.entityList.reloadEntity(entity);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.users-table-view::v-deep {
  .dt-table__td {
    border-bottom: 1px solid var(--line-color-main);
  }

  .dt-table__tr.odd-row {
    background-color: var(--entity-list-table-odd-row-bg);
  }

  .subrow-column.even-row {
    background-color: var(--entity-list-table-subrows-even-row-bg);
  }
}
</style>
