/*
  File class
 */

import { Webhook } from '@/entities/webhook/Webhook';
import store from '@/store';

export class UserWebhook extends Webhook {
  static routes = {
    list: 'SettingsWebhooks',
    view: 'SettingsWebhook',
    edit: 'SettingsEditorWebhook',
    create: 'SettingsEditorWebhookNew',
  };

  constructor(...args) {
    super(...args);

    this.selfClass = UserWebhook;
  }

  getActions() {
    const actions = super.getActions();
    return actions.filter(action => {
      let res = true;

      switch (action.key) {
        case 'edit':
          res = store.getters['Account/hasComponent']('webhook_list_edit_btn');
          break;
        default:
      }

      return res;
    });
  }
}
