/*
  UserContact class
 */

import { BaseContact } from "@/entities/contact/BaseContact";

export class UserContact extends BaseContact {
  static routes = BaseContact.userSectionRoutes;

  constructor(...args) {
    super(...args);

    this.selfClass = UserContact;
  }
}
