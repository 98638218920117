/*
  UsageStatsUsersList class
 */

import EntityList from "@/shared/classes/list/EntityList";
import UsageStatsUsersTableView from "@/entities/usage-stats-user/views/UsageStatsUsersTableView";
import Vue from "vue";
import { getUsageStatsUsersListsConfig } from "@/entities/usage-stats-user/config";
import { requiredFields } from "@/entities/usage-stats-user/fields";
import { getUSUsers } from '@/api/repositories/usageStatisticsRepository';
import { UsageStatsUser } from "@/entities";

export class UsageStatsUsersList extends EntityList {
  static entityClass = UsageStatsUser;

  constructor(listTypeId) {
    super(listTypeId, getUsageStatsUsersListsConfig);

    this.tableView = UsageStatsUsersTableView;

    this.selfClass = UsageStatsUsersList;
    this.getRequiredFields = requiredFields;
  }
}
