/*
  WebhooksQueue class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import {
  getWebhookQueues,
} from "@/api/repositories/webhookRepository";
import { formatDateTime, getExternalLink } from "@/shared/utils";
import { Client, Webhook } from "@/entities";

export class WebhooksQueue extends BaseEntity {
  static entityKey = 'webhooks_queue';
  static datasetKey = 'webhooksQueues';
  static entityTypeId = ENTITY_TYPES.WEBHOOK_QUEUE;

  // api
  static loadAllItemsFunc = getWebhookQueues;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('webhook_queue.webhook_queue_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = WebhooksQueue;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'endpoint_url':
        res = getExternalLink(fieldValue);
        break;
      case 'created_at':
      case 'scheduled_at':
      case 'launched_at':
      case 'sent_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'webhook_event_type':
        value = this.getWebhookEventType();
        res = value?.name || '';
        break;
      case 'status':
        res = this.getStatus();
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'webhook_id':
        res = this.getWebhook(dataset);
        break;
      case 'client_id':
        res = this.getClient(dataset);
        break;
      default:
    }

    return res;
  }

  getWebhookEventType() {
    const eventTypes = Vue.prototype.$getEnumsList('WEBHOOK_EVENT_TYPES');
    return eventTypes.find(type => type.id === this.getValue('webhook_event_type'))
  }

  getStatus() {
    const statuses = Vue.prototype.$getEnumsList('WEBHOOK_QUEUE_STATUSES');
    return statuses.find(status => status.id === this.getValue('status'));
  }

  getClient(dataset = {}) {
    return this.getDatasetItem(dataset, Client.datasetKey, this.getValue('client_id'));
  }

  getWebhook(dataset = {}) {
    return this.getDatasetItem(dataset, Webhook.datasetKey, this.getValue('webhook_id'));
  }
}
